import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { faqs } from "./data";
import { Accordion } from "react-bootstrap";

const FAQsPage = ({ t, modules }) => {
  const [open, setOpen] = useState(null); 
  const activeModule = modules?.active;

  const toggle = (id) => {
    setOpen(open === id ? null : id); 
  };

  const items = faqs[activeModule];

  return (
    <Container className="pb-5">
      <h1 className="my-4">{t("FAQs")}</h1>
      <Accordion activeKey={open} alwaysOpen={false}>
        {items.map((faq, index) => (
          <Card className="mb-3" key={index}>
            <Accordion.Item eventKey={index}>
              <Accordion.Header onClick={() => toggle(index)}>
                {`${index + 1}. ${t(faq.question)}`}
              </Accordion.Header>
              <Accordion.Body>{open === index && t(faq.answer)}</Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </Container>
  );
};

const mapStatetoProps = (state) => {
  const { Modules } = state;
  return {
    modules: Modules,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(FAQsPage));
