import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TaskDetails from "src/modules/3rd-party-management/components/RisksAndTasks/tasks/details";
import { successNotification, errorNotification } from "src/store/actions.js";
import Navigator from "src/modules/3rd-party-management/components/navigator";
import { useQuery } from "@tanstack/react-query";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import { toast } from "react-toastify";
import { useState } from "react";

const SupplierTaskDetailsPage = ({ t }) => {
    const { supplierId, taskId } = useParams();
    const [taskData, setTaskData] = useState(null);

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		}
	});

    const rootPageLink = handleFetchSupplierBasicInformation?.data ? (
        handleFetchSupplierBasicInformation.data.isOwnCompany ? 
        '/admin/3rd-party/manager/own' : 
        '/admin/3rd-party/manager?tab=suppliers'
    ) : `/admin/3rd-party/suppliers/${supplierId}/details?tab=task-manager`;

    let supplierName = `${handleFetchSupplierBasicInformation?.data?.name}-ID:${supplierId}`;

    if(taskData?.is_mitigation){
        supplierName += `/R${taskData?.relatedRisk?.secondary_id || ''}`;
    }

    supplierName += `/T${taskData?.secondary_id || ''}`;

    return (
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col lg="12" className="mb-4">
                        <Navigator backButtonOptions={(props) => {
                            return {
                                ...props,
                                enable  :   true
                            }
                        }} breadCrumbs={[
                            {
                                title   :   'Management',
                                link    :   rootPageLink
                            },
                            {
                                title   :   `${handleFetchSupplierBasicInformation?.data?.name || '...'}`,
                                link    :   `/admin/3rd-party/suppliers/${supplierId}/details`
                            },
                            {
                                title   :   'Task Management',
                                link    :   `/admin/3rd-party/suppliers/${supplierId}/details?tab=task-manager`
                            },
                            {
                                title   :   'Details',
                                link    :   '#'
                            }
                        ]}
                        pageTitle={handleFetchSupplierBasicInformation.isFetching || handleFetchSupplierBasicInformation.isLoading ? '...' : supplierName}
                        />
                    </Col>

                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TaskDetails 
                                    supplierInfos={ handleFetchSupplierBasicInformation }
                                    supplierId={ supplierId }
                                    taskId={ taskId }
                                    onTaskDataFetched={(task) => setTaskData(task)}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user            :   state.Login.user,
        token,
        organization    :   Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {
            successNotification,
            errorNotification
        }
    )(SupplierTaskDetailsPage))
);