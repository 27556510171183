import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS } from "src/common/constants";
import { toast } from "react-toastify";

const DownloadStatistics = ({
    filterQuery,
    token,
    t
}) => {
    const [downloadInprocess, setDownloadInprocess] = useState(false);

    const handleClickDownloadStats = () => {
        setDownloadInprocess(true);

        axios.get(`${API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS}${filterQuery ? "?" + filterQuery : ""}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            }
        ).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `dashboard-${Date.now()}.xlsx`);

            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            if (error.response) {
                const data = error.response.data;
                toast(t(data.message || data.title), {
                    type: "error"
                });
            }
        }).finally(() => {
            setDownloadInprocess(false);
        });
    };
    
    return (
        <div className="d-inline-flex position-relative text-left">
            <UncontrolledDropdown>
                <DropdownToggle
                    color="primary"
                    onClick={handleClickDownloadStats}
                    disabled={downloadInprocess}
                    className="d-flex"
                >
                    {downloadInprocess ? (
                        <>
                            <Spinner color='white' size='sm' className="me-1" />
                            {t('Downloading')}
                        </>
                    
                    ) : (
                        t("Download")
                    )}
                </DropdownToggle>
            </UncontrolledDropdown>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login;

    return { token };
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        DownloadStatistics
    )
);
