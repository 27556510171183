import i18n from 'i18next';

import {
    HistoryLogActions
} from "../constants";

export class HistoryLogDescriptionCreator {
    createFromApiData({
        section,
        action,
        user_name
    }){
        if(HistoryLogActions[section] && HistoryLogActions[section][action])
            return `${i18n.t(HistoryLogActions[section][action])}` + (user_name ? ` ${ i18n.t('by') } ${user_name}` : '');

        return '';
    }
}