import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Checkbox from "./checkbox";
import { withNamespaces } from "react-i18next";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 20,
        paddingTop: 0,
        paddingBottom: 0
    },
    sectionTitle: {
        fontWeight: 800,
        fontSize: 13
    },
    sectionBody: {
        margin: 0,
        marginLeft: 10,
        padding:0
    },
    subSection: {
        marginTop: 12,
        padding: 0
    },
    subSectionTitle: {
        fontWeight: 600,
        fontSize: 12
    },
    subSectionBody: {
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10
    },
    question: {
        margin: 0,
        marginTop: 12
    },
    questionTitle: {
        fontWeight: 400,
        marginBottom: 6,
        fontSize: 11,
    },
    questionAnswer: {
        fontWeight: 200,
        fontSize: 10,
        marginLeft: 10
    },
    subQuestion: {
        marginTop: 6
    },
    fontBold: {
        fontWeight: 'bold'
    }
});

const findQuestionAnswers = (question) => {
    if(!question){ return null }

    switch(question.type){
        case 'textarea':
        case 'text':
        case 'date':
        case 'select':
        case 'country_list':
            return question.answer[0]?.title;

        case 'checkbox':
        case 'category':
        case 'file_upload':
            return question.answer || [];

        case 'radiobox':
            const userAnswer = (question.answer && Array.isArray(question.answer) && question.answer.length > 0) ? question.answer[0] : null;

            if(userAnswer){
                return {
                    value       :   userAnswer.value,
                    title       :   userAnswer.title,
                    children    :   userAnswer.children
                };
            }
        break;
        
        default:
            return null;
    }
}

const PageContent = (props) => {
    const dateUtils = new DateUtils();
    const { sections,  t } = props;

    const renderSubQuestions = (children, childrenInfos) => {
        return children.map((child, childIndex) => {
            const childInfos = childrenInfos.find((info) => parseInt(info.id) === parseInt(child.id));

            if(child.type === 'file_upload'){
                return (
                    <View style={styles.question} key={childIndex}>
                        <Text style={styles.questionTitle}>
                            {`${t(childInfos?.title)}`}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            <Text>{ child.name }</Text>
                        </View>
                    </View>
                )
            }
            else{
                return (
                    <View style={styles.question} key={childIndex}>
                        <Text style={styles.questionTitle}>
                            {`${t(childInfos?.title)}`}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            <Text>{t(child.title)}</Text>
                        </View>
                    </View>
                );
            }
        })
    }

    const groupQuestionsBySubSection = (items) => {
        const result = [];

        const findSection = (sectionName) => {
            return result.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < items.length; i++){
            const question = items[i];
            let subSection = findSection(question.subSection || '');

            if(!subSection){
                subSection = {
                    name : question.subSection || '',
                    items : []
                }
                result.push(subSection);
            }

            subSection.items.push(question);
        }

        return result;
    }

    const renderQuestions = (questions, subSectionPrefix = null) => {
        return questions.map((question, questionIndex) => {
            const {
                title,
                type
            } = question;
            
            const userAnswers = findQuestionAnswers(question);
    
            if (type === "textarea" || type === "text" || type === "country_list" || type === "select") {
                return (
                    <View style={styles.question} key={questionIndex} break>
                        <Text style={styles.questionTitle}>
                            <Text style={styles.fontBold}>
                                { `${subSectionPrefix ? subSectionPrefix + '.' : ''}${questionIndex + 1} ` }
                            </Text>{t(title)}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            <Text>{t(userAnswers)}</Text>
                        </View>
                    </View>
                );
            }
            else if(type === "date"){
                return (
                    <View style={styles.question} key={questionIndex} break>
                        <Text style={styles.questionTitle}>
                            <Text style={styles.fontBold}>
                                { `${subSectionPrefix ? subSectionPrefix + '.' : ''}${questionIndex + 1} ` }
                            </Text> {t(title)}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            <Text>
                                {userAnswers && dateUtils.convertDateToDate(
                                    userAnswers,
                                    INTERNATIONAL_DATE_FORMAT
                                )}
                            </Text>
                        </View>
                    </View>
                )
            }
            else if(type === "checkbox" || type === "category"){
                return (
                    <View style={styles.question} key={questionIndex} break>
                        <Text style={styles.questionTitle}>
                            <Text style={styles.fontBold}>
                                { `${subSectionPrefix ? subSectionPrefix + '.' : ''}${questionIndex + 1} ` }
                            </Text> {t(title)}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            {
                                userAnswers.map((eachCheckboxAnswer, checkboxIndex) => {
                                    return (
                                        <View key={checkboxIndex}>
                                            <Checkbox isChecked={true} />
                                            <Text>{t(eachCheckboxAnswer?.title)}</Text>
                                        </View>
                                    );
                                })
                            }
                        </View>
                    </View>
                )
            }
            else if(type === "radiobox"){
                return (
                    <View style={styles.question} key={questionIndex} break>
                        <Text style={styles.questionTitle}>
                            <Text style={styles.fontBold}>
                                { `${subSectionPrefix ? subSectionPrefix + '.' : ''}${questionIndex + 1} ` }
                            </Text> {t(title)}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            {
                                userAnswers && (
                                    <View>
                                        <Text>
                                            {t(userAnswers?.title)}
                                        </Text>
                                        {(userAnswers?.children || []).length > 0 && (
                                            <View style={styles.subQuestion}>
                                                {renderSubQuestions(userAnswers?.children, (question?.options || []).find((op) => {
                                                    return parseInt(op.id) === parseInt(userAnswers.value)
                                                })?.children)}
                                            </View>
                                        )}
                                    </View>
                                )
                            }
                        </View>
                    </View>
                )
            }
            else if(type === "file_upload"){
                return (
                    <View style={styles.question} key={questionIndex} break>
                        <Text style={styles.questionTitle}>
                            <Text style={styles.fontBold}>
                                { `${subSectionPrefix ? subSectionPrefix + '.' : ''}${questionIndex + 1} ` }
                            </Text> {t(title)}
                        </Text>
    
                        <View style={styles.questionAnswer}>
                            {
                                (userAnswers || []).map((f, i) => {
                                    return (
                                        <View key={i}>
                                            <Text>{ f.name }</Text>
                                        </View>
                                    );
                                })
                            }
                        </View>
                    </View>
                )
            }

            return null;
        })  
    }

    const renderQuestionsGroup = (sectionIndex, items) => {
        const subSections = groupQuestionsBySubSection(items);

        if(subSections.length === 1){
            return (
                <>
                    { renderQuestions(subSections[0].items, sectionIndex) }
                </>
            )
        }

        let panelIndex = 0

        return subSections.map((subSection) => {
            const panelPrefix = `${sectionIndex}.${panelIndex + 1}`;
            panelIndex++;

            return (
                <View style={styles.subSection} key={panelIndex}>
                    <Text style={styles.subSectionTitle}>
                        {panelPrefix} { t(subSection.name) }
                    </Text>

                    <View style={styles.subSectionBody}>
                        { renderQuestions(subSection.items, panelPrefix) }
                    </View>
                </View>
            )
        })
    }

    return (
        <>
            {sections.map((section, index) => {
                return (
                    <View style={styles.section} key={index}>
                        <Text style={styles.sectionTitle}>
                            {`${index + 1}. ${ t(section.name)}`}
                        </Text>

                        <View style={styles.sectionBody}>
                            { renderQuestionsGroup(index + 1, section.items) }
                        </View>
                    </View>
                )
            })}
        </>
    );
};

export default withNamespaces()(PageContent);
