import { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Col, Row, Button, Spinner } from "reactstrap";
import paginationFactory, {
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import DateUtils from "src/services/utils/DateUtils";
import { 
    EMPTY_LIST, 
    INTERNATIONAL_DATE_FORMAT,
} from "src/common/constants"; 
import {
    HistoryLogActions,
    HistoryLogSections,
    HistoryLogRoles,

    API_BASE_URL
} from "src/modules/3rd-party-management/constants";
import DetailsModal from "../../../../components/history-log/details-modal";
import FilteringComponent from "../../../../components/history-log/filtering-component";

const HistoryLogs = ({ 
    t, 
    token,
    supplierId
}) => {
    const historyUrl = `/supplier/${supplierId}/logs`;
    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    });

    const [tableResultTotalCount, setTableResultTotalCount] = useState(0);

    const [logs, setLogs] = useState([]);

    const [selectedLog, setSelectedLog] = useState(null);

    const dateUtils = new DateUtils();

    const tableColumns = [
        {
            dataField: "secondary_id",
            text: "#",
            sort: false,
            key: 1,
            style: {
                width: 120,
            }
        },
        {
            dataField: "action",
            text: t("Activity"),
            sort: false,
            key: 2,
            style: {
                width: 180,
            },
            formatter: (cellContent, row) => {
                return t(HistoryLogSections[row.section]);
            },
        },
        {
            dataField: "date_time",
            text: t("Date and time"),
            sort: false,
            key: 3,
            style: {
                width: 180,
            },
            formatter: (cellContent, row) => {
                return (
                <div>
                    <span>
                    {dateUtils.convertDateToDate(
                        row.created_at,
                        INTERNATIONAL_DATE_FORMAT
                    )}
                    </span>
                    <br />
                    <span className="text-muted">
                    {dateUtils.convertDateToDate(row.created_at, "HH:mm")}
                    </span>
                </div>
                );
            },
        },
        {
            dataField: "user_name",
            text: t("User"),
            sort: false,
            key: 4,
            style: {
                width: 180,
            },
        },
        {
            dataField: "user_role",
            text: t("User Role"),
            sort: false,
            key: 5,
            style: {
                width: 180,
            },
            formatter: (cellContent, row) => {
                return HistoryLogRoles[row.user_role];
            },
        },
        {
            dataField: "description",
            text: t("Description"),
            sort: false,
            key: 6,
            formatter: (cellContent, row) => {
                const section  = HistoryLogActions[row.section];

                return t(section ? section[row.action] : '');
            },
        },
        {
            dataField: "actions",
            text: t("Action"),
            sort: false,
            key: 7,
            formatter: (cellContent, row) => {
                return (
                <div className="actions">
                    <Button
                    color="primary"
                    onClick={() => setSelectedLog(row)}
                    outline
                    className="border-0">
                    <i className="ri-eye-line"></i>
                    </Button>
                </div>
                );
            },
        }
    ];

    const NoDataIndication = () =>
        handleFetchSupplierHistoryLogQuery.isFetched &&
        !handleFetchSupplierHistoryLogQuery.length ? (
            <div className="alert m-0" role="alert">
                <p
                style={{
                    textAlign: "center",
                    marginBottom: 0,
                }}
                >
                {t(EMPTY_LIST)}
                </p>
            </div>
        ) : (
            <></>
        );

    const handleFetchSupplierHistoryLogQuery = useQuery({
        queryKey: [
            "3rd-party-admin-history-logs", 
            historyUrl, 
            (new URLSearchParams(
                Object.fromEntries(Object.entries(filters).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
        queryFn: async () => {
            const response = await axios.get(`${API_BASE_URL}${historyUrl}`, {
                params: filters,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            return response.data?.data || [];
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t("An error occurred while fetching supplier history logs."), {
                type: "error"
            });
        }
    });

    const handleTableChange = (type, { page, sizePerPage }) => {
        switch (type) {
            case "pagination":
                setFilters({
                    ...filters,
                    pageIndex: page,
                    pageSize: sizePerPage,
                });
                break;
            default:
                return false;
        }
    };

    useEffect(() => {
        if (handleFetchSupplierHistoryLogQuery.data) {
            setLogs(handleFetchSupplierHistoryLogQuery.data.logs || []);
            setTableResultTotalCount(
                handleFetchSupplierHistoryLogQuery.data.itemsCount || 0
            );
        }
    }, [handleFetchSupplierHistoryLogQuery.data]);

    return (
        <div className='p-4'>
            <Row>
                <Col sm="12">
                    <PaginationProvider
                        pagination={paginationFactory({
                            custom: true,
                            page: filters.pageIndex,
                            sizePerPage: filters.pageSize,
                            totalSize: tableResultTotalCount,
                            withFirstAndLast: false,
                            alwaysShowAllBtns: true,
                            prePageText: (
                                <span>
                                    <i className="ri-arrow-left-s-line"></i> {t("Back")}
                                </span>
                            ),
                            nextPageText: (
                                <span>
                                    {t("Next")} <i className="ri-arrow-right-s-line"></i>
                                </span>
                            ),
                            prePageTitle: t("Pre page"),
                            firstPageTitle: t("Next page"),
                            showTotal:   false,
                            paginationTotalRenderer :   (from, to, size) => {
                                return (
                                    <span className="react-bootstrap-table-pagination-total">
                                        {`${t('Total Items')} ${size}`}
                                    </span>
                                )
                            },
                            hideSizePerPage: false,
                            sizePerPageList: [
                                {
                                    text: "25",
                                    value: 25,
                                },
                                {
                                    text: "50",
                                    value: 50,
                                }
                            ]
                        })}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <Row className="mb-3 mt-3">
                                    <Col sm="12">
                                        <div className="d-flex gap-4 justify-content-end">
                                            <FilteringComponent 
                                                onChange={(newFilters) => {
                                                    setFilters({
                                                        ...filters,
                                                        ...newFilters,
                                                        pageIndex: 1
                                                    });
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <BootstrapTable
                                            remote={{
                                                pagination: true,
                                                filter: false,
                                                sort: true,
                                                cellEdit: false,
                                                search: false,
                                            }}
                                            loading={
                                                handleFetchSupplierHistoryLogQuery.isFetching ||
                                                handleFetchSupplierHistoryLogQuery.isLoading
                                            }
                                            overlay={overlayFactory({
                                                spinner: (
                                                    <Spinner
                                                        animation="border"
                                                        variant="primary"
                                                        size="md"
                                                    />
                                                ),
                                                text: `${t("Loading")}...`
                                            })}
                                            onTableChange={handleTableChange}
                                            defaultSorted={[]}
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            data={logs}
                                            striped={false}
                                            columns={tableColumns}
                                            wrapperClasses="table-responsive"
                                            classes={"table tpdd-table"}
                                            headerWrapperClasses={"thead-light"}
                                            style={{
                                                overflowX: "auto",
                                            }}
                                            noDataIndication={() => <NoDataIndication />}
                                            {...paginationTableProps}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <div className="tpdd-pagination-style-1">
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />

                                            <div>
                                                <PaginationListStandalone {...paginationProps} />

                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </PaginationProvider>

                    {selectedLog && (
                        <DetailsModal
                            logId={selectedLog.id}
                            supplierId={supplierId}
                            onClose={() => setSelectedLog(null)}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { token } = state.Login;

    return {
        token
    };
};

export default withNamespaces()(
    withRouter(connect(mapStateToProps)(HistoryLogs))
);