import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { 
    removeNotificationInList,
    markNotificationAsRead
} from "src/store/actions";
import {
    INTERNATIONAL_DATE_TIME_FORMAT
} from 'src/common/constants';
import DateUtils from 'src/services/utils/DateUtils';

const NotificationItem = ({
    t,
    notification,
    link, 
    content,
    customOnClick,
    onClick,

    removeNotification,
    markNotificationAsRead
}) => {
    const history = useHistory();
    const dateUtils = new DateUtils();

    return (
        <div className='notification-item'>
            <div
                className='d-flex'
                onClick={() => {
                    if(customOnClick){
                        return customOnClick();
                    }

                    if(onClick){
                        onClick();
                    }

                    if(!notification.currentState?.isRead){
                        markNotificationAsRead(notification.id)
                    }
                    
                    setTimeout(() => {
                        history.push(`/${window.location.pathname.split('/', 4)[3]}/${link}`)
                    }, 500);
                }}
            >
                <div className='notification-content d-flex'>
                    <div className='avatar-xs me-3'>
                        {!notification.currentState?.isLoading ? (
                            <span className='avatar-title bg-info rounded-circle font-size-16'>
                                <i className='ri-notification-3-line'></i>
                            </span>
                        ) : (
                            <Spinner size='sm' color='primary' />
                        )}
                    </div>
                    <div className='flex-1'>
                        <h6 className={`mt-0 mb-1 ${!notification.currentState?.isRead ? 'not-read' : ''}`}>
                            Smart Integrity Platform {notification?.message_type === "report_request_assigned"? t("Entity update") : notification?.data?.caseId ? t('Case update') : t('System update')}:
                        </h6>
                        
                        <div className='font-size-12 text-muted'>
                            <p className='mb-1'>
                                {content}
                            </p>
                            <p className='mb-0'>
                                <i className='mdi mdi-clock-outline'></i>
                                {` ${dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}`}
                            </p>
                        </div>
                    </div>
                </div>

                <div onClick={(e) => {
                        e.stopPropagation();
                        if(!notification?.currentState?.isDeleting){
                            removeNotification(notification?.id)
                        }
                    }}>
                        {notification.currentState?.isDeleting ?
                            <div className='d-flex justify-content-center'>
                                <Spinner  size="sm" color="primary" />
                            </div>
                            :
                            <i className="mdi mdi-trash-can text-danger font-size-18"></i>
                        }
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
	return {
        organization    :   state.Organization,
        user            :   state.Login?.user,
        token           :   state.Login?.token
	};
};

const mapDispachToProps = (dispatch) => {
	return {
		removeNotification  :  (notificationId) => dispatch(removeNotificationInList({
            notificationId
        })),
        markNotificationAsRead  : (notificationId) => dispatch(markNotificationAsRead({
            notificationId
        })) 
    };
};

export default connect(
    mapStatetoProps, mapDispachToProps
)(
    withNamespaces()(
        NotificationItem
    )
);