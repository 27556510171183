import React from 'react';
import { View, StyleSheet, Image, Svg, Path, Defs, LinearGradient, Stop } from "@react-pdf/renderer";

const Header = ({ backgroundColor, startColor, stopColor, logo }) => {
    const styles = StyleSheet.create({
        header: {
            backgroundColor,
            color: 'white',
            textAlign: 'center',
            position: 'relative',
            height: '56px',
        },
        logo: {
            position: 'absolute',
            left: 58,
            bottom: 14,
            gap: 11,
            ...logo.size
        },
        title: {
            marginTop: 20,
            fontSize: 18,
            fontWeight: 'bold',
        },
    });

    const GradientBackground = () => (
        <Svg height="56">
            <Path d="M491.67 41.2526L490.522 40.4725L489.243 39.6977L487.836 38.9288L486.304 38.1664L484.65 37.4111L482.878 36.6634L480.992 35.9239L478.998 35.1929L476.899 34.471L474.702 33.7585L472.413 33.0557L470.037 32.3629L467.582 31.6804L465.055 31.0082L462.462 30.3466L459.811 29.6954L457.109 29.0548L454.365 28.4245L451.586 27.8044L448.78 27.1942L445.955 26.5937L443.12 26.0025L440.282 25.4202L437.449 24.8461L434.629 24.2797L431.829 23.7205L429.057 23.1676L426.32 22.6204L423.625 22.0781L420.978 21.5397L418.386 21.0044L415.853 20.4713L413.385 19.9394L410.987 19.4078L408.663 18.8755L406.417 18.3414L404.251 17.8045L402.169 17.2639L400.171 16.7185L398.26 16.1674L396.435 15.6097L394.697 15.0444L393.045 14.4707L391.477 13.8878L389.992 13.2949L388.587 12.6913L387.258 12.0764L386.002 11.4497L384.813 10.8107L383.688 10.1591L382.621 9.49464L381.604 8.81711L380.633 8.12646L379.7 7.42277L378.797 6.70619L377.916 5.97704L377.049 5.23584L376.187 4.48328L375.319 3.72024L374.435 2.94776L373.525 2.16708L372.578 1.3796L371.583 0.586851L370.807 0L0 0L0 55.9992H488.765L489.649 55.4743L490.824 54.7054L491.886 53.9312L492.832 53.1522L493.659 52.3689L494.366 51.5817L494.949 50.7911L495.406 49.9977L495.734 49.202L495.933 48.4046L496 47.6061L495.934 46.807L495.732 46.0081L495.395 45.2098L494.922 44.413L494.312 43.6182L493.566 42.8261L492.685 42.0373L491.67 41.2526Z" fill="url('#myLinearGradient')"/>
            <Defs>
                <LinearGradient id="myLinearGradient">
                    <Stop stopColor={startColor}/>
                    <Stop offset="1" stopColor={stopColor}/>
                </LinearGradient>
            </Defs>
        </Svg>
    )

    return (
        <View style={styles.header} fixed>
            <GradientBackground  />
            <Image style={styles.logo} src={logo.src || {}} />
        </View>
    );
};

export default Header;