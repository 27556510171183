import { useMemo, memo } from 'react';
import HorizontalMenuContent from './Layouts/Horizontal';
import VerticalMenuContent from './Layouts/Vertical';
import { connect } from "react-redux";
import {
    modulesMenuItems
} from './Data/index';
import { 
    isFeatureEnabledForActiveModule,
    isSettingEnabledForActiveModule
} from '../../helpers/module_helper';

const MenuGenerator = (props) => {
    const {
        layout,
        workspace,
        modules,
        user,
        menuOpen = false
    } = props;

    const validateMenuItem = (item) => {
        if(item?.authProtected && !user){
            return false;
        }

        if(item?.roles && item?.roles.length > 0){
            if(!user){
                return false;
            }

            let userHasRelatedRole = false;

            for(let i = 0; i < item.roles.length; i++){
                const itemEachRole = item.roles[i];

                if((user?.roles || []).includes(itemEachRole)){
                    userHasRelatedRole = true;
                    break;
                }
            }

            if(!userHasRelatedRole){
                return false;
            }
        }

        if(item?.settingKey && !isSettingEnabledForActiveModule(item.settingKey)){
            return false;
        }

        if(item?.moduleFeatures && item.moduleFeatures.length > 0){
            let moduleHasAnyFeature = false;

            for(let i = 0; i < item.moduleFeatures.length; i++){
                if(isFeatureEnabledForActiveModule(item.moduleFeatures[i])){
                    moduleHasAnyFeature = true;
                    break;
                }
            }

            if(!moduleHasAnyFeature){
                return false;
            }
        }

        if(item?.customValidator && typeof item.customValidator === 'function'){
            return item.customValidator({
                menuItem    :   item,
                modules     :   modules,
                user        :   user
            });
        }

        return true;
    }

    const renderMenuItems = (menuItems) => {
        const result = [];

        menuItems.forEach((item) => {
            const validateResp = validateMenuItem(item);

            if(validateResp){
                const subMenuItems = item?.items || [];

                if(!subMenuItems.length){
                    result.push(item);
                    return;
                }

                const subMenuResult = subMenuItems.filter((subMenuItem) => {
                    return validateMenuItem(subMenuItem);
                });

                if(subMenuResult.length > 0){
                    item.items = subMenuResult;

                    result.push(item)
                }
            }
        });

        return result;
    }

    const menuItems = useMemo(() => {
        const modulesMenuItemsClone = JSON.parse(JSON.stringify(modulesMenuItems));
        const moduleMenus = modulesMenuItemsClone[modules?.active];
        
        if(moduleMenus && moduleMenus[workspace.name]){
            return renderMenuItems(moduleMenus[workspace.name]);
        }
    }, [workspace, modules, user]);

    return (
        layout === "horizontal" ? (
            <HorizontalMenuContent 
                menu={ menuItems || [] }
                menuOpen={menuOpen}
            />
        ) : (
            <VerticalMenuContent 
                menu={ menuItems || [] }
            />
        )
    );
}

const mapStatetoProps = (state) => {
    const { Modules: modules } = state;

    const {
        Login : {
            user,
            token
        },
        Workspace
    } = state;
  
    return {
      modules,
      user,
      token,
      workspace: Workspace,
    };
};

export default connect(
    mapStatetoProps, {}
)(memo(MenuGenerator));