import React from "react";
import { AvField, AvRadioGroup, AvRadio, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Row, Col, Label } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import ExtendableAvField from "../Components/ReportComponents/ExtendableAvField";
import { NotShowOrgInfosOrganizations } from "src/common/constants";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount = () => {
  //   console.log("Props", this.props)
  // }


  renderContactForm = () => {
    const { t, contactForm, handleInputChange, userSelection, user } = this.props;
    // console.log("User", contactForm);
    const defaultValues = {
      title: this.props?.contactForm?.title
    }

    {/* 
        * SPIK-7479
        * This is an uggly way to handle this task. 
        * This part should be changed in the future after handling it by the backend.
    */}
    const hideOrgInfo = this.props.modules.active === 'cm' && NotShowOrgInfosOrganizations.includes(this.props.Organization.id);
    
    return (
      <div>

        <FormGroup>
          <Label>{t("What is your relation to the company?")}</Label>
          <AvRadioGroup
            name="relation"
            required={userSelection === "yes" && !contactForm.relation}
          >
            <Row className="mb-3">
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Employee")}
                  value="Employee"
                  name="relation"
                  onChange={handleInputChange}
                  checked={contactForm.relation === "Employee"}
                />
              </Col>
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("External")}
                  value="External"
                  name="relation"
                  onChange={handleInputChange}
                  checked={contactForm.relation === "External"}
                />
              </Col>
            </Row>
          </AvRadioGroup>
        </FormGroup>
        
        <AvForm  model={defaultValues} >
          <Label>{t("Salutation (Optional)")}</Label>
          <AvRadioGroup name="title">
            <Row className="mb-3">
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Mr.")}
                  value="Mr."
                  name="title"
                  onChange={handleInputChange}
                  checked={user?.gender === "Mr." || contactForm.title === "Mr."}
                />
              </Col>
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Mrs.")}
                  value="Mrs."
                  name="title"
                  onChange={handleInputChange}
                  checked={contactForm.title === "Mrs." || user?.gender === "Mrs."}
                />
              </Col>
            </Row>
          </AvRadioGroup>
        </AvForm>

        <Row className="mb-3">
          <Col {...{
            md: hideOrgInfo ? '6' : '4',
            lg: hideOrgInfo ? '6' : '3'
          }}>
            <ExtendableAvField
              name="firstName"
              label={t("First name")}
              type="text"
              required={userSelection === "yes" && !contactForm.firstName}
              value={contactForm.firstName}
              // defaultValue={user?.first_name}
              onChange={handleInputChange}
            />
          </Col>
          <Col {...{
            md: hideOrgInfo ? '6' : '4',
            lg: hideOrgInfo ? '6' : '3'
          }}>
            <ExtendableAvField
              name="lastName"
              label={t("Last name")}
              type="text"
              value={contactForm.lastName}
              // defaultValue={user?.last_name}
              required={userSelection === "yes" && !contactForm.lastName}
              onChange={handleInputChange}
            />
          </Col>
          {!hideOrgInfo && (
            <Col md="4" lg="3">
                <ExtendableAvField
                name="organization"
                label={t("Organization")}
                // required={userSelection === "yes" && !contactForm.organization}
                type="text"
                value={contactForm.organization}
                // defaultValue={user?.organization?.name}
                onChange={handleInputChange}
                />
            </Col>
          )}
        </Row>

        {!hideOrgInfo && (
            <Row className="mb-3">
                <Col xs="9" sm="9" md="9" lg="9">
                    <ExtendableAvField
                    name="position"
                    label={t("Position")}
                    // required={userSelection === "yes" && !contactForm.position}
                    type="text"
                    value={contactForm.position}
                    // defaultValue={user?.position}
                    onChange={handleInputChange}
                    />
                </Col>
            </Row>
        )}
      </div>
    );
  };

  render() {
    return <div>{this.renderContactForm()}</div>;
  }
}

// export default ContactForm;
const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App, Modules } = state;
  return { token, Organization, user, App, modules: Modules };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { })(
      ContactForm
    )
  )
);
