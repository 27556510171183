import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
    ACTION_GET_INFO_ORGANIZATION,
    ACTION_GET_INFO_ORGANIZATION_SUCCESS
} from './actionTypes';

import {
    API_URL_VERIFY_ORGANIZATION,
    MODULES_FEATURES_ROUTES
  } from "../../common/constants";

import {
    appError,
    organizationModulesLoadedSuccessfully,
    organizationDoestHaveActiveModuleError,
    setOrganizationActiveModule,
    onGetInfoOrganizationSuccess,
    changeLayoutDirection,
    saveOrganizationActiveModuleOnRemote,
    changePreloader,
    startFetchingNotifications
} from '../actions';

import { post } from "../../helpers/request_helper";

function storeOrganizationId(orgId){
    localStorage.setItem('orgId', orgId);
    return true;
}

function setOrganizationDefaultLanguage(languages){
    const prevLngCode = localStorage.getItem('i18nextLng');
    const prevLanguage = languages.find((lng) => lng.language_code === prevLngCode);

    const resultLang = prevLanguage || languages.find((lng) => lng.is_default)

    localStorage.setItem('i18nextLng', resultLang?.language_code);

    return resultLang;
}

function* loadOrganizationInfo({
    payload :   organization
}){
    try{
        const response = yield call(
            post,
            API_URL_VERIFY_ORGANIZATION,
            {
                id  :   organization.id,
                url :   organization.url
            }, {}
        );

        const {
            data    :   organizationInfo
        } = response.data;

        const availableModules =  organizationInfo.modules.filter((m) => {
            return m.hasModule;
        }); 

        if(!availableModules.length){
            yield put(organizationDoestHaveActiveModuleError());
        }
        else{
            const defaultLanguage = yield call(setOrganizationDefaultLanguage, organizationInfo.languages);

            yield put(changeLayoutDirection(defaultLanguage.direction));

            yield call(storeOrganizationId, organizationInfo.id);
            
            yield put(onGetInfoOrganizationSuccess(organizationInfo));

            const prevUsedModuleName = new URLSearchParams(window.location.search).get('module') || localStorage.getItem('module');

            const prevUsedModule = availableModules.find((m) => {
                return m.name === prevUsedModuleName;
            });

            for(let i = 0; i < availableModules.length; i++){
                const avaModule = availableModules[i];
                const moduleSettings = organizationInfo.setting[avaModule.name];

                avaModule.settings = {
                    isChat              :   !!moduleSettings?.chat,
                    isMeeting           :   !!moduleSettings?.meeting,
                    isVoice             :   !!moduleSettings?.voice,
                    isAnonymously       :   !!moduleSettings?.anonymously,
                    isConfidentially    :   !!moduleSettings?.confidentially,
                    isElearning         :   !!moduleSettings?.elearning
                };

                avaModule.features = [];

            }

            yield put(organizationModulesLoadedSuccessfully({
                allModules              :   organizationInfo.modules,
                organizationModules     :   availableModules
            }));

            yield put(setOrganizationActiveModule(prevUsedModule?.name || availableModules[0].name));

            yield put(startFetchingNotifications());

            yield put(changePreloader(true));
        }
    }
    catch(error){
        yield put(appError({
            status  :   true,
            code    :   error,
            message :   ''
        }));
    }
}

function* organizationSaga() {
    yield takeEvery(ACTION_GET_INFO_ORGANIZATION, loadOrganizationInfo);
}

export default organizationSaga;