import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import QuestionnaireService from 'src/modules/3rd-party-management/apis/QuestionnaireService';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Col, Row } from 'reactstrap';
import {
    MultiTabQuestionnaire,
    helpers,
    useAutoSave
} from '@smartintegrity/questionnaire';
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';
import SupplierService from 'src/modules/3rd-party-management/apis/thirdparty/SupplierService';
import {
	confirmPromptMakeReadyToShow,
	confirmPromptHide,
	confirmPromptUpdateAlertProps
} from 'src/store/actions';

const QuestionsLoadingPlaceholder = () => {
    return (
        <div>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

const FillQuestionnaire = ({
    t,
    token, 
	Organization,
    onBackButtonClicked,
    onValidSubmit,

	exitConfirmPromptOptions, 
	makeConfirmPromptReadyToShow, 
	hideConfirmPrompt
}) => {
    const history = useHistory();

    const [ reportCase, setReportCase ] = useState({
        id: null,
        refetch: false,
        isLoading: true
    });
    const [ questions, setQuestions ] = useState([]);
    const [ prevAnswers, setPrevAnswers ] = useState([]);

    const {
        control
    } = useAutoSave({
        enable: false,
        onlyChangedItems: false,
        stepInterval: 7,
        saveRequestOptions: {
            headers: {
                Authorization: `Bearer ${token}`
            },
            payload: (payload) => {
                return {
                    questionAnswers: [
                        ...payload
                    ],
                    questionnaireType: "tpdd"
                }
            },
            requestUrl: () => {
                return `${API_BASE_URL}/report/${ reportCase.id }/edit`
            }
        }
    });

    const {
        autoSaveState: {
            unsavedStepsCount,
            latestRequestState
        },
        autoSaveUpdateOptions,
        autoSaveSaveCurrentState
    } = control;

    const [ questionnaireCompleted, setQuestionnaireCompleted ] = useState(false);

    const settings = useMemo(() => {
        let submitButtonSettings = {
            enable: false,
            title: t('Next')
        }
        
        if(questionnaireCompleted){
            if(latestRequestState === 'in_process'){
                submitButtonSettings.title = (
                    <>
                        <Spinner 
                            className="me-2" 
                            animation="border" 
                            variant="info" 
                            size="sm"
                        />
                        {t('Please wait')}...
                    </>
                )
            }
            else if(latestRequestState !== 'failed'){
                submitButtonSettings = {
                    title: t('Next'),
                    enable: true
                }
            }
        }

        return {
            tabSettings: {
                showNumber: true,
                showProgressBar: true,
            }, 
            buttonsSettings: {
                submit: {
                    display: true,
                    ...submitButtonSettings
                },
                back: {
                    display: true,
                    enable: true,
                    title: t('Back'),
                    onClick: () => {
                        onBackButtonClicked && onBackButtonClicked();
                    }
                }
            },
            fileUploaderOptions: {
                deleteRequestOptions: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    payload: {},
                    requestUrl: ({
                        questionId,
                        file
                    }) => {
                        return `${API_BASE_URL}/attachment/${file.id}/delete`
                    }
                },
                uploadRequestOptions: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    payload: {},
                    requestUrl: ({
                        questionId
                    }) => {
                        return `${API_BASE_URL}/report.uploadAttachment`
                    }
                }
            },
            questions: {
                showNumber: true
            }
        }
    }, [ 
        token, 
        questionnaireCompleted, 
        t,
        latestRequestState
    ]);

    const handleFetchPrevFilledAnswers = useQuery({
		queryKey: [
            '3rd-party-fetch-questionnaire-prev-answers-query',
            'tpdd',
            Organization.id
        ],
		queryFn: async () => {
			const service = QuestionnaireService.getInstance();
	
			return await service.fetchPrevFilledAnswers('tpdd', Organization.id, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		retry: 0,
		onError: ({
			response
		}) => {
			if((response.data.error).toLowerCase() === 'there is not any report for this questionnaire'){
				axios.post(`${API_BASE_URL}/report.create`, {
					"questionnaireType"		:	'tpdd',
					"questionAnswers"	    :	[],
                    "reportRequest"         :   openReportRequestsList?.request?.id
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(() => {
					setReportCase((currentState) => {
                        return {
                            ...currentState,
                            refetch: true
                        }
                    });
				})
				.catch(() => {
                    hideConfirmPrompt();

                    history.push('/third-party/3rd-party/home');
				});
			}
		},
		onSuccess : (response) => {
            setReportCase({
                id: response.reportCaseId,
                refetch: false,
                isLoading: false
            });
		}
	});

    const {
        data: openReportRequestsList
    } = useQuery({
		queryKey: [
            '3rd-party-management-fetch-supplier-report-requests',
            'tpdd',
            Organization.id
        ],
		queryFn: async () => {
			const service = SupplierService.getInstance();

			return await service.fetchUserReportRequests();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        retry: 0,
		onError: ({
            response
        }) => {
            hideConfirmPrompt();
            
            if(response.data.error === 'report_request_list_empty'){
                toast(t('It is not possible to send a report before the request is created by admin'), {
                    type: 'error',
                });
            }
            else{
                toast(t('An error occurred while starting!'), {
                    type: 'error',
                });
            }

            history.push('/third-party/3rd-party/home');
		}
	});

	useEffect(() => {
        if(reportCase.refetch){
            handleFetchPrevFilledAnswers.refetch();
        }
	}, [reportCase.refetch]);

    useEffect(() => {
        if(openReportRequestsList?.request?.id){
            handleFetchPrevFilledAnswers.refetch();
        }
    }, [ openReportRequestsList ])

    useEffect(() => {
        if(!reportCase.isLoading && reportCase.id){
            autoSaveUpdateOptions((currentOptions) => {
                return {
                    ...currentOptions,
                    enable: true,
                    saveRequestOptions: {
                        ...currentOptions.saveRequestOptions,
                        requestUrl: () => {
                            return `${API_BASE_URL}/report/${ reportCase.id }/edit`
                        }
                    }
                }
            })
        }
    }, [ reportCase ])

    useEffect(() => {
        if(handleFetchPrevFilledAnswers?.data?.questions){
            setPrevAnswers(helpers.makePreviousAnswersReadyForQuestionnaire(
                handleFetchPrevFilledAnswers.data.questions,
                handleFetchPrevFilledAnswers.data.questions
            ));

            setTimeout(() => {
                setQuestions(helpers.makeQuestionsReadyForQuestionnaire(
                    handleFetchPrevFilledAnswers.data.questions
                ));
            }, 1000);
        }
    }, [ handleFetchPrevFilledAnswers?.data ]);

    useEffect(() => {
        if(latestRequestState === 'in_process') return;

        if(exitConfirmPromptOptions.show){
            if(unsavedStepsCount > 0){
                autoSaveSaveCurrentState();
            }
            
            if(latestRequestState === 'succeed'){
                exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
                hideConfirmPrompt();
                toast(t('Questionnaire saved successfully.'), {
                    type: 'success',
                });
            }
            else if(latestRequestState === 'failed'){
                hideConfirmPrompt();
                toast(t('An error occurred while saving questionnaire!'), {
                    type: 'error',
                });
            }

            return;
        }

        if(unsavedStepsCount > 0){
            if(!exitConfirmPromptOptions.readyToShow){
                makeConfirmPromptReadyToShow({
                    title       : (
                        <div>
                            <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                            {t('Saving the current state')}...
                        </div>
                    ),
                    message     : t("please don't close or refresh the page"),
                    alertProps  : {
                        ...exitConfirmPromptOptions.alertProps,
                        confirmBtn	:	{
                            ...exitConfirmPromptOptions.alertProps.confirmBtn,
                            enabled	:	false
                        },
                        cancelBtn	:	{
                            ...exitConfirmPromptOptions.alertProps.cancelBtn,
                            enabled	:	false
                        }
                    }
                });
            }
        }
        else{
            if(exitConfirmPromptOptions.readyToShow && 
                latestRequestState !== 'ready_to_send'){
                    hideConfirmPrompt();
            }
        }
    }, [
        unsavedStepsCount,
        latestRequestState,
        exitConfirmPromptOptions.show,
        exitConfirmPromptOptions.readyToShow,
    ]);

    if(reportCase.isLoading  || !questions.length){
        return (
            <QuestionsLoadingPlaceholder />
        )
    };

    return (
        <MultiTabQuestionnaire 
            className='tpdd'
            questions={questions}
            defaultValues={prevAnswers}
            settings={settings}
            onQuestionnaireCompleteStatusChanged={(_currentPayload, newStatus) => {
                setQuestionnaireCompleted(newStatus)
            }}
            onSubmit={(payload) => {
                onValidSubmit && onValidSubmit({
                    reportCaseId: reportCase.id,
                    questions: questions,
                    answers: payload
                })
            }}
            {...control}
        />
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, ExitConfirmPrompt } = state;
	
	return {
		token,
		user,
		Organization,
		App,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            makeConfirmPromptReadyToShow  		: 	(payload) => confirmPromptMakeReadyToShow(payload),
            hideConfirmPrompt             		: 	() => confirmPromptHide(),
            updateConfirmPromptAlertProps 		: 	(payload) => confirmPromptUpdateAlertProps(payload)
        })(
            FillQuestionnaire
        )
    )
);