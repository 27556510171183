import React, { Component } from "react";

import { Row, Col, Button, Label, Input } from "reactstrap";

import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  registerUser,
  setUserAccessToken,
  changeWorkspace,
  errorNotification,
  successNotification,
  registerUserSuccessful,
} from "../../store/actions";

import NotificationUtils from "../../services/utils/NotificationUtils";

import { API_URL_REGISTER } from "../../common/constants";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";

import Password from "../../components/Fields/Password";
import UrlUtils from "../../services/utils/UrlUtils";
import UserUtils from "../../services/utils/UserUtils";

import OrganizationHeader from "./components/OrganizationHeader";

import axios from "axios";

class Register extends Component {
  constructor(props) {
    super(props);
    this.initialTime = 60;
    this.urlUtils = new UrlUtils();
    this.state = {
      otp: {
        error: false,
        message: "",
        code: null,
      },
      countDown: this.initialTime,
      isThirdPartyUrl: false,
      isThirdPartyUser: false,
      organization: this.urlUtils.getOrganizationId(),
      regConsent: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.notificationUtils = new NotificationUtils();
    this.userUtils = new UserUtils();
  }

  // Is user register successfull.
  registerUser = (user) => {
    const { regConsent } = this.state;
    const url = API_URL_REGISTER;
    const response = axios
      .post(
        url,
        {
          email: user.email || "",
          username: user.username,
          plainPassword: user.password,
          organization: user.organization,
          privacyPolicyAccepted: regConsent,
          userPolicyAccepted: regConsent
        },
        {}
      )
      .then((res) =>
        {
          // this.setState({
          //   ...this.state,
          // })
  
          this.props.registerUserSuccessful({
            ...res.data.data.profile,
            needExchange: !!res.data.data.needExchange,
            tempToken: res.data.data.token,
          })
        }
        
      )
      .catch((err) => {
        const errorTitle = this.props.t(err.response.data.title);
        const errors = err.response.data.errors;
        // console.log(err)
        for (const [key, value] of Object.entries(errors)) {
          this.props.errorNotification({
            code: errorTitle,
            message: `${key}: ${value}`,
          });
        }
      });
  };

  handleSubmit(event, values) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;

    if (emailRegex.test(values.username)) {
      return this.props.errorNotification({
        code: "username-not-valid",
        message: this.props.t("Please enter a valid username"),
      });
    }
    if (!passwordRegex.test(values.password)) {
      this.props.errorNotification({
        code: "passwords-not-valid",
        message: this.props.t(
          "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
        ),
      });
    } else {
      if (typeof this.urlUtils.getOrganizationId() !== "undefined") {
        values.organization = this.urlUtils.getOrganizationId();
      }
      localStorage.setItem("org", this.urlUtils.getOrganizationName());
      // this.props.registerUser(values);
      
      // console.log("Val", values)
      this.registerUser(values);

      this.startCountDown();
    }
  }

  checkUrlParamThirdParty() {
    if (this.urlUtils.getUrlParamThirdParty() === "third-party") {
      this.setState({
        isThirdPartyUrl: true,
        isThirdPartyUser: true,
      });
    } else {
      this.setState({
        isThirdPartyUrl: false,
      });
    }
  }

  setWorkspace() {
    const workspace = this.userUtils.getWorkspace(this.props.user);
    this.props.changeWorkspace(workspace);
  }

  getAfterRegisterRedirectRoute() {
    let redirect = new URLSearchParams(this.props.location.search).get(
      "redirect"
    );
    switch (redirect) {
      // case "livechat":
      //   return (
      //     this.userUtils.getUserTypeBaseRoute(this.props.user) + "/livechat"
      //   );
      default:
        return this.userUtils.getUserHomeRoute(this.props.user);
    }
  }

  timer = () => {
    this.interval = setInterval(() => {
      this.setState(
        (prevState) => ({
          countDown: prevState.countDown - 1,
        }),
        () => {
          if (this.state.countDown < 1) {
            this.stopCountDown();
          }
        }
      );
    }, 1000);
  };

  stopCountDown = () => {
    clearInterval(this.interval); // Not working
  };

  startCountDown = () => {
    this.timer();
  };

  componentDidMount() {
    document.body.classList.add("auth-body-bg");
    this.checkUrlParamThirdParty();
  }

  componentDidUpdate() {
    if (
      this.props.user &&
      this.props.user.tempToken &&
      !this.props.user.needExchange
    ) {
      console.log("this.props.user.tempToken -------------");
      console.log(this.props.user.tempToken);

      this.props.setUserAccessToken(this.props.user.tempToken);
      localStorage.setItem(
        LOCAL_STORAGE_KEY_ACCESS_TOKEN,
        this.props.user.tempToken
      );
      this.setWorkspace();
      this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
      this.checkUrlParamThirdParty();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <OrganizationHeader />
                        {this.props.user && this.props.user.needExchange ? (
                          <div></div>
                        ) : (
                          <div>
                            <div className="p-2 mt-5">
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={this.handleSubmit}
                              >
                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="useremail">{`${this.props.t(
                                    "Email"
                                  )} (${this.props.t("optional")})`}</Label>
                                  <AvField
                                    name="email"
                                    value={this.state.email}
                                    type="email"
                                    className="form-control"
                                    id="useremail"
                                  />
                                </div>

                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="username">
                                    {this.state.isThirdPartyUrl
                                      ? this.props.t("Username")
                                      : this.props.t("Email")}
                                  </Label>
                                  <AvField
                                    name="username"
                                    value={this.state.username}
                                    type="text"
                                    className="form-control bootstrap-default"
                                    id="username"
                                    errorMessage={this.props.t(
                                      "This field cannot be blank"
                                    )}
                                    validate={{
                                      required: true,
                                    }}
                                    placeholder=""
                                  />
                                </div>

                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">
                                    {this.props.t("Password")}
                                  </Label>
                                  <Password
                                    name="password"
                                    id="userpassword"
                                    placeholder=""
                                    errorMessage={this.props.t(
                                      "This field cannot be blank"
                                    )}
                                    validate={{
                                      required: true,
                                    }}
                                  />
                                </div>

                                <div>
                                  <Input 
                                    type="checkbox" 
                                    checked={this.state.regConsent} 
                                    onChange={() => this.setState({regConsent: !this.state.regConsent})} 
                                    required
                                    className="me-2" 
                                    />
                                    
                                      {/* {this.props.t("I agree to")} {" "}
                                      <a href={`user_policy`} target="_blank">{this.props.t("User policy")}</a> 
                                      {" " + "and" + " "}
                                      <a href={`data_privacy`} target="_blank">{this.props.t("Data privacy")}</a> */}
                                      { localStorage.getItem("i18nextLng") === "de" ? 
                                          <Label>
                                            {"Ich stimme den "}
                                            <a href={`user_policy`} target="_blank">{"Nutzungsbedingungen"}</a> 
                                            {" " + "und der" + " "}
                                            <a href={`data_privacy`} target="_blank">{"Datenschutzerklärung"}</a>
                                            {" zu."}
                                            </Label>
                                        : 
                                        <Label>
                                          {this.props.t("I agree to")} {" "}
                                          <a href={`user_policy`} target="_blank">{this.props.t("User policy")}</a> 
                                          {" " + this.props.t("and") + " "}
                                          <a href={`data_privacy`} target="_blank">{this.props.t("Data privacy")}</a>
                                          </Label>
                                        
                                        }
                                    
                                </div>

                                <div className="mt-4 text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                    disabled={!this.state.regConsent}
                                    tabIndex={0}
                                  >
                                    {this.props.t("Register")}
                                  </Button>
                                </div>

                                {this.state.isThirdPartyUrl && (
                                  <div className="mt-4 text-center">
                                    <Link
                                      to="/third-party/signin"
                                      className="text-muted"
                                    >
                                      <span>{`${this.props.t(
                                        "Already a member"
                                      )}?`}</span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          marginLeft: 9,
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {this.props.t("Log in")}.
                                      </span>
                                    </Link>
                                  </div>
                                )}
                              </AvForm>
                            </div>

                            <div className="mt-5 text-center">
                              <p>
                                {`© 2024 SMART INTEGRITY PLATFORM. ${this.props.t('All rights reserved.')}`}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    token: state.Login.token,
    user: state.Login.user,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      registerUser,
      setUserAccessToken,
      changeWorkspace,
      errorNotification,
      registerUserSuccessful,
      successNotification,
    })(Register)
  )
);
