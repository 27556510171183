import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { withNamespaces } from "react-i18next";

const styles = StyleSheet.create({
    section: {
        flexGrow: 1,
        textAlign: "center",
        position: "relative",
        width: "100%",
        height: "100%"
    },
    titleContainer: {
        marginTop: 20,
        marginBottom: 50,
        padding: '0px 0px 20px 0px'
    },
    titleText: {
        fontWeight: "600",
        fontSize: '16px'
    },
    infoContainer: {
        margin: "0px 60px 0px 60px",
        fontSize: '16px',
        lineHeight: 1.8,
        textAlign: 'left'
    },
    infoRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: '10px 0',
        fontSize: '14'
    },
    infoItemTitle: {
        color: '#1a73e8',
        fontWeight: 600
    },
    infoItemValue: {
        fontWeight: 500
    }
});

const FirstPageContent = (props) => {
    const { 
        t, 
        name, 
        downloadedAt,
        financialEndYear,
        submissionDate,
        responsiblePerson,
        assignedAnalyst,
        supplierType
    } = props;

    const title = supplierType === 'own' ? 'Questionnaire for the report according to Section 10 Paragraph 2 LkSG (Supply Chain Due Diligence Act)' : 'Third Party Report'

    return (
        <View style={styles.section}>
            <View style={styles.titleContainer}>
                <Text style={styles.titleText}>
                    {t(title)}
                </Text>
            </View>

            <View style={styles.infoContainer}>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Company Name')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {name}
                    </Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Financial End Year')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {(financialEndYear || '').split('/')[2]}
                    </Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Responsible Person')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {responsiblePerson.name}
                    </Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Assigned Analyst')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {assignedAnalyst?.name}
                    </Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Submission Date')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {submissionDate}
                    </Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={styles.infoItemTitle}>
                        {t('Download Date')}
                    </Text>
                    <Text style={styles.infoItemValue}>
                        {downloadedAt}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default withNamespaces()(FirstPageContent);
