import { memo } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmSelectionModal = ({
  isOpen,
  toggle,
  onConfirm,
  selectedUser,
  t,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Selection</ModalHeader>
      <ModalBody>
        {t("Are you sure you want to select") +
          " " +
          selectedUser?.label +
          " " +
          t("for this module?") +
          t(
            "This action will link the user from other modules to the current one."
          )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          {t("Confirm")}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(withRouter(memo(ConfirmSelectionModal)));
