import { useState, useImperativeHandle, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";

const Tabs = ({
    t,
    supplierType,
    actionsRef,
    onActiveTabChanged
}) => {
    const [tabs, setTabs] = useState([
        {
            id: 1,
            name: "company-details",
            title: "Company Details",
            completed: false,
            isCurrent: true,
        },
        {
            id: 2,
            name: "location-and-products",
            title: "Location and Products",
            completed: false,
            isCurrent: false,
        },
        {
            id: 3,
            name: "contact-person",
            title: supplierType === 'own' ? 'Responsible Person' : "Contact Person",
            completed: false,
            isCurrent: false,
        },
        {
            id: 4,
            name: "finalize",
            title: "Finalize",
            completed: false,
            isCurrent: false,
        },
    ]);

    const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

    useEffect(() => {
        onActiveTabChanged && onActiveTabChanged(tabs.find((tab) => tab.isCurrent))
    }, [ tabs ]);

    useImperativeHandle(actionsRef, () => {
        return {
            goToNext: () => {
                setTabs((currentState) => {
                    const newList = [...currentState];
                    const currentTabIndex = newList.findIndex((tab) => tab.isCurrent);

                    return newList.map((tab, index) => {
                        return {
                            ...tab,
                            completed: index === currentTabIndex ? true : tab.completed,
                            isCurrent: index === (currentTabIndex + 1) ? true : false
                        }
                    });
                })
            },
            goToPrev: () => {
                setTabs((currentState) => {
                    const newList = [...currentState];
                    const currentTabIndex = newList.findIndex((tab) => tab.isCurrent);

                    return newList.map((tab, index) => {
                        return {
                            ...tab,
                            isCurrent: index === (currentTabIndex - 1) ? true : false
                        }
                    });
                })
            },
            goToSpecificTab: (tabId) => {
                setTabs((currentState) => {
                    const newList = [...currentState];
                    const foundTabIndex = newList.findIndex((tab) => tab.id === tabId);

                    return newList.map((tab, index) => {
                        return {
                            ...tab,
                            isCurrent: index === foundTabIndex ? true : false
                        }
                    });
                })
            }
        }
    });

    return (
        <div className="bg-white wizard-steps-container mb-4">
            <div className="twitter-bs-wizard">
                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                    {tabs.map((tab, index) => {
                        return (
                            <NavItem
                                key={index}
                                className={tab.completed ? "completed" : ""}
                            >
                                <NavLink
                                    className={(index <= currentActiveTabIndex ? "active " : "") + (tab.isCurrent ? "current" : "")}
                                >
                                    <span className="step-number">
                                        {tab.completed ? (
                                            <i className="fa fa-check"></i>
                                        ) : (
                                            index + 1
                                        )}
                                    </span>

                                    <span className="step-title">{t(tab.title)}</span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
};

export default withNamespaces()(
    Tabs
)