import axios from 'axios'
import { API_BASE_URL } from 'src/common/constants'

export default class EntityManagementService {
    static instance

    static getInstance() {
        if (!EntityManagementService.instance) {
            EntityManagementService.instance = new EntityManagementService()
        }
        return EntityManagementService.instance
    }

    async fetchEntitesList(params = {}) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/legal/list`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }


    async fetchInstituteList(entityId) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/institute/legal_entity/${entityId}/list`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchDivisionList(divisionId) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/division/institute/${divisionId}/list`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

}
