import axios from 'axios';
import {
    API_BASE_URL
} from "src/modules/3rd-party-management/constants";

export default class FinancialYearSettingsService {
    static instance;

    static getInstance() {
        if (!FinancialYearSettingsService.instance) {
            FinancialYearSettingsService.instance = new FinancialYearSettingsService();
        }
        return FinancialYearSettingsService.instance;
    }

    async fetchSettings(){
        const response = await axios.get(`${API_BASE_URL}/organization/financial_detail`, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response.data
    }

    async saveSettings(payload){
        const response = await axios.post(`${API_BASE_URL}/organization/edit_financial_setting`, payload, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response
    }
}
