import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";

const DeletionUserModal = function (props) {
  const { t, isOpen, onConfirm, close, isLoading } = props;
  return (
    <>
      <Modal isOpen={isOpen} backdrop="static">
        {/* header */}
        <ModalHeader toggle={close}>{t("Delete User")}</ModalHeader>

        {/* body */}
        <ModalBody>
          <AvForm className="needs-validation">
            <Row>
              <Label>{t("Are you sure?")}</Label>
            </Row>

            <ModalFooter>
              <Button
                color="danger"
                className="waves-effect waves-light"
                type="submit"
                disabled={isLoading}
                onClick={onConfirm}
              >
                {isLoading ? <Spinner />: t("Delete").toUpperCase()}
              </Button>
              <Button
                color="secondary"
                className="waves-effect waves-light"
                type="button"
                onClick={() => {
                  close();
                }}
              >
                {t("Cancel").toUpperCase()}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withNamespaces()(DeletionUserModal);
