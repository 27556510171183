import React from "react";
import { withNamespaces } from "react-i18next";
import {
  CardImg,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ClockIcon from "../../assets/icons/clockIcon.png";

const PlatformUpdateModal = (props) => {
  const { selectedUpdate: update, setSelectedUpdate } = props;
  if (!update) return null;
  return (
    <Modal
      isOpen={!!update}
      toggle={() => setSelectedUpdate(undefined)}
      size="lg"
      centered
    >
      <ModalHeader toggle={() => setSelectedUpdate(undefined)}>
      <span dangerouslySetInnerHTML={{ __html: update.title }} />
      </ModalHeader>
      <CardImg src={update.image} className="card-img" alt={update.title} />
      <ModalBody className="p-0">
        <div className="px-3 pt-2">
          <span dangerouslySetInnerHTML={{ __html: update.body }} />
        </div>
      </ModalBody>

      <ModalFooter className="bg-transparent d-flex flex-row justify-content-between align-items-center border-top">
        <div className="text-muted">
          <span className="pe-2">
            <img src={ClockIcon} alt="icon" width={24} height={24} />
          </span>
          <span>{new Date(Date.now()).toLocaleDateString()}</span>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(PlatformUpdateModal);
