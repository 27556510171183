import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useState, memo, useEffect } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import OverviewHeader from "../../../../components/overview-header";

import { Col, Row } from "reactstrap";

import SupplierReportsList from "./components/list-reports";

import SupplierReportDetails from "./components/report-details";

import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

const Reports = ({
    t,
    supplierId,
    className
}) => {
    const [ reportToViewDetails, setReportToViewDetails ] = useState(null);
    const [ supplierInfos, setSupplierInfos ] = useState(null);

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		},
	});

    useEffect(() => {
        if(handleFetchSupplierBasicInformation.data){
            setSupplierInfos(handleFetchSupplierBasicInformation.data);
        }
    }, [handleFetchSupplierBasicInformation.data]);

    return (
        <Row className="p-4">
            <Col sm="12" className="mb-4">
                <OverviewHeader supplierId={ supplierId } />
            </Col>

            <Col sm="12">
                <SupplierReportsList 
                    className={`${reportToViewDetails && 'd-none'}`} 
                    onReportSelectedToView={(report) => setReportToViewDetails(report)} 
                    supplierId={ supplierId } 
                    supplierType={ supplierInfos?.isOwnCompany ? 'own' : 'creditor' }
                    supplierInfos={ supplierInfos }
                />

                {
                    reportToViewDetails && supplierInfos && (
                        <SupplierReportDetails 
                            supplierInfos={ supplierInfos } 
                            reportId={ reportToViewDetails.report_case.case_id }
                        />
                    )
                }
            </Col>
        </Row>
    );
};

export default withNamespaces()(
    withRouter(memo(Reports))
); 