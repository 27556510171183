import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
    MultiTabQuestionnaire,
    useAutoSave
} from '@smartintegrity/questionnaire';
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

import {
	confirmPromptMakeReadyToShow,
	confirmPromptHide,
	confirmPromptUpdateAlertProps
} from 'src/store/actions';

const FillQuestionnaire = ({
    t,
    token, 
    onBackButtonClicked,
    onValidSubmit,
    supplierId,
    questionnaireType,
    questions,
    prevAnswers,
    reportCaseId,

	exitConfirmPromptOptions, 
	makeConfirmPromptReadyToShow, 
	hideConfirmPrompt
}) => {
    const {
        control
    } = useAutoSave({
        enable: true,
        onlyChangedItems: false,
        stepInterval: 7,
        saveRequestOptions: {
            headers: {
                Authorization: `Bearer ${token}`
            },
            payload: (payload) => {
                return {
                    questionAnswers: [
                        ...payload
                    ],
                    questionnaireType: questionnaireType === 'short' ? 'short_tpdd_bafa' : 'tpdd_bafa'
                }
            },
            requestUrl: () => {
                return `${API_BASE_URL}/report/${ reportCaseId }/edit`
            }
        }
    });

    const {
        autoSaveState: {
            unsavedStepsCount,
            latestRequestState
        },
        autoSaveSaveCurrentState
    } = control;

    const [ questionnaireCompleted, setQuestionnaireCompleted ] = useState(false);

    const settings = useMemo(() => {
        let submitButtonSettings = {
            enable: false,
            title: t('Next')
        }
        
        if(questionnaireCompleted){
            if(latestRequestState === 'in_process'){
                submitButtonSettings.title = (
                    <>
                        <Spinner 
                            className="me-2" 
                            animation="border" 
                            variant="info" 
                            size="sm"
                        />
                        {t('Please wait')}...
                    </>
                )
            }
            else if(latestRequestState !== 'failed'){
                submitButtonSettings = {
                    title: t('Next'),
                    enable: true
                }
            }
        }

        return {
            tabSettings: {
                showNumber: true,
                showProgressBar: true,
            }, 
            buttonsSettings: {
                submit: {
                    display: true,
                    ...submitButtonSettings
                },
                back: {
                    display: true,
                    enable: false,
                    title: t('Back'),
                    onClick: () => {
                        onBackButtonClicked && onBackButtonClicked();
                    }
                }
            },
            fileUploaderOptions: {
                deleteRequestOptions: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    payload: {},
                    requestUrl: ({
                        questionId,
                        file
                    }) => {
                        return `${API_BASE_URL}/attachment/${file.id}/delete`
                    }
                },
                uploadRequestOptions: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    payload: {},
                    requestUrl: ({
                        questionId
                    }) => {
                        return `${API_BASE_URL}/report.uploadAttachment`
                    }
                }
            },
            questions: {
                showNumber: true
            }
        }
    }, [ 
        token, 
        questionnaireCompleted, 
        t,
        latestRequestState
    ]);

    useEffect(() => {
        if(latestRequestState === 'in_process') return;

        if(exitConfirmPromptOptions.show){
            if(unsavedStepsCount > 0){
                autoSaveSaveCurrentState();
            }

            if(latestRequestState === 'succeed'){
                exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
                hideConfirmPrompt();
                toast(t('Questionnaire saved successfully.'), {
                    type: 'success',
                });
            }
            else if(latestRequestState === 'failed'){
                hideConfirmPrompt();
                toast(t('An error occurred while saving questionnaire!'), {
                    type: 'error',
                });
            }

            return;
        }

        if(unsavedStepsCount > 0){
            if(!exitConfirmPromptOptions.readyToShow){
                makeConfirmPromptReadyToShow({
                    title       : (
                        <div>
                            <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                            {t('Saving the current state')}...
                        </div>
                    ),
                    message     : t("please don't close or refresh the page"),
                    alertProps  : {
                        ...exitConfirmPromptOptions.alertProps,
                        confirmBtn	:	{
                            ...exitConfirmPromptOptions.alertProps.confirmBtn,
                            enabled	:	false
                        },
                        cancelBtn	:	{
                            ...exitConfirmPromptOptions.alertProps.cancelBtn,
                            enabled	:	false
                        }
                    }
                });
            }
        }
        else{
            if(exitConfirmPromptOptions.readyToShow && 
                latestRequestState !== 'ready_to_send'){
                    hideConfirmPrompt();
            }
        }
    }, [
        unsavedStepsCount,
        latestRequestState,
        exitConfirmPromptOptions.show,
        exitConfirmPromptOptions.readyToShow,
    ]);

    return (
        <MultiTabQuestionnaire 
            className='tpdd'
            questions={questions}
            defaultValues={prevAnswers}
            settings={settings}
            onQuestionnaireCompleteStatusChanged={(_currentPayload, newStatus) => {
                setQuestionnaireCompleted(newStatus)
            }}
            onSubmit={(payload) => {
                onValidSubmit && onValidSubmit({
                    reportCaseId: reportCaseId,
                    questions: questions,
                    answers: payload
                })
            }}
            {...control}
        />
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, ExitConfirmPrompt } = state;
	
	return {
		token,
		user,
		Organization,
		App,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            makeConfirmPromptReadyToShow  		: 	(payload) => confirmPromptMakeReadyToShow(payload),
            hideConfirmPrompt             		: 	() => confirmPromptHide(),
            updateConfirmPromptAlertProps 		: 	(payload) => confirmPromptUpdateAlertProps(payload)
        })(
            FillQuestionnaire
        )
    )
);