import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap';
import Breadcrumbs from 'src/components/Common/Breadcrumb';
import { 
	isSettingEnabledForActiveModule
} from 'src/helpers/module_helper';
import reportAIcon from "src/assets/images/report_anonymously.svg";
import reportCIcon from "src/assets/images/report_confidently.svg";
import arrangeMeetingIcon from "src/assets/images/arrange_meeting.svg";
import voiceReportIcon from "src/assets/images/voice.svg";

const breadcrumbItems = [
    { title: 'SIP', link: '/' },
    { title: 'Home', link: '#' },
];

const  WBHome = ({
    t, 
    selectedLanguage,
    Organization
}) => {
    const isAnonymously = isSettingEnabledForActiveModule('isAnonymously');

    const isConfidentially = isSettingEnabledForActiveModule('isConfidentially');

    const isMeeting = isSettingEnabledForActiveModule('isMeeting');
    
    const isVoice = isSettingEnabledForActiveModule('isVoice');

    return (
        <div className='page-content'>
			<Container fluid>
                { Organization.translations.filter((t) => t.module === 'wb')
                    .map((item, index) => {
                        return (
                            <div className={item.lang === selectedLanguage ? '' : 'd-none'} key={index}>
                                <Breadcrumbs key={index} title={item.title} breadcrumbItems={breadcrumbItems} />
                            </div>
                        );
                    })
                }

                <Row>
                    {isAnonymously && (
                        <Col
                            className={
                                isVoice ? 'col col-12 col-md-12 col-lg-3' : `col col-12 col-md-12 col-lg-4`
                            }
                        >
                            <Card>
                                <CardBody
                                    style={{
                                        minHeight: '180px',
                                    }}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-1 overflow-hidden'>
                                            <h4 className='mb-2'>{t('Report anonymously')}</h4>
                                            <p className='font-size-14 mb-2'>
                                                {t(
                                                    'You do not disclose your identity. You communicate exclusively via the Smart Integrity Platform with those responsible from the company.',
                                                )}
                                            </p>
                                        </div>
                                        <div className='text-primary ps-3'>
                                            <img src={reportAIcon} alt='' />
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className='border-top py-3'>
                                    <div className='text-truncate'>
                                        <Link to='/employee/report-anonymously'>
                                            <Button color='btn btn-primary'>{t('Send a report')}</Button>
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {isConfidentially && (
                        <Col
                            className={
                                isVoice ? 'col col-12 col-md-12 col-lg-3' : `col col-12 col-md-12 col-lg-4`
                            }
                        >
                            <Card>
                                <CardBody
                                    style={{
                                        minHeight: '180px',
                                    }}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-1 overflow-hidden'>
                                            <h4 className='mb-2'>{t('Report confidentially')}</h4>
                                            <p className='font-size-14 mb-2'>
                                                {t(
                                                    'You disclose your identity confidentially to the responsible persons and discuss your case discreetly with the responsible persons.',
                                                )}
                                            </p>
                                        </div>
                                        <div className='text-primary ps-3'>
                                            <img src={reportCIcon} alt='' />
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className='border-top py-3'>
                                    <div className='text-truncate'>
                                        <Link to='/employee/report-confidentially'>
                                            <Button color='btn btn-primary'>{t('Send a report')}</Button>
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {isMeeting && (
                        <Col
                            className={
                                isVoice ? 'col col-12 col-md-12 col-lg-3' : `col col-12 col-md-12 col-lg-4`
                            }
                        >
                            <Card>
                                <CardBody
                                    style={{
                                        minHeight: '180px',
                                    }}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-1 overflow-hidden'>
                                            <h4 className='mb-2'>{t('Arrange a meeting')}</h4>
                                            <p className='font-size-14 mb-2'>
                                                {t('You disclose your identity in order to arrange a confidential meeting with the responsible persons.')}
                                            </p>
                                        </div>
                                        <div className='text-primary ms-1 ps-3'>
                                            <img src={arrangeMeetingIcon} alt='' />
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className='border-top py-3'>
                                    <div className='text-truncate'>
                                        <Link to='arrange-meeting'>
                                            <Button color='btn btn-primary'>{t('Arrange a meeting')}</Button>
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {isVoice && (
                        <Col className='col col-12 col-md-12 col-lg-3'>
                            <Card>
                                <CardBody
                                    style={{
                                        minHeight: '180px',
                                    }}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-1 overflow-hidden'>
                                            <h4 className='mb-2'>{t('Voice report')}</h4>
                                            <p className='font-size-14 mb-2'>{t('Send a confidential or anonymous report with a voice message.')}</p>
                                        </div>
                                        <div className='text-primary ps-3'>
                                            <img src={voiceReportIcon} alt='' />
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className='border-top py-3'>
                                    <div className='text-truncate'>
                                        <Link to='voice-report'>
                                            <Button color='btn btn-primary'>{t('Send a voice report')}</Button>
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
                {Organization.translations
                            .filter((t) => t.module === 'wb')
                            .map((item, index) => {
                                return (
                                    <Row key={index} className={item.lang === selectedLanguage ? '' : 'd-none'}>
                                        <Col lg='12'>
                                            <Card>
                                                <CardBody>{<div className='introText' dangerouslySetInnerHTML={{ __html: item.intro }} />}</CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                );
                            })}
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
})(WBHome)));