import {
    TRANSLATION_KEY_SIGN_IN_INFO as KEY_SIGN_IN_INFO,
    TRANSLATION_KEY_REPORT_INFO as KEY_REPORT_INFO,
    TRANSLATION_KEY_USER_INFO as KEY_USER_INFO,
    TRANSLATION_KEY_CHATBOX_INFO as KEY_CHATBOX_INFO
} from '../../common/constants/TranslationKeys';
import { ROLE_ANALYST_ADMIN, ROLE_ANALYST } from './Roles';

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_EMAIL = 'email';
export const FIELD_TYPE_PHONE = 'phone';
export const FIELD_TYPE_PASSWORD = 'password';
export const FIELD_TYPE_SELECT = 'select';
export const FIELD_TYPE_MULTI_SELECT = 'multiSelect';
export const FIELD_TYPE_MULTI_CHECKBOX = 'multiCheckBox';
export const FIELD_TYPE_CHECKBOX = 'checkBox';
export const FIELD_TYPE_MULTI_RADIO = 'multiRadio';
export const FIELD_TYPE_TEXT_AREA = 'textArea';
export const FIELD_TYPE_REPORT_UPLOAD_FILE = 'reportUploadFile';
export const FIELD_TYPE_DATE_INPUT = 'dateInput';
export const FIELD_TYPE_RICH_TEXT = 'richText';

export const ALLOW_FILE_EXT = ['txt', 'jpg', 'jpeg', 'png'];
export const NOT_ALLOW_FILE_EXT = ['flv'];

export const FIELD_CHATBOX_IDENTIFY = {
    labelKey: 'Identify in the chat as:',
    name: 'identity_chat',
    type: FIELD_TYPE_MULTI_RADIO,
    required: true,
    options: [
        {
            value: 'ANONYMOUS',
            titleKey: 'ANONYMOUSLY',
        },
        {
            value: 'CONFIDENTIAL',
            titleKey: 'CONFIDENTIALLY'
        }
    ]
};

export const FIELD_CHATBOX_MESSAGE = {
    labelKey: '',
    name: 'message',
    type: FIELD_TYPE_TEXT,
    required: false
};

export const FIELD_CHATBOX_NAME = {
    labelKey: 'Alias',
    name: 'name',
    type: FIELD_TYPE_TEXT,
    required: true,
    disabled: true,
    readOnly: true
};

export const FIELD_CHATBOX_NAME_ANONYMOUS = {
    labelKey: 'Alias',
    name: 'name',
    type: FIELD_TYPE_TEXT,
};

export const FIELD_UPLOAD_INTRO_COMPANY = {
    labelKey: '',
    name: 'intro',
    type: FIELD_TYPE_RICH_TEXT,
    required: true
};

export const FIELD_UPLOAD_NAME_COMPANY = {
    labelKey: '',
    name: 'name',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_USER_FIRST_NAME = {
    labelKey: 'First name',
    name: 'first_name',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_USER_LAST_NAME = {
    labelKey: 'Last name',
    name: 'last_name',
    type: FIELD_TYPE_TEXT,
    required: false
};

export const FIELD_USER_EMAIL = {
    labelKey: 'Email',
    name: 'email',
    type: FIELD_TYPE_EMAIL,
    required: true
};

export const FIELD_USER_EMAIL_READONLY = {
    labelKey: 'Email',
    name: 'email',
    type: FIELD_TYPE_EMAIL,
    required: true,
    readOnly: true
};

export const FIELD_USER_POSITION = {
    labelKey: 'Company Position',
    name: 'position',
    type: FIELD_TYPE_TEXT,
    required: false,
};

export const FIELD_USER_PHONE_NUMBER = {
    labelKey: 'Phone Number',
    name: 'phone_number',
    type: FIELD_TYPE_PHONE,
    required: false,
};

export const FIELD_USER_ROLE = {
    labelKey: 'Assigned Role',
    name: 'roles',
    type: FIELD_TYPE_SELECT,
    options: [
        {
            value: ROLE_ANALYST_ADMIN,
            titleKey: 'Analyst Admin'
        },
        {
            value: ROLE_ANALYST,
            titleKey: 'Analyst'
        }
    ],
    required: true
};

export const USER_FIELD = [
    FIELD_USER_FIRST_NAME,
    FIELD_USER_LAST_NAME,
    FIELD_USER_EMAIL,
    FIELD_USER_POSITION
];

export const FIELD_USERNAME = {
    labelKey: 'Username',
    name: 'username',
    type: FIELD_TYPE_TEXT,
    required: true,
    usernameComplex: true
};

export const FIELD_EMAIL = {
    labelKey: 'Email address',
    name: 'email',
    type: FIELD_TYPE_EMAIL,
    required: true
};

export const FIELD_OPTIONAL_EMAIL = {
    labelKey: 'Email address',
    name: 'email',
    type: FIELD_TYPE_EMAIL
};

export const FIELD_OPTIONAL_PHONE_NUMBER = {
    labelKey: 'Phone number',
    name: 'phone',
    type: FIELD_TYPE_PHONE
};

export const FIELD_PHONE_NUMBER = {
    labelKey: 'Phone number',
    name: 'phone',
    type: FIELD_TYPE_PHONE,
    required: true
};

export const FIELD_PASSWORD = {
    labelKey: 'Password',
    name: 'password',
    type: FIELD_TYPE_PASSWORD,
    required: true,
    minLength: 6
};

export const FIELD_COMPLEX_PASSWORD = {
    labelKey: 'Password',
    name: 'password',
    type: FIELD_TYPE_PASSWORD,
    required: true,
    passwordComplex: true
};

export const FIELD_COMPLEX_OLD_PASSWORD = {
    labelKey: 'Old password',
    name: 'password',
    type: FIELD_TYPE_PASSWORD,
    required: true
};

export const FIELD_COMPLEX_NEW_PASSWORD = {
    labelKey:  'New password',
    name: 'newPassword',
    type: FIELD_TYPE_PASSWORD,
    required: true,
    passwordComplex: true
};

export const FIELD_COMPLEX_REPEAT_PASSWORD = {
    labelKey: 'Repeat password',
    name: 'repeatPassword',
    type: FIELD_TYPE_PASSWORD,
    required: true,
    passwordComplex: true
};

export const FIELD_REPEAT_PASSWORD = {
    labelKey: 'Repeat password',
    name: 'repeatPassword',
    type: FIELD_TYPE_PASSWORD,
    required: true,
    minLength: 6
};

export const FIELD_REPORT_INCIDENT_DESCRIPTION = {
    labelKey: 'Brief Description of Incident',
    name: 'description',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_REPORT_CATEGORIES = {
    labelKey: 'In your opinion, the facts of the case fall into the following category:',
    name: 'category',
    type: FIELD_TYPE_MULTI_CHECKBOX,
    required: true,
    options: [
        {
            value: '1',
            titleKey: KEY_REPORT_INFO + 'ANIMAL_HEALTH'
        },
        {
            value: '2',
            titleKey: KEY_REPORT_INFO + 'ASSAULT'
        },
        {
            value: '3',
            titleKey: KEY_REPORT_INFO + 'BRIBERY'
        },
        {
            value: '4',
            titleKey: KEY_REPORT_INFO + 'BULLYING'
        },
        {
            value: '5',
            titleKey: KEY_REPORT_INFO + 'INTEREST_CONFLICT'
        },
        {
            value: '6',
            titleKey: KEY_REPORT_INFO + 'CONSUMER_PROTECTION'
        },
        {
            value: '7',
            titleKey: KEY_REPORT_INFO + 'DISCRIMINATION'
        },
        {
            value: '8',
            titleKey: KEY_REPORT_INFO + 'ENVIRONMENT_PROTECTION'
        },
        {
            value: '9',
            titleKey: KEY_REPORT_INFO + 'FOOD_SAFETY'
        },
        {
            value: '10',
            titleKey: KEY_REPORT_INFO + 'FRAUD'
        },
        {
            value: '11',
            titleKey: KEY_REPORT_INFO + 'HARASSMENT'
        },
        {
            value: '12',
            titleKey: KEY_REPORT_INFO + 'HUMAN_RIGHTS'
        },
        {
            value: '13',
            titleKey: KEY_REPORT_INFO + 'INTERNAL_GUIDELINE'
        },
        {
            value: '14',
            titleKey: KEY_REPORT_INFO + 'FINANCIAL_DATA'
        },
        {
            value: '15',
            titleKey: KEY_REPORT_INFO + 'MONEY_LAUNDERING'
        },
        {
            value: '16',
            titleKey: KEY_REPORT_INFO + 'SAFETY_AND_HEALTH'
        },
        {
            value: '17',
            titleKey: KEY_REPORT_INFO + 'PROCUREMENT_AND_CONTRACT'
        },
        {
            value: '18',
            titleKey: KEY_REPORT_INFO + 'SAFETY_PRODUCT'
        },
        {
            value: '19',
            titleKey: KEY_REPORT_INFO + 'PRIVACY_PROTECTION'
        },
        {
            value: '20',
            titleKey: KEY_REPORT_INFO + 'PUBLIC_HEALTH'
        },
        {
            value: '21',
            titleKey: KEY_REPORT_INFO + 'RADIATION_PROTECTION'
        },
        {
            value: '22',
            titleKey: KEY_REPORT_INFO + 'ROAD_SAFETY'
        },
        {
            value: '23',
            titleKey: KEY_REPORT_INFO + 'THEFT'
        },
        {
            value: '24',
            titleKey: KEY_REPORT_INFO + 'VIOLATIONS_INTEGRITY'
        },
        {
            value: '25',
            titleKey: KEY_REPORT_INFO + 'OTHER'
        }
    ]
};

export const FIELD_REPORT_LEGAL_ENTITY = {
    labelKey: 'Which legal entity is affected?',
    name: 'legal_entity',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question1'
};

export const FIELD_REPORT_AFFECTED_LOCATION = {
    labelKey: 'Which country is affected?',
    name: 'affected_location',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question2'
};

export const FIELD_REPORT_WHEN_HAPPENED = {
    labelKey: 'When did it happen?',
    name: 'when_happened',
    type: FIELD_TYPE_MULTI_RADIO,
    options: [
        {
            value: '1',
            titleKey: KEY_REPORT_INFO + 'APPROXIMATE_DATE',
            childType: FIELD_TYPE_DATE_INPUT
        },
        {
            value: '2',
            titleKey: KEY_REPORT_INFO + 'SPECIFY_DATE',
            childType: FIELD_TYPE_DATE_INPUT
        },
        {
            value: '3',
            titleKey: KEY_REPORT_INFO + 'DO_NOT_REMEMBER'
        }
    ],
    questionId: 'question3'
};

export const FIELD_REPORT_WHO_INVOLVED = {
    labelKey: KEY_REPORT_INFO + 'WHO_INVOLVED',
    name: 'who_involved',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question4'
};


export const FIELD_REPORT_WHAT_HAPPENED = {
    labelKey: KEY_REPORT_INFO + 'WHAT_HAPPENED',
    name: 'what_happened',
    type: FIELD_TYPE_TEXT_AREA,
    required: true,
    questionId: 'question5'
};

export const FIELD_REPORT_WHOM_REPORTED_TO = {
    labelKey: KEY_REPORT_INFO + 'WHOM_REPORTED',
    name: 'whom_reported_to',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question6'
};

export const FIELD_REPORT_HOW_AWARE_FACTS = {
    labelKey: KEY_REPORT_INFO + 'HOW_AWARE_FACTS',
    name: 'how_aware_facts',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question7'
};

export const FIELD_REPORT_HOW_PROVE_FACTS = {
    labelKey: KEY_REPORT_INFO + 'HOW_PROVE_FACTS',
    name: 'how_prove_facts',
    type: FIELD_TYPE_TEXT,
    required: true,
    questionId: 'question8'
};

export const FIELD_REPORT_ATTACHMENTS = {
    labelKey: 'Attachments',
    name: 'files',
    type: FIELD_TYPE_REPORT_UPLOAD_FILE,
    descriptionKey: KEY_REPORT_INFO + 'FILE_TRANSFER_INFO'
};

export const FIELD_MEETING_AVAILABLE_DATE_FROM = {
    labelKey: 'I am available for a callback as of the following date',
    name: 'date_available_time_from',
    type: FIELD_TYPE_DATE_INPUT,
    required: true
};

export const FIELD_MEETING_AVAILABLE_DATE_TO = {
    labelKey: 'I am available for a callback until the following date',
    name: 'date_available_time_to',
    type: FIELD_TYPE_DATE_INPUT,
    required: true
};

export const REPORT_FIELDS = [
    FIELD_REPORT_INCIDENT_DESCRIPTION,
    FIELD_REPORT_CATEGORIES,
    FIELD_REPORT_LEGAL_ENTITY,
    FIELD_REPORT_AFFECTED_LOCATION,
    FIELD_REPORT_WHEN_HAPPENED,
    FIELD_REPORT_WHO_INVOLVED,
    FIELD_REPORT_WHAT_HAPPENED,
    FIELD_REPORT_WHOM_REPORTED_TO,
    FIELD_REPORT_HOW_AWARE_FACTS,
    FIELD_REPORT_HOW_PROVE_FACTS,
    FIELD_REPORT_ATTACHMENTS
];

export const FIELD_REPORT_USER_RELATION = {
    labelKey: 'What is your relation to the company?',
    name: 'relation_company',
    type: FIELD_TYPE_SELECT,
    options: [
        {
            value: '1',
            titleKey:  'Employee'
        },
        {
            value: '2',
            titleKey:  'External'
        },
    ],
    required: true
};

export const FIELD_REPORT_USER_TITLE = {
    labelKey: 'Title',
    name: 'salutation',
    type: FIELD_TYPE_SELECT,
    options: [
        {
            value: '1',
            titleKey: 'Mr.'
        },
        {
            value: '2',
            titleKey: 'Mrs.'
        },
    ],
    required: true
};

export const FIELD_REPORT_USER_FIRST_NAME = {
    labelKey: 'First name',
    name: 'first_name',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_REPORT_USER_LAST_NAME = {
    labelKey: 'Last name',
    name: 'last_name',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_REPORT_USER_ORGANIZATION = {
    labelKey: 'Organization',
    name: 'organization',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_REPORT_USER_POSITION = {
    labelKey: 'Position',
    name: 'position',
    type: FIELD_TYPE_TEXT,
    required: true
};

export const FIELD_REPORT_AVAILABLE_TIME = {
    labelKey: 'I am available during the following times for a callback',
    name: 'phone_available_time',
    type: FIELD_TYPE_MULTI_CHECKBOX,
    options: [
        {
            value: '7_9',
            titleKey: '7am - 9am'
        },
        {
            value: '9_12',
            titleKey: '9am - 12pm'
        },
        {
            value: '12_3',
            titleKey: '12pm - 3pm'
        },
        {
            value: '3_6',
            titleKey: '3pm - 6pm'
        },
        {
            value: '6_8',
            titleKey: '6pm - 8pm'
        },
        {
            value: 'custom',
            titleKey: 'Customize',
            childType: FIELD_TYPE_TEXT
        }
    ]
};

export const FIELD_MEETING_AVAILABLE_TIME = {
    labelKey: 'I am available during the following times for a callback',
    name: 'phone_available_time',
    type: FIELD_TYPE_MULTI_CHECKBOX,
    required: true,
    options: [
        {
            value: '7_9',
            titleKey: '7am - 9am'
        },
        {
            value: '9_12',
            titleKey: '9am - 12pm'
        },
        {
            value: '12_3',
            titleKey: '12pm - 3pm'
        },
        {
            value: '3_6',
            titleKey: '3pm - 6pm'
        },
        {
            value: '6_8',
            titleKey: '6pm - 8pm'
        },
        {
            value: 'custom',
            titleKey: 'Customize',
            childType: FIELD_TYPE_TEXT
        }
    ]
};

export const CREATE_ACCOUNT_FIELDS = [
    FIELD_USERNAME,
    FIELD_COMPLEX_PASSWORD,
    FIELD_COMPLEX_REPEAT_PASSWORD
];

export const FIELD_REPORT_COMMENT = {
    labelKey: '',
    name: 'comment_content',
    type: FIELD_TYPE_RICH_TEXT,
    required: true
};

export const FIELD_REPORT_LINK_CASE = {
    labelKey: '',
    name: 'linked_report_case_id',
    type: FIELD_TYPE_SELECT
};

export const FIELD_REPORT_STATUS = {
    labelKey: '',
    name: 'status',
    type: FIELD_TYPE_SELECT,
    placeholderKey: KEY_REPORT_INFO + 'STATUS'
};

export const FIELD_REPORT_ANALYST = {
    labelKey: '',
    name: 'analyst_id',
    type: FIELD_TYPE_SELECT
};
