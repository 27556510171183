import { AvField, AvForm } from "availity-reactstrap-validation";
import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { roles, routes } from "../../../../data";
import { useMutation } from "@tanstack/react-query";
import { createNewUser } from "../../../../api/apiFunctions";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Papa from "papaparse";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import axios from "axios";

const AddNewUser = (props) => {
  const { t, token } = props;
  const activeModule = props.modules?.active;
  const history = useHistory();

  const [items, setItems] = useState([
    {
      salutation: "",
      first_name: "",
      last_name: "",
      email: "",
      position: "",
      role: "",
    },
  ]);

  const renderSubmitErrorMessage = (val) => {
    let result = "";
    switch (val) {
      case val?.email === "validation.email.already_used":
        result = t("This email has already been used.");
        break;
      case val === "insufficient_license_ROLE_ANALYST_ADMIN":
        result = t("Insufficient licenses for role Analyst Admin.");
        break;
      case val === "insufficient_license_ROLE_ANALYST":
        result = t("Insufficient licenses for role Analyst.");
        break;
      case val === "insufficient_license_ROLE_EMPLOYEE":
        result = t("Insufficient licenses for role Employee.");
        break;
      case val === "insufficient_license_ROLE_TASK_OWNER":
        result = t("Insufficient licenses for role Task Owner.");
        break;
      default:
        result = t(JSON.stringify(val));
    }
    return result;
  };

  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const payload = items.map((i) => {
        const item = {
          gender: i.salutation,
          first_name: i.first_name,
          last_name: i.last_name,
          email: i.email,
          position: i.position,
          role: i.role,
        };
        return item;
      });
      await createNewUser({
        token: token,
        payload,
        activeModule,
      });
    },
    onSuccess: () => {
      toast("User created successfully.", {
        type: "success",
      });
      history.push(routes[activeModule].listOfUsers);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const error = err?.response?.data?.message;
        Object.values(error).forEach((val) => {
          toast(renderSubmitErrorMessage(val), {
            type: "error",
          });
        });
      }
    },
  });

  const addUser = () => {
    setItems([
      ...items,
      {
        salutation: "", 
        first_name: "",
        last_name: "",
        email: "",
        position: "",
        role: "",
      },
    ]);
  };

  const deleteUser = (index) => {
    if (items.length > 1) {
      setItems(
        items.filter((item, itemIndex) => {
          return index !== itemIndex;
        })
      );
    }
  };

  const fieldValueChanged = (index, key, value) => {
    setItems(
      items.map((item, itemIndex) => {
        if (index === itemIndex) {
          let newValue = item;
          newValue[key] = value;
          return newValue;
        }
        return item;
      })
    );
  };

  const uploadCSV = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      Papa.parse(files[0], {
        complete: (results, file) => {
          const data = results.data;
          const errors = results.errors;

          if (errors && errors.length > 0) {
            toast(t("Error to parse the csv file"), {
              type: "error",
            });
            return;
          }

          if (data && data.length > 0) {
            const rolesMapping = {
              "Analyst Admin": "ROLE_ANALYST_ADMIN",
              Analyst: "ROLE_ANALYST",
              Employee: "ROLE_EMPLOYEE",
              "Task Owner": "ROLE_TASK_OWNER",
            };

            let firstNameIndex = -1;
            let lastNameIndex = -1;
            let emailIndex = -1;
            let companyPositionIndex = -1;
            let roleIndex = -1;
            let headerIndex = -1;
            const newRows = [];
            for (let i = 0; i < data.length; i++) {
              const items = data[i];
              if (items && items.length > 0 && headerIndex === -1) {
                firstNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "first_name"
                );
                lastNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "last_name"
                );
                emailIndex = items.findIndex(
                  (label) => label.toLowerCase() === "email"
                );
                companyPositionIndex = items.findIndex(
                  (label) => label.toLowerCase() === "position"
                );
                roleIndex = items.findIndex(
                  (label) => label.toLowerCase() === "role"
                );

                if (
                  firstNameIndex !== -1 &&
                  lastNameIndex !== -1 &&
                  emailIndex !== -1 &&
                  companyPositionIndex !== -1 &&
                  roleIndex !== -1
                ) {
                  headerIndex = i;
                }
              } else if (
                headerIndex > -1 &&
                items.length === data[headerIndex].length
              ) {
                const userRole = rolesMapping[items[roleIndex]] || "";
                if (!userRole) {
                  toast(t(`Invalid role: ${items[roleIndex]}`), {
                    type: "error",
                  });
                  return;
                }
                newRows.push({
                  first_name: items[firstNameIndex] || "",
                  last_name: items[lastNameIndex] || "",
                  email: items[emailIndex] || "",
                  position: items[companyPositionIndex] || "",
                  role: items[roleIndex] || "",
                  role: userRole,
                });
              }
            }

            if (headerIndex === -1) {
              toast(
                t("Invalid format, please check the template for details"),
                {
                  type: "error",
                }
              );
              return;
            }
            if (newRows.length > 0) {
              setItems(newRows);
            }
          }
        },
      });
    }
  };

  return (
    <Card>
      <CardBody>
        {isFeatureEnabledForActiveModule("new-user-upload-csv") && (
          <Row>
            <Col sm="12" className="text-end">
              <Button type="button" color="success" className="mb-2 me-2">
                <input
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0px",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    zIndex: "1",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  type="file"
                  onChange={uploadCSV}
                />
                <i className="fa fa-upload"></i>
                {` ${t("Upload CSV")}`}
              </Button>
            </Col>
          </Row>
        )}
        <AvForm
          className="needs-validation"
          onValidSubmit={handleSubmitMutation.mutate}
        >
          <Row>
            <Col sm="12">
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("Salutation")}</th>
                      <th>
                        {t("First name")} <span className="text-danger">*</span>
                      </th>
                      <th>{t("Last name")}</th>
                      <th>
                        {t("Email")} <span className="text-danger">*</span>
                      </th>
                      <th>{`${t("Company Position")} ${t("(Optional)")}`}</th>
                      <th>{t("Role")}</th>
                      <th>{t("Delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((user, index) => {
                      return (
                        <tr>
                          <td>
                            <AvField
                              type="select"
                              name={`salutation[${index}]`}
                              className="form-control"
                              id={`salutation[${index}]`}
                              value={user?.salutation}
                              onChange={(e) =>
                                fieldValueChanged(
                                  index,
                                  "salutation",
                                  e.target.value
                                )
                              }
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: t("Please select a salutation"),
                                },
                              }}
                            >
                              <option value="" disabled>
                                {t("Select Salutation")}
                              </option>
                              <option value="Mr">{t("Mr.")}</option>
                              <option value="Mrs">{t("Mrs.")}</option>
                              <option value="Ms">{t("Ms.")}</option>
                            </AvField>
                          </td>
                          <td>
                            <AvField
                              name={`first_name[${index}]`}
                              placeholder=""
                              type="text"
                              errorMessage={t("This field cannot be blank")}
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                              id={`first-name[${index}]`}
                              value={user?.first_name}
                              onChange={(e) =>
                                fieldValueChanged(
                                  index,
                                  "first_name",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <AvField
                              name={`last_name[${index}]`}
                              placeholder=""
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: false },
                              }}
                              id={`last-name[${index}]`}
                              value={user?.last_name}
                              onChange={(e) =>
                                fieldValueChanged(
                                  index,
                                  "last_name",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <AvField
                              name={`email[${index}]`}
                              placeholder=""
                              type="text"
                              errorMessage={t("This field cannot be blank")}
                              className="form-control"
                              validate={{
                                required: { value: true },
                                email: true,
                              }}
                              value={user?.email}
                              id={`email[${index}]`}
                              onChange={(e) =>
                                fieldValueChanged(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <AvField
                              name={`position[${index}]`}
                              placeholder=""
                              type="text"
                              className="form-control"
                              id={`position`}
                              value={user?.position}
                              onChange={(e) =>
                                fieldValueChanged(
                                  index,
                                  "position",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <AvField
                              type="select"
                              name={`role[${index}]`}
                              className="form-control"
                              id={`role-select[${index}]`}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: t("Please select a role"),
                                },
                              }}
                              value={user?.role}
                              onChange={(e) => {
                                fieldValueChanged(
                                  index,
                                  "role",
                                  e.target.value
                                );
                              }}
                            >
                              {
                                <>
                                  <option value="" disabled>
                                    {t("Select Role")}
                                  </option>
                                  {Object.entries(roles)
                                    .filter(([key, value]) =>
                                      value.modules.includes(activeModule)
                                    )
                                    .map(([key, value]) => (
                                      <option key={key} value={key}>
                                        {t(value.label)}
                                      </option>
                                    ))}
                                </>
                              }
                            </AvField>
                          </td>
                          <td>
                            <Button
                              type="button"
                              color="link"
                              className="waves-effect me-1 text-danger"
                              onClick={() => deleteUser(index)}
                            >
                              <i className="ri-delete-bin-fill"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Button color="secondary" type="button" onClick={addUser}>
                {t("Add User")}
              </Button>
              <Button color="primary" type="submit" style={{ float: "right" }}>
                {handleSubmitMutation.isLoading ? (
                  <Spinner />
                ) : (
                  t("Send Invite")
                )}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(AddNewUser)))
);
