import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import OverviewHeader from "../../../../components/overview-header";
import ProductList from "./components/list";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import { hasUserAccessToEditSupplier } from "src/modules/3rd-party-management/helpers/users";
import { connect } from "react-redux";

const Products = ({
    user,
    t,
    supplierId
}) => {

    const {
        data: supplierInfos,
        isFetching: supplierInfosAreFetching,
        isLoading: supplierInfosAreLoading
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		}
	});

    return (
        <div className="p-4">
            <OverviewHeader supplierId={ supplierId } />

            {!supplierInfosAreLoading && !supplierInfosAreFetching && (
                <ProductList 
                    industryIds={ supplierInfos.industries.map(industry => industry.id) } 
                    supplierId={ supplierId } 
                    readOnly={ !hasUserAccessToEditSupplier(user, supplierInfos.assignedAnalyst?.id) }
                />
            )}
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { user } = state.Login;

    return {
        user
    };
};

export default withNamespaces()(
    connect(mapStatetoProps, {})(
        withRouter(Products)
    )
); 