import { withNamespaces } from "react-i18next";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import DateUtils from "src/services/utils/DateUtils";
import { STANDARD_DATE_FORMAT } from "src/components/constants";
import Select from "react-select";

import SupplierProductService from "src/modules/3rd-party-management/apis/SupplierProductService";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

const ProductList = ({
    t,
    supplierId,
    industryIds,
    readOnly
}) => {
    const [ productsList, setProductsList ] = useState([]); 
    const [ products, setProducts ] = useState([]);

    const newProductNameRef = useRef();
    const newProductDescRef = useRef();
    const newProductSelectRef = useRef();

    const editProductNameRef = useRef();
    const editProductDescRef = useRef();
    const editProductSelectRef = useRef();

    const dateUtils = new DateUtils();

    const createNewProductRowId = 'create-new-product';
    
    const setProductStateFieldValue = (rowId, fieldsNames, fieldValue) => {
        const fields = Array.isArray(fieldsNames) ? fieldsNames : [fieldsNames];

        setProducts((products) => {
            const result = [...products].map((product) => {
                if(product.id === rowId){
                    const newstate = {};

                    fields.forEach((fieldName) => {
                        newstate[[fieldName]] = fieldValue;
                    });

                    return {
                        ...product,
                        state   :   {
                            ...product.state,
                            ...newstate
                        }
                    }
                }
    
                return product;
            });

            return result;
        });
    };

    const resetCreationForm = () => {
        newProductNameRef.current.value = "";
        newProductDescRef.current.value = "";
        newProductSelectRef.current.state.value = null;
    };

    const handleClickAddProduct = () => {
        const validationFaildInputs = [];
        if(!newProductNameRef.current.value){
            validationFaildInputs.push('nameValidationFaild');
        }

        if(!newProductDescRef.current.value){
            validationFaildInputs.push('descValidationFaild');
        }

        if(validationFaildInputs.length > 0){
            setProductStateFieldValue(createNewProductRowId, validationFaildInputs, true);
        }
        else{

            handleCreateSupplierProductMutation.mutate({
                name        :   parseInt(newProductNameRef.current.value),
                description :   newProductDescRef.current.value,
                supplier    :   supplierId
            });
        }
    };

    const handleClickSaveProductChanges = (row) => {
        const validationFaildInputs = [];

        if(!editProductNameRef.current.value){
            validationFaildInputs.push('nameValidationFaild');
        }

        if(!editProductDescRef.current.value){
            validationFaildInputs.push('descValidationFaild');
        }

        if(validationFaildInputs.length > 0){
            setProductStateFieldValue(row.id, validationFaildInputs, true);
        }
        else{
            setProductStateFieldValue(row.id, 'isUpdatingInProcess', true);

            handleUpdateSupplierProductMutation.mutate({
                id          :   row.id,
                payload     :   {
                    name        :   parseInt(editProductNameRef.current.value),
                    description :   editProductDescRef.current.value
                }
            }, {
                onSuccess   :   ()  =>  {
                    setProducts((products) => {
                        return [...products].map((product) => {
                            if(product.id === row.id){
                                return {
                                    ...product,
                                    name        :   {...product.name},
                                    description :   editProductDescRef.current.value,
                                    state       :   {
                                        ...product.state,
                                        isUpdatingInProcess :   false,
                                        displayEditingForm  :   false
                                    }
                                }
                            }
                            return product;
                        });
                    });
                },
                onError   :   ()  =>  {
                    setProductStateFieldValue(row.id, 'isUpdatingInProcess', false);
                },
                onSettled   :   ()  =>  {
                    setProductStateFieldValue(row.id, 'displayEditingForm', false);
                    setProductStateFieldValue(createNewProductRowId, 'disabled', false);
                }
            });
        }
    }

    const displayProductEditingForm = (row) => {
        setProducts((products) => {
            return [...products].map((product) => {
                return {
                    ...product,
                    state   :   {
                        ...product.state,
                        displayEditingForm  :   product.id === row.id,
                        disabled            :   product.id !== row.id,
                    }
                }
            });
        });
    }

    const handleFetchSupplierProductsList = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-products-list', supplierId],
		queryFn: async () => {
			const service = SupplierProductService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching products list.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierIndustryProductsList = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-industry-products-list', industryIds],
		queryFn: async () => {
			const service = IndustriesService.getInstance();

            return await service.fetchProducts({selectedIndustryIds: industryIds});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching products list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateSupplierProductMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierProductService.getInstance();

            setProductStateFieldValue(createNewProductRowId, 'isCreationInProcess', true);

            return await service.create(payload);
        },
        onSuccess: () => {
            resetCreationForm();
            handleFetchSupplierProductsList.refetch();
            toast(t("New product created successfully."), {
                type: "success",
            });
        },
        onError: () => {
            setProductStateFieldValue(createNewProductRowId, 'isCreationInProcess', false);
            toast(t("An error occurred while creating product."), {
                type: "error",
            });
        }
    });

    const handleDeleteSupplierProductMutation = useMutation({
        mutationFn: async (productId) => {
            const service = SupplierProductService.getInstance();

            setProductStateFieldValue(productId, 'isDeletionInProcess', true);

            return await service.delete(productId);
        },
        onSuccess: () => {
            handleFetchSupplierProductsList.refetch();
            toast(t("Product deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            setProductStateFieldValue(createNewProductRowId, 'isDeletionInProcess', false);

            toast(t("An error occurred while deleting product."), {
                type: "error",
            });
        }
    });

    const handleUpdateSupplierProductMutation = useMutation({
        mutationFn: async ({
            id,
            payload
        }) => {
            const service = SupplierProductService.getInstance();
            return await service.update(id, payload);
        },
        onSuccess: () => {
            handleFetchSupplierProductsList.refetch();

            toast(t("product updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating product."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        const list = (handleFetchSupplierProductsList?.data || []).map((productData) => {
            return {
                'id'            :   productData.id,
                'name'          :   productData.name,
                'createdon'     :   productData.createdAt,
                'description'   :   productData.description,
                'state'         :   {
                    isDeletionInProcess :   false,
                    displayEditingForm  :   false,
                    isUpdatingInProcess :   false,
                    nameValidationFaild :   false,
                    descValidationFaild :   false
                }
            }
        });

        list.push({
            'id'            :   createNewProductRowId,
            'name'          :   null,
            'createdon'     :   null,
            'description'   :   null,
            'state'         :   {
                isCreationInProcess :   false,
                nameValidationFaild :   false,
                descValidationFaild :   false
            }
        });

        setProducts(list);
    }, [ handleFetchSupplierProductsList.data ]);

    useEffect(() => {
        if(handleFetchSupplierIndustryProductsList.data && Array.isArray(handleFetchSupplierIndustryProductsList.data)){
            setProductsList(handleFetchSupplierIndustryProductsList.data.map((product) => {
                return {
                    value       :   product.id,
                    label       :   `${product.class} - ${t(product.name)}`,
                    baseLabel   :   product.name
                }
            }));
            
        }
    }, [ handleFetchSupplierIndustryProductsList.data, t ]);

    const industryProductsLoading = handleFetchSupplierIndustryProductsList.isFetching || handleFetchSupplierIndustryProductsList.isLoading;

    return (
        <Container>
            <Row>
                <Col>
                    <div className="table-responsive products-list mt-4 mb-4">
                        {
                            !industryProductsLoading && (
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                { t('Product Name') }
                                            </th>
                                            <th>
                                                { t('Created on') }
                                            </th>
                                            <th>
                                                { t('Description') }
                                            </th>
                                            {!readOnly && (
                                                <th>
                                                    { t('Action') }
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products.map((product, productIndex) => {
                                                if(product.id !== createNewProductRowId){
                                                    return (
                                                        <tr key={ productIndex }>
                                                            <td>
                                                                {
                                                                    product.state.displayEditingForm ? (
                                                                        <>
                                                                            <Select
                                                                                isLoading={ product.state.isUpdatingInProcess }
                                                                                isDisabled={ !!product.state.disabled }
                                                                                ref={ editProductSelectRef }
                                                                                placeholder={t("Product")}
                                                                                classNamePrefix="select2-selection"
                                                                                options={ productsList }
                                                                                menuPortalTarget={ document.body }
                                                                                onChange={(e) => {
                                                                                    editProductNameRef.current.value = e.value;
                                                                                }}
                                                                                defaultValue={{
                                                                                    value   :   product.name?.id,
                                                                                    label   :   `${product.name?.class} - ${t(product.name.name)}`
                                                                                }}
                                                                            />
                                                                            <Form.Control defaultValue={product.name?.id} isInvalid={ product.state.nameValidationFaild } type="hidden" ref={ editProductNameRef } />
                                                                            <Form.Control.Feedback className={product.state.nameValidationFaild ? 'd-block' : 'd-none'} type="invalid">
                                                                                {t('Product name is required')}
                                                                            </Form.Control.Feedback>
                                                                        </>
                                                                    ) : (
                                                                        <>{`${product.name.class} - ${t(product.name.name)}`}</>
                                                                    )
                                                                }
                                                            </td>

                                                            <td>
                                                                { 
                                                                    dateUtils.convertDateToDate(
                                                                        product.createdon, 
                                                                        STANDARD_DATE_FORMAT
                                                                    )
                                                                }
                                                            </td>
                                                            
                                                            <td>
                                                                {
                                                                    product.state.displayEditingForm ? (
                                                                        <>
                                                                            <Form.Control 
                                                                                disabled={ !!product.state.disabled } 
                                                                                defaultValue={ product.description } 
                                                                                isInvalid={ product.state.descValidationFaild } 
                                                                                type="text" 
                                                                                ref={ editProductDescRef } 
                                                                                placeholder={t('Description')} 
                                                                            />

                                                                            <Form.Control.Feedback 
                                                                                className={product.state.descValidationFaild ? 'd-block' : 'd-none'} 
                                                                                type="invalid">
                                                                                    { t('Description is required') }
                                                                            </Form.Control.Feedback>
                                                                        </>
                                                                    ) : (
                                                                        <>{ product.description }</>
                                                                    )
                                                                }
                                                            </td>

                                                            {!readOnly && (
                                                                <td>
                                                                    <div className="d-flex justify-content-start">
                                                                        {
                                                                            !product.state.displayEditingForm ? (
                                                                                <Button onClick={() => {
                                                                                    displayProductEditingForm(product);
                                                                                }} outline color="primary" size="md" className="border-0">
                                                                                    <i className="ri-pencil-line font-size-20"></i>
                                                                                </Button>
                                                                            ) : (
                                                                                <Button onClick={() => {
                                                                                    handleClickSaveProductChanges(product);
                                                                                }} disabled={product.state.isUpdatingInProcess} color="primary" size="md" className="border-0">
                                                                                    {
                                                                                        product.state.isUpdatingInProcess ? (
                                                                                            <Spinner animation="border" variant="white" size="sm"/>
                                                                                        ) : (
                                                                                            <i className="ri-save-line font-size-20"></i>
                                                                                        )
                                                                                    }
                                                                                </Button>
                                                                            )
                                                                        }

                                                                        {
                                                                            !product.state.displayEditingForm ? (
                                                                                <Button onClick={() => {
                                                                                    handleDeleteSupplierProductMutation.mutate(product.id)
                                                                                }} disabled={ product.state.isDeletionInProcess } outline color="danger" size="md" className="border-0">
                                                                                    {
                                                                                        product.state.isDeletionInProcess ? (
                                                                                            <Spinner animation="border" variant="danger" size="sm"/>
                                                                                        ) : (
                                                                                            <i className="ri-delete-bin-line font-size-20"></i>
                                                                                        )
                                                                                    }
                                                                                </Button>
                                                                            ) : (
                                                                                <Button onClick={() => {
                                                                                    setProductStateFieldValue(product.id, 'displayEditingForm', false);
                                                                                    setProductStateFieldValue(createNewProductRowId, 'disabled', false);
                                                                                }} disabled={ product.state.isUpdatingInProcess } outline color="secondary" size="md" className="border-0">
                                                                                    <i className="ri-close-line font-size-20"></i>
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )
                                                }

                                                return !readOnly ? (
                                                    <tr key={ createNewProductRowId }>
                                                        <td>
                                                            <Select
                                                                isDisabled={ !!product.state.disabled || handleFetchSupplierIndustryProductsList.isFetching }
                                                                ref={ newProductSelectRef }
                                                                placeholder={t("Product")}
                                                                classNamePrefix="select2-selection"
                                                                options={ productsList }
                                                                menuPortalTarget={ document.body }
                                                                onChange={(e) => {
                                                                    newProductNameRef.current.value = e.value;
                                                                }}
                                                                defaultValue={ null }
                                                            />
                                                            <Form.Control 
                                                                isInvalid={ product.state.nameValidationFaild } 
                                                                type="hidden" 
                                                                ref={ newProductNameRef } 
                                                            />

                                                            <Form.Control.Feedback 
                                                                className={product.state.nameValidationFaild ? 'd-block' : 'd-none'} type="invalid">
                                                                {t('Product name is required')}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        
                                                        <td colSpan={2}>
                                                            <Form.Control 
                                                                disabled={ !!product.state.disabled } 
                                                                defaultValue={ product.description } 
                                                                isInvalid={ product.state.descValidationFaild } 
                                                                type="text" 
                                                                ref={ newProductDescRef } 
                                                                placeholder={t('Description')} 
                                                            />
                                                            
                                                            <Form.Control.Feedback className={product.state.descValidationFaild ? 'd-block' : 'd-none'} type="invalid">
                                                                {t('Description is required')}
                                                            </Form.Control.Feedback>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex justify-content-start">
                                                                <Button onClick={ handleClickAddProduct } 
                                                                    color="primary" 
                                                                    disabled={ product.state.isCreationInProcess || product.state.disabled } 
                                                                    outline>
                                                                    {
                                                                        product.state.isCreationInProcess ? (
                                                                            <>
                                                                                <Spinner animation="border" variant="primary" size="sm"/>{' '}
                                                                                {t("Saving...")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <i className="ri-add-line align-middle me-2 font-size-20"></i>
                                                                                {t('Add Product')}
                                                                            </>
                                                                        )
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null
                                            })
                                        }
                                    </tbody>
                                </Table>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default withNamespaces()(ProductList);