import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Row, Alert } from "reactstrap";
import PlatformUpdateCard from "./PlatformUpdateCard";
import PlatfromUpdateModal from "./PlatformUpdateModal";

const PlatformUpdateList = (props) => {
  const { updates, t } = props;
  const [selectedUpdate, setSelectedUpdate] = useState(undefined);

  if (updates && updates.length === 0)
    return (
      <Alert color="warning">
        <div className="text-center w-100">
          <h3>{t("No Updates")}</h3>
        </div>
      </Alert>
    );

  return (
    <>
      <Row className="card-list">
        {updates.map((update, index) => (
          <>
            <PlatformUpdateCard
              key={update.id}
              update={update}
              setSelectedUpdate={setSelectedUpdate}
            />
          </>
        ))}
        <PlatfromUpdateModal
          selectedUpdate={selectedUpdate}
          setSelectedUpdate={setSelectedUpdate}
        />
      </Row>
    </>
  );
};

export default withNamespaces()(PlatformUpdateList);
