import React from "react";
import { handleRenderReportStatus } from "../../utils/helpers";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button } from "reactstrap";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import { ROLE_ANALYST, ROLE_ANALYST_ADMIN } from "src/components/constants";
import { toast } from "react-toastify";
import { routes } from "../../data";

const dateUtils = new DateUtils();

const restrictedRoles = [ROLE_ANALYST_ADMIN, ROLE_ANALYST];

export const getTableColumns = ({
  t,
  userUtils,
  setUserIdToDelete,
  updateUserStatusHandler,
  user,
  activeModule
}) => [
  {
    key: 1,
    dataField: "first_name",
    text: t("First name"),
    sort: true,
  },
  {
    key: 2,
    dataField: "last_name",
    text: t("Last name"),
    sort: true,
  },
  {
    key: 3,
    dataField: "email",
    text: t("Email"),
    style: {
      display: "table-cell",
      maxWidth: "200px",
    },
    headerStyle: {
      maxWidth: "200px",
    },
    sort: true,
  },
  {
    key: 4,
    dataField: "position",
    style: {
      display: "table-cell",
      maxWidth: "200px",
    },
    headerStyle: {
      maxWidth: "200px",
    },
    text: t("position"),
    sort: true,
  },
  {
    key: 5,
    dataField: "roles",
    text: t("Assigned Role"),
    style: {
      display: "table-cell",
      maxWidth: "200px",
    },
    sort: false,
    formatter: (cellContent, row) => {
      return (
        <div>
          {row.roles.map((i) => t(userUtils.gerUserRole(i))).join(", ")}
        </div>
      );
    },
  },
  {
    key: 6,
    dataField: "created_at",
    sort: true,
    text: t("Created on"),
    formatter: (cellContent, row) => {
      return (
        <div>
          {dateUtils.convertTimeStampToDate(
            cellContent,
            INTERNATIONAL_DATE_FORMAT
          )}
        </div>
      );
    },
  },
  {
    key: 7,
    dataField: "updated_at",
    sort: true,
    text: t("Updated on"),
    formatter: (cellContent, row) => {
      return (
        <div>
          {dateUtils.convertTimeStampToDate(
            cellContent,
            INTERNATIONAL_DATE_FORMAT
          )}
        </div>
      );
    },
  },
  {
    key: 8,
    dataField: "organization_name",
    text: `${t("Company")} ${t("name")}`,
  },
  {
    key: 9,
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => {
      return (
        <div>
          {handleRenderReportStatus({
            status: row.status,
            id: row.id,
            updateUserStatusHandler,
            t,
          })}
        </div>
      );
    },
  },
  {
    key: 10,
    dataField: "edit",
    text: t("Edit"),
    formatter: (cellContent, row) => {
      const isAccessDeniedForEdit =
        !userUtils.isSuperAdmin(user) &&
        row.roles.some((role) => restrictedRoles.includes(role));

      const handleClick = (e) => {
        e.preventDefault();
        toast.error(t("Access denied"));
      };
      return (
        <div className="d-flex gap-0">
          <Link
            to={routes[activeModule].editUser(row.id)}
            onClick={isAccessDeniedForEdit ? handleClick : null}
          >
            <Button color="link" className="waves-effect ">
              <i className="ri-edit-2-fill"></i>
            </Button>
          </Link>
          {isFeatureEnabledForActiveModule("delete-user") && (
            <Button
              onClick={() =>
                isAccessDeniedForEdit
                  ? toast(t("Access denied"), {
                      type: "error",
                    })
                  : setUserIdToDelete(row.id)
              }
              color="link"
              className="waves-effect  text-danger"
            >
              <i className="ri-delete-bin-fill"></i>
            </Button>
          )}
        </div>
      );
    },
  },
];
