import { withNamespaces } from "react-i18next";
import React, { memo, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import AddNewUser from "./tabs-content/new-user";
import AddExistingUser from "./tabs-content/exsisting-user"
import Tabs from "./tabs";
import Navigator from "src/components/navigator";
import { routes } from "../../data";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SuperAdminUserManagement = (props) => {
  const { t } = props;
  const activeModule = props.modules?.active;
  const location = useLocation();
  const history = useHistory();

  const navigatorOptions = useMemo(() => {
    return {
      backButtonOptions: () => {
        return {
          enable: true,
          onClick: () => history.push(routes[activeModule].listOfUsers),
          title: t("Back"),
          icon: <i className="ri-arrow-left-fill"></i>,
        };
      },
      breadCrumbs: [
        {
          title: t("User Management"),
          link: routes[activeModule]?.listOfUsers,
        },
        {
          title: t("New User"),
          link: routes[activeModule]?.newUser,
        },
      ],
      pageTitle: t("User Management"),
    };
  }, [t, location.search]);

  const renderContent = () => {
    const searchParams = new URLSearchParams(location.search);

    let result = null;

    switch (searchParams.get("tab")) {
      case "new-user":
        result = <AddNewUser />;
        break;
      case "existing-user":
        result = <AddExistingUser />;
        break;

      default:
        result = <AddNewUser />;
        break;
    }

    return (
      <React.Fragment>
        <Tabs />

        {result}
      </React.Fragment>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row classNames="super-admin-add-user">
          <Col lg="12" className="mb-4">
            <Navigator {...navigatorOptions} />
          </Col>
          <Col lg="12" className="mb-4">
            <div className="details-content-wrapper">{renderContent()}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(SuperAdminUserManagement)))
);
