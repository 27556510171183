import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Progress,
  Container,
  Alert,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Input,
} from "reactstrap";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import {
  authCurrentUser,
  logoutUser,
  loginUserSuccessful,
} from "../../store/auth/login/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import InputPhoneNumber from "../../components/Fields/InputPhoneNumber";
import axios from "axios";
import {
  API_URL_QUESTION_FORM_ORGANIZATION,
  CM_API_URL_QUESTION_FORM_ORGANIZATION,
  API_URL_CREATE_REPORT,
  CM_API_URL_CREATE_REPORT,
  API_URL_REPORT_UPLOAD_FILE,
  CM_API_URL_REPORT_UPLOAD_FILE,
  API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION,
  CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION,
  API_URL_CREATE_THIRD_PARTY_ACCOUNT,
  API_URL_SIGN_IN,
  AUTH_API_DOMAIN_URL,
  INTERNATIONAL_DATE_FORMAT,
  API_URL_GENERATE_RANDOM_USERNAME,
  CM_API_URL_GET_USER_DETAIL,
  API_URL_GET_USER_DETAIL,
  API_URL_FETCH_CAPTCHA,
  NotShowOrgInfosOrganizations,
} from "../../common/constants";
import {
  errorNotification,
  successNotification,
  checkLogin,
  setUserAccessToken,
  appError,
  appLoadStatusChanged,
} from "../../store/actions";
import { changeWorkspace } from "../../store/workspace/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserUtils from "../../services/utils/UserUtils";
import UrlUtils from "../../services/utils/UrlUtils";
import PhoneNumber from "../../components/Fields/PhoneNumber";
import Password from "../../components/Fields/Password";
import DateUtils from "../../services/utils/DateUtils";
import DateInputBefor from "../../components/Fields/DateInputBefor";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";
import "./Reports.css";
import { getCurrentLanguage } from "../../i18n";
import ExtendableAvField from "./Components/ReportComponents/ExtendableAvField";
import ConsentModal from "../Authentication/ConsentModal";
import MultiSelect from "src/components/MultiSelect";

class ReportConfidently extends Component {

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Report confidentially"), link: "#" },
      ],
      inputPhoneNumber: null,
      displayDate: "none",
      isThirdPartyUser: true,
      statusLogin: false,
      avToDateStartFrom: new Date(),
      avToDate: new Date(),
      statusCreateReport: false,
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 33,
      questions: [],
      categories: [],
      locations: [],
      legalEntities: [],
      selectedLegalEntities: [],
      selectedFiles: [],
      uploadedFiles: [],
      submitData: null,
      selectedAuthType: "register",
      authUserInfos: null,
      phoneAvailableTimes: [],
      userCustomAvaTime: "",
      showCustomAvaTime: false,
      selectedAnalystReportingTypeOption: "myself",
      selectedAnalystReportingTypeOptionValue: "other_one",
      modalForCalendar: false,
      calendarValue: null,
      isThirdPartyUserLogin:
        window.location.href.includes("third-party") && this.props.user
          ? true
          : false,
      selectedDay: new Date(),
      checkFirstName: false,
      showProg: false,
      popover: {
        id: null,
        active: false,
      },
      displayFollowUp: true,
      displayButton: true,
      showAlertSuccess: false,
      reload: false,
      usernameTooltipOpen: false,
      passwordTooltipOpen: false,
      randomGeneratedUsername: "",
      username: null,
      password: "",
      report_passwor: "",
      organization_name: "",
      consentChecked: true,
      // Consent
      regConsent: false,
      showConsentModal: false,
      loginConsent: false,
      captchaFetched: false,
      captchaSrc: "",
      captcha: "",
      categoryQuestion: "",
    };

    this.toggleTabProgress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finalizeReport = this.finalizeReport.bind(this);
    this.handleFollowUpForm = this.handleFollowUpForm.bind(this);
    this.handleAvaTimeChange = this.handleAvaTimeChange.bind(this);
    this.uploadAttachmentFile = this.uploadAttachmentFile.bind(this);
    this.handleChangeRadioBox = this.handleChangeRadioBox.bind(this);
    this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

    this.userUtils = new UserUtils();
    this.dateUtils = new DateUtils();
    this.urlUtils = new UrlUtils();

    this.dropzoneAcceptedFiles = {
      accepted:
        "text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
      description: [
        `${this.props.t(
          "You are allowed to upload limited file types"
        )}. ${this.props.t("For more information")}, ${this.props.t("visit")}`,
        <a
          href="https://diss-co.tech/faq-diss-co/"
          target="_blank"
        >{` ${this.props.t("FAQ")} `}</a>,
        this.props.t("page"),
      ],
    };
  }

  logoutCurrentUser() {
    this.props.logoutUser(this.props.history);
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  statusLogin() {
    if (this.urlUtils.getUrlParamThirdParty() === "third-party") {
      if (this.userUtils.isThirdParty(this.props.user) === true) {
        this.setState({
          isThirdPartyUserLogin: true,
        });
      } else {
        this.setState({
          isThirdPartyUserLogin: false,
        });
      }

      if (this.userUtils.isEmployee(this.props.user) === true) {
        this.setState({
          isEmployeeUserLogin: true,
        });
      } else {
        this.setState({
          isEmployeeUserLogin: false,
        });
      }

      this.setState({
        isThirdPartyUser: true,
      });
    } else {
      this.setState({
        isThirdPartyUser: false,
      });
    }

    if (
      this.userUtils.isAnalystOrAnalystAdmin(this.props.user) === true ||
      this.userUtils.isThirdParty(this.props.user) === true ||
      this.userUtils.isEmployee(this.props.user) === true
    ) {
      this.setState({
        statusLogin: true,
      });
    } else {
      this.setState({
        statusLogin: false,
      });
    }
  }

  fetchReportCaseAfterLoginTherdParty() {
    if (this.userUtils.isThirdParty(this.props.user) === true) {
      if (this.props.user.first_name) {
        this.setState({
          checkFirstName: true,
        });
      } else {
        this.setState({
          checkFirstName: false,
        });
      }

      if (this.props.user.last_name) {
        this.setState({
          checkLastName: true,
        });
      } else {
        this.setState({
          checkLastName: false,
        });
      }

      if (this.props.user.email) {
        this.setState({
          checkEmail: true,
        });
      } else {
        this.setState({
          checkEmail: false,
        });
      }

      if (this.props.user.position) {
        this.setState({
          checkPosition: true,
        });
      } else {
        this.setState({
          checkPosition: false,
        });
      }

      if (this.props.user.gender) {
        this.setState({
          checkTitle: true,
        });
      } else {
        this.setState({
          checkTitle: false,
        });
      }
    }
  }

  handleAcceptedFiles = (files, id) => {
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};

    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });

      const formData = new FormData();

      formData.append("report_type", 1);
      formData.append("file", file);
      this.setState({ showProg: true });
      axios
        .post(
          this.props.modules.active === "wb"
            ? API_URL_REPORT_UPLOAD_FILE
            : CM_API_URL_REPORT_UPLOAD_FILE,
          formData,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            const fileData = response.data.data;
            this.setState({ showProg: false });
            this.setState({
              uploadedFiles: [
                ...this.state.uploadedFiles,
                {
                  questionId: id,
                  id: fileData.id,
                  size: fileData.size,
                  file: fileData,
                  name: file.name,
                  preview: file.preview,
                  formattedSize: file.formattedSize,
                },
              ],
            });
          } else {
            this.props.errorNotification({
              code: response.data.error,
              message: this.props.t(
                "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support."
              ),
            });
          }
        })
        .catch((error) => {
          this.setState({ showProg: false });

          this.props.errorNotification({
            code: error.response.data.error,
            message: this.props.t(error.response.data.message),
          });
        });
    });

    this.setState({ selectedFiles: files });
  };

  handleClickDeleteFiles = (file) => {
    const url =
      this.props.modules.active === "wb"
        ? AUTH_API_DOMAIN_URL + `attachment/${file.id}/delete`
        : AUTH_API_DOMAIN_URL + `cm/attachment/${file.id}/delete`;
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};

    let newSelectedFiles = this.state.selectedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });
    let newUploadedFiles = this.state.uploadedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    const deletedFile = {
      file: file.name,
      report_case_id: file.id,
    };

    axios
      .post(url, deletedFile, headers)
      .then((data) => {
        this.setState({
          selectedFiles: newSelectedFiles,
          uploadedFiles: newUploadedFiles,
        });

        this.props.successNotification({
          code: this.props.t("Data was deleted successfully"),
          message: this.props.t("Data was deleted successfully"),
        });
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      });
  };

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      window.scrollTo({
        left: 0,
        top: 0,
      });
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (this.state.selectedAnalystReportingTypeOptionValue === "myself") {
          if (tab === 1) {
            this.setState({ progressValue: 33 });
          }
          if (tab === 2) {
            this.setState({ progressValue: 66 });
          }
          if (tab === 3) {
            this.setState({ progressValue: 100 });
            if (this.userUtils.isAnalystOrAnalystAdmin(this.props.user)) {
              this.setState({ showProg: true });
              this.finalizeReport();
            }
          }
          if (tab === 4) {
            if (this.state.statusCreateReport === true) {
              this.setState({ progressValue: 100 });
            } else {
              this.setState({
                activeTabProgress: tab - 1,
              });
            }
          }
        } else {
          if (tab === 1) {
            this.setState({ progressValue: 25 });
          }
          if (tab === 2) {
            this.setState({ progressValue: 50 });
          }
          if (tab === 3) {
            this.setState({ progressValue: 75 });
          }
          if (tab === 4) {
            if (this.state.statusCreateReport === true) {
              this.setState({ progressValue: 100 });
            } else {
              if (
                this.userUtils.isAnalystOrAnalystAdmin(this.props.user) ||
                this.userUtils.isEmployee(this.props.user) ||
                (this.props.user &&
                  this.userUtils.isThirdParty(this.props.user)) ||
                !this.props.user
              ) {
                this.finalizeReport();
              }
              this.setState({
                activeTabProgress: tab - 1,
              });
            }
          }
        }
      }
    }
  }

  getQuestionsAnswers(answers) {
    let result = [];
    answers.map((answer, id) => {
      let getDate = document.getElementById("date-" + answer);

      // When did it happen?
      if (getDate !== null) {
        result.push({
          id: id,
          answer: [
            {
              id: answer,
              date: getDate.value,
            },
          ],
        });
      } else {
        result.push({
          id: id,
          answer: answer,
        });
      }
    });
    return result;
  }

  findUserAnswer(question) {
    if (question.type === "file") {
      const answer = this.state.uploadedFiles.find((file) => {
        return file.questionId === question.id;
      });
      if (answer) {
        return answer.file.name;
      }
    } else {
      const answers = this.state.submitData.question_answer;
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].id === question.id) {
          return answers[i].answer;
        }
      }
    }
    return null;
  }

  handleSubmit(e, values) {
    const files = this.state.uploadedFiles.map((file) => {
      return file.id;
    });
    this.setState(
      {
        submitData: {
          files: files,
          category: values.Question_Category,
          description: values.description,
          email: values.email || null,
          email_other_one: values.email_other_one || null,
          first_name: values.first_name,
          last_name: values.last_name,
          organization:
            values.organization || this.urlUtils.getOrganizationId(),
          phone: values.phone || "",
          phone_available_time: null,
          title: values.title,
          position: values.position || null,
          relation_company: values.relation_company,
          salutation: values.salutation,
          type: 2,
          source: values.source || "web",
          locations: [values.locations],
          question_answer: this.getQuestionsAnswers(values.Questien),
          reporting_type: this.state.selectedAnalystReportingTypeOption,
        },
      },
      () => {
        this.toggleTabProgress(this.state.activeTabProgress + 1);
      }
    );
  }

  handleFollowUpForm(e, values) {
    if (!this.props.user) {
      if (
        values.auth_type === "register" &&
        values.password !== values.repeat_pasword
      ) {
        this.props.errorNotification({
          code: "password_not_equal",
          message: this.props.t(
            "Password and repeat password are not the same"
          ),
        });
        return;
      }
      if (values.password.length < 10) {
        this.props.errorNotification({
          code: "invalid_password",
          message: this.props.t(
            "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
          ),
        });
        return;
      }
      this.setState({
        authUserInfos: values,
      });
    }
    let userSelectedAvaTimes =
      values &&
      values.phone_available_time &&
      values.phone_available_time.length > 0
        ? values.phone_available_time
        : [];

    let userAvaTimesResult = userSelectedAvaTimes.map((selectedTime) => {
      if (selectedTime === "custom") {
        return {
          optionId: "custom",
          value: this.state.userCustomAvaTime,
        };
      } else {
        return {
          optionId: selectedTime,
          value: "",
        };
      }
    });

    this.setState(
      {
        submitData: {
          ...this.state.submitData,
          email: values && values.email,
          email_other_one: values && values.email_other_one,
          phone: this.state.inputPhoneNumber,
          phoneNumber: this.state.inputPhoneNumber,
          phone_available_time: userAvaTimesResult,
        },
      },
    );
  }

  handleNewFollowUpForm = (e, values) => {
    let userSelectedAvaTimes =
      values.phone_available_time && values.phone_available_time.length > 0
        ? values.phone_available_time
        : [];

    let userAvaTimesResult = userSelectedAvaTimes.map((selectedTime) => {
      if (selectedTime === "custom") {
        return {
          optionId: "custom",
          value: this.state.userCustomAvaTime,
        };
      } else {
        return {
          optionId: selectedTime,
          value: "",
        };
      }
    });

    this.setState(
      {
        submitData: {
          ...this.state.submitData,
          phone_available_time: userAvaTimesResult,
          user_policy_accepted: this.state.consentChecked,
          privacy_policy_accepted: this.state.consentChecked,
        },
      },
      () => this.toggleTabProgress(this.state.activeTabProgress + 1)
    );
  };

  uploadAttachmentFile(e, { questionId }) {
    const formData = new FormData();
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};
    formData.append("report_type", 2);
    formData.append("file", e.target.files[0]);

    axios
      .post(
        this.props.modules.active === "wb"
          ? API_URL_REPORT_UPLOAD_FILE
          : CM_API_URL_REPORT_UPLOAD_FILE,
        formData,
        headers
      )
      .then((response) => {
        const file = response.data.data;
        this.setState({
          uploadedFiles: [
            ...this.state.uploadedFiles,
            {
              questionId: questionId,
              id: file.id,
              size: file.size,
              file: e.target.files[0],
            },
          ],
        });
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      });
  }

  findSubQuestion(questionId, subId) {
    const questions = this.state.questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id === questionId) {
        for (let j = 0; j < questions[i].sub_question.length; j++) {
          if (typeof subId === "object" || typeof subId === "array") {
            if (questions[i].sub_question[j].id === subId[0].id) {
              return [questions[i].sub_question[j], subId[0]];
            }
          }
          if (questions[i].sub_question[j].id === subId) {
            return questions[i].sub_question[j];
          }
        }
      }
    }
    return null;
  }

  getToken = async () => {
    let userToken = null;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;
    if (!this.props.user && !this.state.registerStatus) {
      try {
        userToken = await new Promise(async (nonAuthRes, nonAuthRej) => {
          try {
            if (
              this.state.authUserInfos.auth_type === "register" ||
              !this.state.authUserInfos.auth_type
            ) {
              var currentEmail;
              currentEmail = this.state.authUserInfos.email;
              if (this.state.authUserInfos.email === "") {
                currentEmail = null;
              }
              if (!passwordRegex.test(this.state.authUserInfos.password)) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowUp: true,
                  consentChecked: false,
                });
                return this.props.errorNotification({
                  code: "passwords-not-valid",
                  message: this.props.t(
                    "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
                  ),
                });
              } else if (
                this.state.authUserInfos.password !==
                this.state.authUserInfos.repeat_pasword
              ) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowUp: true,
                  consentChecked: false,
                });
                return this.props.errorNotification({
                  code: "password_not_equal",
                  message: this.props.t(
                    "Password and repeat password are not the same"
                  ),
                });
              } else {
                const responseCreate = await axios
                  .post(API_URL_CREATE_THIRD_PARTY_ACCOUNT, {
                    organization: this.props.Organization.id,
                    username: this.state.authUserInfos.username,
                    password: this.state.authUserInfos.password,
                    email: currentEmail,
                    isThirdPartyUser: this.state.isThirdPartyUser,
                    privacyPolicyAccepted: this.state.regConsent,
                    userPolicyAccepted: this.state.regConsent,
                    verifyCode: this.state.captcha,
                  }, {
                    withCredentials: true,
                  })
                  .then(async (data) => {
                    this.setState({ registerStatus: true });

                    try {
                      const response = await axios.post(API_URL_SIGN_IN, {
                        organization: this.props.Organization.id,
                        username: this.state.authUserInfos.username,
                        password: this.state.authUserInfos.password,
                        isThirdPartyUser: this.state.isThirdPartyUser,
                        "2fa": false,
                      });
                      nonAuthRes(response.data.data.token);
                    } catch (error) {
                      console.log("login faild. try catch error");
                      this.setState({
                        displayButton: true,
                        showProg: false,
                        displayFollowUp: true,
                        consentChecked: false,
                      });
                      this.props.errorNotification({
                        code: "passwords-not-valid",
                        message: this.props.t(
                          "Your email and password combination is not correct"
                        ),
                      })
                    }
                  })
                  .catch((error) => {
                    console.log(error.response.data.message);
                    this.setState({
                      displayButton: true,
                      showProg: false,
                      displayFollowUp: true,
                      consentChecked: false,
                    });

                    for (var key in error.response.data.message) {
                      if (error.response.data.message.hasOwnProperty(key)) {
                        if (
                          "email" in error.response.data.message &&
                          error.response.data.message.email ===
                            "validation.email.already_used"
                        ) {
                          this.props.errorNotification({
                            code: "validation_error",
                            message: this.props.t("Email already used."),
                          });
                        } else {
                          this.props.errorNotification({
                            code: "validation_error",
                            message: this.props.t(
                              error.response.data.message[key]
                            ),
                          });
                        }
                      }
                    }
                  });
              }
            } else {
              try {
                const response = await axios.post(API_URL_SIGN_IN, {
                  organization: this.props.Organization.id,
                  username: this.state.authUserInfos.username,
                  password: this.state.authUserInfos.password,
                  isThirdPartyUser: this.state.isThirdPartyUser,
                  "2fa": false,
                });
                nonAuthRes(response.data.data.token);
              } catch (error) {
                console.log("login faild. try catch error");
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowUp: true,
                  consentChecked: false,
                });
                this.props.errorNotification({
                    code: "passwords-not-valid",
                    message: this.props.t(
                      "Your email and password combination is not correct"
                    ),
                  })
              }
            }
          } catch (error) {
            this.setState({
              displayButton: true,
              showProg: false,
              consentChecked: false,
            });
            if (!this.state.tabs[3].active) {
                this.props.errorNotification({
                    code: "fatal_error",
                    message: this.props.t(
                      "Username already used"
                    ),
                  })
            }
          }
        });
      } catch (error) {
        this.setState({
          displayButton: true,
          showProg: false,
          consentChecked: false,
        });
        this.props.errorNotification({
          code: "fatal_error",
          message: error,
        });
        return;
      }
    } else {
      userToken = this.props.token;
    }
    this.props.setUserAccessToken(userToken);
    return localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, userToken);
  };

  authUser = async() => {
    const url = localStorage.getItem("module") === "cm" ? CM_API_URL_GET_USER_DETAIL : API_URL_GET_USER_DETAIL;
    
    const response = axios
				.post(
					url,
					{
						organizationId: this.props.Organization?.id,
					},
					{
						headers: {
							Authorization: `Bearer ${this.props.token}`,
						},
					},
				);

        
      return response;
  }

  makeAuth = async () => {
    await this.getToken();

    if (this.props.token) {
      await this.props.authCurrentUser(this.state.authUserInfos);

      await this.props.loginUserSuccessful(this.state.authUserInfos);
      await this.statusLogin();
    } else {
      this.setState(
        {
          showProg: false,
          displayButton: true,
        },
      );
    }
  };

  toggleConsentModal = () => {
    
    this.setState({
      showConsentModal: !this.state.showConsentModal, 
      showProg: false,
      displayFollowUp: true,
      activeTabProgress: 3
    })    
  }

  async finalizeReport() {
    this.setState({ displayFollowUp: false, showAlertSuccess: false,showProg: false});
    const nex = !this.props.user ? await this.makeAuth() : null;
    const response = await this.authUser();
    if( this.state.isThirdPartyUser && response?.data?.data?.consentStatus === false) {
      return this.setState({showConsentModal: true})
    } else {
      return this.createReport();
    }
  }

  createReport = async () => {
    this.setState({showConsentModal: false})
    if (this.state.organization_name) {
      Object.assign(this.state.submitData, {
        organization_name: this.state.organization_name,
      });
    }
    localStorage.setItem("org", this.urlUtils.getOrganizationName());
    new Promise(async (res, reject) => {
      let userToken = this.props.token;
      axios
        .post(
          this.props.modules.active === "wb"
            ? API_URL_CREATE_REPORT
            : CM_API_URL_CREATE_REPORT,
          this.state.submitData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            statusCreateReport: true,
            showAlertSuccess: true,
          });
          const next =
            this.state.selectedAnalystReportingTypeOptionValue === "myself"
              ? this.toggleTabProgress(this.state.activeTabProgress)
              : this.toggleTabProgress(this.state.activeTabProgress + 1);
        })
        .catch((error) => {
          this.setState({
            displayButton: true,
            showProg: false,
            statusCreateReport: false,
            displayFollowUp: true,
          });

          let switchToFirstStep = false;

          for (var key in error.response.data.message) {
            switch (key) {
              case "email": {
                if (
                  error.response.data.message.email ===
                  "validation.email.already_used"
                )
                  this.props.errorNotification({
                    code: "validation_error",
                    message: this.props.t("Email already used."),
                  });
                break;
              }
              case "description": {
                switchToFirstStep = true;
                if (
                  error.response.data.message.description ===
                  "This value is too long. It should have 1000 characters or less."
                )
                  this.props.errorNotification({
                    code: "validation_error",
                    message: this.props.t(
                      "Description must have 1000 characters or less."
                    ),
                  });
                break;
              }
              default: {
                switchToFirstStep = true;
                this.props.errorNotification({
                  code: "validation_error",
                  message: this.props.t(error.response.data.message[key]),
                });
                break;
              }
            }
          }

          switchToFirstStep && this.toggleTabProgress(this.state.activeTabProgress - 2);
          
          return;
        });
    });
  }

  handleChangeRadioBox(e) {
    let input = document.getElementById("displayDateID-" + e.target.value);
    if (e.target.checked == true) {
      var rates = document.getElementsByName(e.target.name);
      var rate_value;
      for (var i = 0; i < rates.length; i++) {
        if (rates[i].checked == false) {
          document.getElementById(
            "displayDateID-" + rates[i].value
          ).style.display = "none";
        }
      }
      input.style.display = "block";
    } else {
      input.style.display = "none";
    }

    this.setState({
      modalForCalendar: false,
    });
  }

  setSelectedDay(e) {
    this.setState({
      setSelected: e,
    });
  }

  handleChangeCalendar(e) {
    this.setState({
      calendarValue: e,
    });
  }

  handleAvaTimeChange(e, avaTime) {
    if (avaTime.optionId === "custom") {
      this.setState({
        showCustomAvaTime: e.target.checked,
      });
    }
  }

  getUserHome() {
    return this.userUtils.getUserHomeRoute(this.props.user);
  }

  generateRandomUsername = async () => {
    this.setState({ username: null });
    const api = API_URL_GENERATE_RANDOM_USERNAME + getCurrentLanguage();
    axios.get(api).then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        this.setState({
          randomGeneratedUsername: data,
          randomGenerated: true,
          authUserInfos: {
            ...this.state.authUserInfos,
            username: data,
          },
        });
      }
    });
  };

  renderRegisterForm() {
    if (!this.props.user) {
      return (
        <Row>
          <Col sm="12" className="mb-3">
            <AvRadioGroup
              defaultValue={this.state.selectedAuthType}
              onChange={(e) =>
                this.setState({
                  authUserInfos: { auth_type: e.target.value },
                })
              }
              name="auth_type"
              validate={{ required: { value: true } }}
              errorMessage={this.props.t("This field cannot be blank")}
            >
              <Row>
                <Col sm="12" md="6">
                  <AvRadio
                    label={this.props.t("Log In")}
                    onChange={(e) =>
                      this.setState({
                        selectedAuthType: "login",
                        randomGeneratedUsername: "",
                        authUserInfos: {
                          ...this.state.authUserInfos,
                          auth_type: e.target.value,
                        },
                      })
                    }
                    value="login"
                  />
                </Col>
                <Col sm="12" md="6">
                  <AvRadio
                    label={this.props.t("Create account")}
                    onChange={(e) =>
                      this.setState({
                        selectedAuthType: "register",
                        authUserInfos: {
                          ...this.state.authUserInfos,
                          auth_type: e.target.value,
                        },
                      })
                    }
                    value="register"
                  />
                </Col>
              </Row>
            </AvRadioGroup>
          </Col>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="username">
              {`${this.props.t("Username")}`}
              <span className="ms-2">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="infoIconU"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.usernameTooltipOpen}
                  target="infoIconU"
                  toggle={() =>
                    this.setState({
                      usernameTooltipOpen: !this.state.usernameTooltipOpen,
                    })
                  }
                >
                  {this.props.t(
                    "Please choose a username that doesnt contain your name or any kind of email address"
                  )}
                </Tooltip>
              </span>
              <Button
                outline
                hidden={this.state.selectedAuthType === "login"}
                className="btn-sm color-primary ms-2"
                onClick={this.generateRandomUsername}
              >
                {this.props.t("Random")}
              </Button>
            </Label>
            <ExtendableAvField
              name="username"
              placeholder=""
              type="text"
              className="form-control"
              errorMessage={this.props.t("This field cannot be blank")}
              onChange={(e) =>
                this.setState({
                  username: e.target.value,
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    username: e.target.value,
                  },
                })
              }
              validate={{
                required: { value: true },
                pattern: {
                  value: /^[^@\s]+$/, // regular expression to disallow emails
                  errorMessage: this.props.t("Please enter a valid username"),
                },
              }}
              value={this.state.username || this.state.randomGeneratedUsername}
              id="username"
            />
          </Col>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="password">
              {`${this.props.t("Password")}`}
              <span className="ms-2">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="infoIcon"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.passwordTooltipOpen}
                  target="infoIcon"
                  toggle={() =>
                    this.setState({
                      passwordTooltipOpen: !this.state.passwordTooltipOpen,
                    })
                  }
                >
                  {this.props.t(
                    "Please choose a password at least 10 characters contains 1 capital letter,1 digit, and 1 special character contains"
                  ) +
                    ": ! @ # $ % ^ & * ( ) _ [ ]+={ } ' \" ; : / ? . , < > |  -"}
                </Tooltip>
              </span>
            </Label>
            <Password
              name="password"
              onChange={(e) =>
                this.setState({
                  password: e.target.value,
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    password: e.target.value,
                  },
                })
              }
              placeholder=""
              errorMessage={this.props.t("This field cannot be blank")}
              validate={{ required: { value: true } }}
              id="password"
              value={this.state.password}
            />
          </Col>
          {this.state.selectedAuthType === "register" ? (
            <>
            <Col sm="12" className="mb-3">
              <Label className="form-label" htmlFor="repeatpasword">
                {`${this.props.t("Repeat password")}`}
              </Label>
              <Password
                name="repeat_pasword"
                placeholder=""
                onChange={(e) =>
                  this.setState({
                    repeat_pasword: e.target.value,
                    authUserInfos: {
                      ...this.state.authUserInfos,
                      repeat_pasword: e.target.value,
                    },
                  })
                }
                className="form-control"
                value={this.state.repeat_pasword}
                errorMessage={this.props.t("This field cannot be blank")}
                validate={{ required: { value: true } }}
                id="repeatpasword"
              />
            </Col>
          </>
          ) : null}
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="email">
              {`${this.props.t("Email")} ${this.props.t("(Optional)")}`}
            </Label>
            <ExtendableAvField
              name="email"
              placeholder=""
              type="email"
              className="form-control"
              validate={{ required: { value: false }, email: true }}
              errorMessage={this.props.t("This field is invalid")}
              id="email"
              onChange={(e) => {
                this.setState({
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    email: e.target.value,
                  },
                  submitData: {
                    ...this.state.submitData,
                    email: e.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="phone-number">
              {`${this.props.t("Phone number")} ${this.props.t("(Optional)")}`}
            </Label>
            <InputPhoneNumber
              id="phone-number"
              name="phoneNumber"
              required={false}
              className="form-control"
              placeholder=""
              errorMessage=""
              onChange={(phone) =>
                this.setState({
                  inputPhoneNumber: phone,
                  submitData: {
                    ...this.state.submitData,
                    phone: phone.length > 4 ? phone : null,
                  },
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    phoneNumber: phone,
                  },
                })
              }
              value={this.props.user ? this.props.user.phone_number : ""}
              validate={{ required: { value: false } }}
            />
          </Col>

          
        </Row>
      );
    }
    return null;
  }

  renderEmailField() {
    if (
      !this.props.user ||
      (this.userUtils.isThirdParty(this.props.user) && !this.props.user.email)
    ) {
      return (
        <Col sm="12" className="mb-3">
          <Label className="form-label" htmlFor="email">
            Email (Optional)
          </Label>
          <ExtendableAvField
            name="email"
            placeholder=""
            type="email"
            className="form-control"
            validate={{ required: { value: false }, email: true }}
            errorMessage={this.props.t("This field is invalid")}
            id="email"
          />
        </Col>
      );
    }
    return null;
  }

  renderUserInfosFields() {
    {/* 
        * SPIK-7479
        * This is an uggly way to handle this task. 
        * This part should be changed in the future after handling it by the backend.
    */}
    const hideOrgInfo = this.props.modules.active === 'cm' && NotShowOrgInfosOrganizations.includes(this.props.Organization.id);

    if (!this.props.user || this.userUtils.isThirdParty(this.props.user)) {
      return (
        <Row>
          <Col sm="12" lg="9">
            {!this.props.token && (
              <>
                <Row className="mb-3">
                  <Col sm="12" lg="12">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="relation_company">
                        {this.props.t("What is your relation to the company?")}
                      </Label>
                      <AvRadioGroup
                        inline
                        name="relation_company"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        validate={{ required: { value: true } }}
                      >
                        <Row>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("Employee")}
                              value="Employee"
                            />
                          </Col>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("External")}
                              value="External"
                            />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                    </div>
                  </Col>
                  <Col sm="12" lg="12">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {this.props.t("Salutation")} {this.props.t("(Optional)")}
                      </Label>
                      <AvRadioGroup inline name="title">
                        <Row>
                          <Col sm="12" lg="6">
                            <AvRadio label={this.props.t("Mr.")} value="Mr." />
                          </Col>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("Mrs.")}
                              value="Mrs."
                            />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12" lg={hideOrgInfo ? '6' : '4'}>
                    <Label className="form-label" htmlFor="first_name">
                      {this.props.t("First name")}
                    </Label>
                    <ExtendableAvField
                      name="first_name"
                      placeholder=""
                      type="text"
                      errorMessage={this.props.t("This field cannot be blank")}
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="first_name"
                    />
                  </Col>
                  <Col sm="12" lg={hideOrgInfo ? '6' : '4'}>
                    <Label className="form-label" htmlFor="last_name">
                      {this.props.t("Last name")}
                    </Label>
                    <ExtendableAvField
                      name="last_name"
                      placeholder=""
                      type="text"
                      errorMessage={this.props.t("This field cannot be blank")}
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="last_name"
                    />
                  </Col>
                  {!hideOrgInfo && (
                    <Col 
                        sm="12" 
                        lg="4" 
                    >
                        <Label className="form-label" htmlFor="organization">
                            {this.props.t("Organization")}
                        </Label>
                        <ExtendableAvField
                            name="organization"
                            placeholder=""
                            type="text"
                            errorMessage={this.props.t("This field cannot be blank")}
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={(event) =>
                                this.setState({ organization_name: event.target.value })
                            }
                            id="organization"
                        />
                    </Col>
                  )}
                </Row>
                {!hideOrgInfo && (
                    <Row className='mb-3'>
                        <Col sm="12" lg="12">
                            <div className="mb-3">
                            <Label className="form-label" htmlFor="Position">
                                {this.props.t("Position")}
                            </Label>
                            <ExtendableAvField
                                name="position"
                                placeholder=""
                                type="text"
                                errorMessage={this.props.t(
                                "This field cannot be blank"
                                )}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="Position"
                            />
                            </div>
                        </Col>
                    </Row>
                )}
              </>
            )}
            <Row>
              {!this.state.checkTitle && this.state.isThirdPartyUserLogin && (
                <>
                  <Col sm="12" md="4">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {this.props.t("Salutation")} {this.props.t("(Optional)")}
                      </Label>
                      <AvField
                        id="title"
                        type="select"
                        name="title"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        value={!this.props.user ? "" : this.props.user.gender}
                        defaultValue={"Mr."}
                        validate={{ required: { value: false } }}
                      >
                        <option value={"Mr."}>{this.props.t("Mr.")}</option>
                        <option value={"Mrs."}>{this.props.t("Mrs.")}</option>
                      </AvField>
                    </div>
                  </Col>
                </>
              )}

              {!this.state.checkFirstName &&
                this.state.isThirdPartyUserLogin && (
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="first_name">
                        {this.props.t("First name")}
                      </Label>
                      <ExtendableAvField
                        name="first_name"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="first_name"
                      />
                    </Col>
                  </>
                )}
              {!this.state.checkLastName &&
                this.state.isThirdPartyUserLogin && (
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="last_name">
                        {this.props.t("Last name")}
                      </Label>
                      <ExtendableAvField
                        name="last_name"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="last_name"
                      />
                    </Col>
                  </>
                )}

              {!this.state.checkPosition &&
                this.state.isThirdPartyUserLogin && !hideOrgInfo &&(
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="position">
                        {this.props.t("Position")}
                      </Label>
                      <ExtendableAvField
                        name="position"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="position"
                      />
                    </Col>
                  </>
                )}
            </Row>
          </Col>
        </Row>
      );
    } else if (
      this.userUtils.isAnalystOrAnalystAdmin(this.props.user) &&
      this.state.selectedAnalystReportingTypeOption === "other_one"
    ) {
      return (
        <Row className="mb-3">
          <Col sm="12" lg="9">
            <Row className="mb-3">
              <Col sm="12" lg="12">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="source">
                    {this.props.t("Choose the channel of information")}
                  </Label>
                  <AvField
                    id="source"
                    type="select"
                    placeholder={this.props.t(
                      "Choose the channel of information"
                    )}
                    name="source"
                    errorMessage={this.props.t("This field cannot be blank")}
                    className="form-control"
                    validate={{ required: { value: true } }}
                    value={"email"}
                  >
                    <option value="email">{this.props.t("Email")}</option>
                    <option value="letter">{this.props.t("Letter")}</option>
                    <option value="phone">{this.props.t("Phone")}</option>
                    <option value="meeting">{this.props.t("Meeting")}</option>
                    <option value="live_chat">
                      {this.props.t("Live Chat")}
                    </option>
                    <option value="other">
                      {this.props.t(`${this.props.t("Other")}`)}
                    </option>
                  </AvField>
                </div>
              </Col>
              <Col sm="12" lg="12">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="relation_company">
                    {this.props.t("What is your relation to the company?")}
                  </Label>
                  <AvRadioGroup
                    inline
                    name="relation_company"
                    errorMessage={this.props.t("This field cannot be blank")}
                    validate={{ required: { value: true } }}
                  >
                    <Row>
                      <Col sm="12" lg="6">
                        <AvRadio
                          label={this.props.t("Employee")}
                          value="Employee"
                        />
                      </Col>
                      <Col sm="12" lg="6">
                        <AvRadio
                          label={this.props.t("External")}
                          value="External"
                        />
                      </Col>
                    </Row>
                  </AvRadioGroup>
                </div>
              </Col>

              <Col sm="12" lg="12">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="title">
                    {this.props.t("Salutation")} {this.props.t("(Optional)")}
                  </Label>
                  <AvRadioGroup inline name="title">
                    <Row>
                      <Col sm="12" lg="6">
                        <AvRadio label={this.props.t("Mr.")} value="Mr." />
                      </Col>
                      <Col sm="12" lg="6">
                        <AvRadio label={this.props.t("Mrs.")} value="Mrs." />
                      </Col>
                    </Row>
                  </AvRadioGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm="12" lg={hideOrgInfo ? '6' : '4'}>
                <Label className="form-label" htmlFor="first_name">
                  {this.props.t("First name")}
                </Label>
                <ExtendableAvField
                  name="first_name"
                  placeholder=""
                  type="text"
                  errorMessage={this.props.t("This field cannot be blank")}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="first_name"
                />
              </Col>
              <Col sm="12" lg={hideOrgInfo ? '6' : '4'}>
                <Label className="form-label" htmlFor="last_name">
                  {this.props.t("Last name")}
                </Label>
                <ExtendableAvField
                  name="last_name"
                  placeholder=""
                  type="text"
                  errorMessage={this.props.t("This field cannot be blank")}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="last_name"
                />
              </Col>
              {!hideOrgInfo && (
                <Col sm="12" lg="4">
                    <Label className="form-label" htmlFor="organization">
                    {this.props.t("Organization")}
                    </Label>
                    <ExtendableAvField
                    name="organization"
                    placeholder=""
                    type="text"
                    errorMessage={this.props.t("This field cannot be blank")}
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="organization"
                    />
                </Col>
              )}
            </Row>
            {!hideOrgInfo && (
                <Row className='mb-3'>
                    <Col sm="12" lg="12">
                        <div className="mb-3">
                        <Label className="form-label" htmlFor="Position">
                            {this.props.t("Position")}
                        </Label>
                        <ExtendableAvField
                            name="position"
                            placeholder=""
                            type="text"
                            errorMessage={this.props.t("This field cannot be blank")}
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="Position"
                        />
                        </div>
                    </Col>
                </Row>
            )}
          </Col>
        </Row>
      );
    }
    return null;
  }

  displayUserInfosFieldsValues() {
    if (
      (!this.props.user || this.userUtils.isThirdParty(this.props.user)) ===
        false ||
      this.state.isThirdPartyUser === true ||
      (this.userUtils.isAnalystOrAnalystAdmin(this.props.user) &&
        this.state.selectedAnalystReportingTypeOption === "other_one")
    ) {
        {/* 
            * SPIK-7479
            * This is an uggly way to handle this task. 
            * This part should be changed in the future after handling it by the backend.
        */}
        const hideOrgInfo = this.props.modules.active === 'cm' && NotShowOrgInfosOrganizations.includes(this.props.Organization.id);

      return (
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="12">
                <Label className="form-label">{this.props.t("Source")}</Label>
                <p className="text-answers">
                  {this.state.submitData.source
                    ? this.props.t(this.state.submitData.source)
                    : this.props.t("Web")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Relation to company")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.relation_company
                    ? this.state.submitData.relation_company
                    : this.props.t(this.props.user.roles)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Salutation")} {this.props.t("(Optional)")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.title
                    ? this.props.t(this.state.submitData.title)
                    : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("First name")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.first_name
                    ? this.state.submitData.first_name
                    : this.props.user.first_name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Last name")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.last_name
                    ? this.state.submitData.last_name
                    : this.props.user.last_name}
                </p>
              </Col>
            </Row>
            {!hideOrgInfo && (
                <>
                    <Row>
                        <Col sm="12">
                            <Label className="form-label">
                            {this.props.t("Organization")}
                            </Label>
                            <p className="text-answers">
                            {this.state.submitData.organization}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Label className="form-label">{this.props.t("Position")}</Label>
                            <p className="text-answers">
                            {this.state.submitData.position
                                ? this.state.submitData.position
                                : this.props.user.position}
                            </p>
                        </Col>
                    </Row>
                </>
            )}
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderAnalystReportingTypeOptions() {
    if (this.userUtils.isAnalystOrAnalystAdmin(this.props.user)) {
      return (
        <Row>
          <Col sm="12" lg="9">
            <div className="mb-3">
              <Label className="form-label">
                {this.props.t("Reprting type")}
              </Label>
              <AvRadioGroup
                defaultValue={this.state.selectedAnalystReportingTypeOption}
                name="analyst_admin_reporting_type"
                validate={{ required: { value: true } }}
                errorMessage={this.props.t("This field cannot be blank")}
              >
                <Row>
                  <Col sm="6">
                    <AvRadio
                      onChange={() =>
                        this.setState({
                          selectedAnalystReportingTypeOption: "myself",
                          selectedAnalystReportingTypeOptionValue: "myself",
                        })
                      }
                      label={this.props.t("For myself")}
                      value="myself"
                    />
                  </Col>
                  <Col sm="6">
                    <AvRadio
                      onChange={() =>
                        this.setState({
                          selectedAnalystReportingTypeOption: "other_one",
                          selectedAnalystReportingTypeOptionValue: "other_one",
                        })
                      }
                      label={this.props.t("For other")}
                      value="other_one"
                    />
                  </Col>
                </Row>
              </AvRadioGroup>
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  }

  scrollToInvalidField(fieldRef) {
    const fieldRect = fieldRef.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetTop = fieldRect.top + scrollTop - 100; // Adjust the scroll offset as needed

    window.scrollTo({
      top: targetTop,
      behavior: "smooth",
    });
  }

  handleClickViewFiles = (type) => {
    var xhr = new XMLHttpRequest();
    const selectedLang = getCurrentLanguage();
    const orgId = Number(localStorage.getItem("orgId"));
    const languageId = Number(
      this.props?.Organization?.languages.filter(
        (lang) => lang.language_code === selectedLang
      )[0]?.language?.id
    );

    const queryParams = {
      language: languageId,
      organization: orgId,
      type: type,
    };

    const queryString = Object.keys(queryParams)
      .map((key) => key + "=" + queryParams[key])
      .join("&");

    const token = `Bearer ${this.props.token}`;
    const url = `${AUTH_API_DOMAIN_URL}user_manual/download?${queryString}`;
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        window.open(link);
      }
    };
    xhr.send();
  };

  renderLegalQuestion(question) {
    if(question.after_login === 'true' && !this.state.statusLogin) return null;
    return (
      <Row key={question.id}>
          <Col sm="12">
              <div className="mb-3">
                  <Label
                      className="form-label"
                      htmlFor="legal-entities"
                  >
                      {this.props.t(question.title)}
                  </Label>

                  <MultiSelect
                      placeholder={this.props.t("Select") + "..."}
                      selectedValues={this.state.selectedLegalEntities}
                      options={this.state.legalEntities}
                      onChange={(e) => this.setState({
                        ...this.state,
                        selectedLegalEntities: e
                      }) }
                  />

                  <AvField
                    multiple
                    id="legal-entities-hidden"
                    type="select"
                    name={`Questien[${question.id}]`}
                    value={this.state.selectedLegalEntities.map(item => item.id)} 
                    errorMessage={this.props.t("This field cannot be blank")}
                    className="form-control"
                    validate={{
                      required: {
                        value: question.isMandatory
                      }
                    }}
                    style={{ display: 'none' }} 
                  />
                
              </div>
          </Col>
      </Row>
    )
  }

  displayEnteredLegalEntities(question, answers) {
    if(question.after_login === 'true' && !this.state.statusLogin) return null;

    return (
        <Row>
            <Col sm="12">
                <Label className="form-label">
                    {this.props.t(question.title)}
                </Label>
                <Row>
                    {(answers || []).map((answerId) => {
                        const answerTitle = this.state.legalEntities.find((legal) => legal.id === parseInt(answerId))?.title
                        return (
                            <Col sm="12" md="6" key={answerId}>
                                <p className="text-dark">
                                    <i className="fa fa-check"></i>
                                    {" " +
                                    this.props.t(answerTitle)}
                                </p>
                            </Col>
                        )}
                    )}
                </Row>
            </Col>
        </Row>
    );
  }

  fetchCaptcha = () => {
    axios
      .get(API_URL_FETCH_CAPTCHA, {
        responseType: "json", 
        withCredentials: true,
      })
      .then((response) => {
        if (response && response.data && response.data.captcha) {
          this.setState({
            captchaFetched: true,
            captchaSrc: response.data.captcha, 
          });
        } else {
          console.error("Invalid response or empty captcha field.");
        }
      })
      .catch((error) => {
        console.error("Error fetching captcha:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Report confidentially")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1,
                            })}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">
                              {this.props.t("Report")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2,
                            })}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">
                              {this.props.t("Review your report")}
                            </span>
                          </NavLink>
                        </NavItem>
                        {this.state.selectedAnalystReportingTypeOptionValue !==
                          "myself" && (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 3,
                              })}
                            >
                              <span className="step-number">03</span>
                              <span className="step-title">
                                {this.props.t("Follow-Up")}
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                this.state.activeTabProgress ===
                                (this.state
                                  .selectedAnalystReportingTypeOptionValue !==
                                "myself"
                                  ? 4
                                  : 3),
                            })}
                          >
                            <span className="step-number">
                              0
                              {this.state
                                .selectedAnalystReportingTypeOptionValue !==
                              "myself"
                                ? 4
                                : 3}
                            </span>
                            <span className="step-title">
                              {this.props.t("Finalize")}
                            </span>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                      </div>

                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.handleSubmit}
                            onInvalidSubmit={(e) => {
                              const result = [];
                              const invalidFields = Object.keys(
                                e.target.elements
                              ).map((fieldName) => {
                                if (
                                  e.target.elements[fieldName].validity &&
                                  !e.target.elements[fieldName].validity.valid
                                ) {
                                  result.push(fieldName);
                                  return (
                                    e.target.elements[fieldName].validity
                                      .valid === false
                                  );
                                }
                              });
                              if (result.length > 0) {
                                const firstInvalidField =
                                  e.target.elements[result[0]];
                                this.scrollToInvalidField(firstInvalidField);
                              }
                            }}
                            ref={(formRef) => {
                              this.formRef = formRef;
                            }}
                          >
                            {this.renderAnalystReportingTypeOptions()}
                            {this.renderUserInfosFields()}
                            <Row>
                              <Col sm="12" lg="9">
                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="description"
                                        >
                                          {this.props.modules.active === "wb"
                                            ? this.props.t(
                                                "Brief Description of Incident"
                                              )
                                            : this.props.t(
                                                "Title of your complaint"
                                              )}
                                        </Label>
                                        <ExtendableAvField
                                          name="description"
                                          placeholder=""
                                          type="text"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="description"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                }

                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor={`question-category`}
                                        >
                                          {this.props.t(
                                            this.state.categoryQuestion || "In your opinion, the facts of the case fall into the following category:"
                                          )}
                                        </Label>
                                        <AvCheckboxGroup
                                          name="Question_Category"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        >
                                          <Row>
                                            {this.state.categories.map(
                                              (category, catIndex) => {
                                                return (
                                                  <Col
                                                    sm="12"
                                                    md="6"
                                                    key={category.id + catIndex}
                                                  >
                                                    <AvCheckbox
                                                      label={this.props.t(
                                                        category.title
                                                      )}
                                                      value={category.id}
                                                    />
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </AvCheckboxGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                }
                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="locations"
                                        >
                                            {this.props.t(this.props.modules.active === "wb" ? "Which country is affected?" : "Your location")}
                                        </Label>
                                        <AvField
                                          id="locations"
                                          type="select"
                                          name="locations"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          defaultValue={
                                            this.state.organizationCountry
                                          }
                                          value={this.state.organizationCountry}
                                        >
                                          {this.sortLocationsByLanguage(
                                            getCurrentLanguage(),
                                            this.state.locations
                                          ).map((location) => {
                                            return (
                                              <option
                                                key={location.id}
                                                value={location.id}
                                              >
                                                {this.props.t(location.name)}
                                              </option>
                                            );
                                          })}
                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                }

                                {this.state.questions.map((question, index) => {
                                  const id = question.id;
                                  const title = question.title || "";
                                  const type = question.type;
                                  const afterLogin = question.after_login;
                                  const isMandatory = question?.isMandatory;
                                  const key = id + index;
                                  if (
                                    type === "textarea" ||
                                    type === "text" ||
                                    type === "date"
                                  ) {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <ExtendableAvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <ExtendableAvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <ExtendableAvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state
                                        .selectedAnalystReportingTypeOption ===
                                        "other_one" &&
                                      (this.userUtils.isAnalystOrAnalystAdmin(
                                        this.props.user
                                      ) ||
                                        this.userUtils.isAnalystAdmin(
                                          this.props.user
                                        ))
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <ExtendableAvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else if (type === "file") {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <Dropzone
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFiles(
                                                      acceptedFiles,
                                                      id
                                                    )
                                                  }
                                                  accept={
                                                    this.dropzoneAcceptedFiles
                                                      .accepted
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>
                                                          {this.props.t(
                                                            "Drop files here or click to upload"
                                                          )}
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <Button
                                                  id="dropzone-popover"
                                                  className="button"
                                                  type="button"
                                                  color="link"
                                                  style={{
                                                    position: "absolute",
                                                    right: 8,
                                                    bottom: 6,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <i
                                                    className="ri-information-line"
                                                    style={{ fontSize: 18 }}
                                                  ></i>
                                                </Button>
                                                <Popover
                                                  placement="bottom"
                                                  target="dropzone-popover"
                                                  isOpen={
                                                    this.state.popover.id ===
                                                      "dropzone-popover" &&
                                                    this.state.popover.active
                                                  }
                                                  toggle={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    {this.props.t(
                                                      "Allowed Formats"
                                                    )}
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    <span>
                                                      {this.props.t(
                                                        "You are allowed to upload limited file types. for more information, visit"
                                                      )}{" "}
                                                    </span>
                                                    <a
                                                      href="https://diss-co.tech/faq-diss-co/"
                                                      target="_blank"
                                                    >
                                                      {this.props.t("FAQ page")}
                                                    </a>
                                                  </PopoverBody>
                                                </Popover>
                                              </div>
                                              <br />
                                              <Progress
                                                color="info"
                                                striped
                                                animated
                                                value={100}
                                                hidden={!this.state.showProg}
                                              >
                                                {this.props.t(
                                                  "Please wait while the file is uploaded"
                                                )}
                                              </Progress>
                                              <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                              >
                                                {this.state.uploadedFiles.map(
                                                  (f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>
                                                                  {
                                                                    f.formattedSize
                                                                  }
                                                                </strong>
                                                              </p>
                                                            </Col>
                                                            <Col
                                                              xs={2}
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() =>
                                                                  this.handleClickDeleteFiles(
                                                                    f
                                                                  )
                                                                }
                                                              >
                                                                X
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <Dropzone
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFiles(
                                                      acceptedFiles,
                                                      id
                                                    )
                                                  }
                                                  accept={
                                                    this.dropzoneAcceptedFiles
                                                      .accepted
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>
                                                          {this.props.t(
                                                            "Drop files here or click to upload"
                                                          )}
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <Button
                                                  id="dropzone-popover"
                                                  className="button"
                                                  type="button"
                                                  color="link"
                                                  style={{
                                                    position: "absolute",
                                                    right: 8,
                                                    bottom: 6,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <i
                                                    className="ri-information-line"
                                                    style={{ fontSize: 18 }}
                                                  ></i>
                                                </Button>
                                                <Popover
                                                  placement="bottom"
                                                  target="dropzone-popover"
                                                  isOpen={
                                                    this.state.popover.id ===
                                                      "dropzone-popover" &&
                                                    this.state.popover.active
                                                  }
                                                  toggle={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    {this.props.t(
                                                      "Allowed Formats"
                                                    )}
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    <span>
                                                      {this.props.t(
                                                        "You are allowed to upload limited file types. for more information, visit"
                                                      )}{" "}
                                                    </span>
                                                    <a
                                                      href="https://diss-co.tech/faq-diss-co/"
                                                      target="_blank"
                                                    >
                                                      {this.props.t("FAQ page")}
                                                    </a>
                                                  </PopoverBody>
                                                </Popover>
                                              </div>
                                              <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                              >
                                                {this.state.uploadedFiles.map(
                                                  (f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>
                                                                  {
                                                                    f.formattedSize
                                                                  }
                                                                </strong>
                                                              </p>
                                                            </Col>
                                                            <Col
                                                              xs={2}
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() =>
                                                                  this.handleClickDeleteFiles(
                                                                    f
                                                                  )
                                                                }
                                                              >
                                                                X
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <Dropzone
                                                  onDrop={(acceptedFiles) =>
                                                    this.handleAcceptedFiles(
                                                      acceptedFiles,
                                                      id
                                                    )
                                                  }
                                                  accept={
                                                    this.dropzoneAcceptedFiles
                                                      .accepted
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>
                                                          {this.props.t(
                                                            "Drop files here or click to upload"
                                                          )}
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                <Button
                                                  id="dropzone-popover"
                                                  className="button"
                                                  type="button"
                                                  color="link"
                                                  style={{
                                                    position: "absolute",
                                                    right: 8,
                                                    bottom: 6,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <i
                                                    className="ri-information-line"
                                                    style={{ fontSize: 18 }}
                                                  ></i>
                                                </Button>
                                                <Popover
                                                  placement="bottom"
                                                  target="dropzone-popover"
                                                  isOpen={
                                                    this.state.popover.id ===
                                                      "dropzone-popover" &&
                                                    this.state.popover.active
                                                  }
                                                  toggle={() =>
                                                    this.setState({
                                                      popover: {
                                                        id: "dropzone-popover",
                                                        active:
                                                          !this.state.popover
                                                            .active,
                                                      },
                                                    })
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    {this.props.t(
                                                      "Allowed Formats"
                                                    )}
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    <span>
                                                      {this.props.t(
                                                        "You are allowed to upload limited file types. for more information, visit"
                                                      )}{" "}
                                                    </span>
                                                    <a
                                                      href="https://diss-co.tech/faq-diss-co/"
                                                      target="_blank"
                                                    >
                                                      {this.props.t("FAQ page")}
                                                    </a>
                                                  </PopoverBody>
                                                </Popover>
                                              </div>
                                              <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                              >
                                                {this.state.uploadedFiles.map(
                                                  (f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>
                                                                  {
                                                                    f.formattedSize
                                                                  }
                                                                </strong>
                                                              </p>
                                                            </Col>
                                                            <Col
                                                              xs={2}
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() =>
                                                                  this.handleClickDeleteFiles(
                                                                    f
                                                                  )
                                                                }
                                                              >
                                                                X
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else if (type === "checkbox") {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvCheckboxGroup
                                                name={"Questien[" + id + "]"}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvCheckbox
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                          />
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvCheckboxGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvCheckboxGroup
                                                name={"Questien[" + id + "]"}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvCheckbox
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                          />
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvCheckboxGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvCheckboxGroup
                                                name={"Questien[" + id + "]"}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvCheckbox
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                          />
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvCheckboxGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state
                                        .selectedAnalystReportingTypeOption ===
                                        "other_one" &&
                                      (this.userUtils.isAnalystOrAnalystAdmin(
                                        this.props.user
                                      ) ||
                                        this.userUtils.isAnalystAdmin(
                                          this.props.user
                                        ))
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvCheckboxGroup
                                                name={"Questien[" + id + "]"}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvCheckbox
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                          />
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvCheckboxGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else if (type === "radiobox") {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />

                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />

                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />

                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state
                                        .selectedAnalystReportingTypeOption ===
                                        "other_one" &&
                                      (this.userUtils.isAnalystOrAnalystAdmin(
                                        this.props.user
                                      ) === true ||
                                        this.userUtils.isAnalystAdmin(
                                          this.props.user
                                        ) === true)
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: {
                                                    value: isMandatory,
                                                  },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />

                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="av_to_date"
                                                                placeholder=""
                                                                id="ava-to-date"
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                maxDate={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else if (type === "legal") {
                                    return this.renderLegalQuestion(question)
                                  }
                                  else {
                                    return null;
                                  }
                                })}

                                <Row>
                                  <Col sm="12 m-t-15">
                                    <Button
                                      color="btn btn-primary"
                                      type="submit"
                                      disabled={
                                        this.state.showProg ||
                                        (!this.state.consentChecked &&
                                          !this.userUtils.isAnalystOrAnalystAdmin(
                                            this.props.user
                                          ))
                                      }
                                      onClick={() => {
                                        return this.setState({
                                          popover: {
                                            id: "dropzone-popover",
                                            active: false,
                                          },
                                        });
                                      }}
                                    >
                                      {this.props.t("Next")}
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </TabPane>
                        <TabPane tabId={2}>
                          {this.state.submitData ? (
                            <div>
                              {this.displayUserInfosFieldsValues()}

                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.props.modules.active === "wb"
                                        ? this.props.t(
                                            "Brief Description of Incident"
                                          )
                                        : this.props.t(
                                            "Title of your complaint"
                                          )}
                                    </Label>
                                    <p className="text-answers">
                                      {this.state.submitData.description}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.props.t(
                                        this.state.categoryQuestion || "In your opinion, the facts of the case fall into the following category:"
                                      )}
                                    </Label>
                                    <Row>
                                      {this.state.submitData.category.map(
                                        (categoryId, catIndex) => {
                                          const category =
                                            this.state.categories[
                                              this.state.categories.findIndex(
                                                (item) => item.id === categoryId
                                              )
                                            ];
                                          return (
                                            <Col
                                              sm="12"
                                              md="6"
                                              key={categoryId + catIndex}
                                            >
                                              <p className="text-dark">
                                                <i className="fa fa-check"></i>
                                                {" " +
                                                  this.props.t(category.title)}
                                              </p>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              {this.displayEnteredWhitchLocations()}

                              <Row>
                                <Col sm="12">
                                  {this.state.questions.map(
                                    (question, index) => {
                                      const id = question.id;
                                      const title = question.title || "";
                                      const type = question.type;
                                      const key = id + index;
                                      const afterLogin = question.after_login;

                                      const userAnswers =
                                        this.findUserAnswer(question);
                                      if (
                                        type === "textarea" ||
                                        type === "text"
                                      ) {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "file") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "date") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "checkbox") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "radiobox") {
                                        const subQuestionInfos =
                                          this.findSubQuestion(id, userAnswers);

                                        let radioLabel = "";
                                        let dateLabel = "";
                                        if (subQuestionInfos) {
                                          if (subQuestionInfos.label) {
                                            radioLabel = subQuestionInfos.label;
                                          } else {
                                            if (
                                              Array.isArray(subQuestionInfos) &&
                                              subQuestionInfos.length > 0
                                            ) {
                                              radioLabel =
                                                subQuestionInfos[0]?.label;
                                              dateLabel =
                                                subQuestionInfos[1]?.date;
                                            }
                                          }
                                        }

                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          hidden={!radioLabel}
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          radioLabel
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>{dateLabel}</span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          hidden={!radioLabel}
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          radioLabel
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>{dateLabel}</span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          hidden={!radioLabel}
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          radioLabel
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>{dateLabel}</span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "legal") {
                                        return this.displayEnteredLegalEntities(question, userAnswers)
                                      }
                                      else {
                                        return null;
                                      }
                                    }
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12 m-t-15">
                                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li className="previous">
                                      <Button
                                        color="light"
                                        outline
                                        onClick={() =>
                                          this.toggleTabProgress(
                                            this.state.activeTabProgress - 1
                                          )
                                        }
                                      >
                                        {this.props.t("Back")}
                                      </Button>
                                    </li>
                                    <li className="next">
                                      <Button
                                        color="btn btn-primary"
                                        onClick={() =>
                                          this.toggleTabProgress(
                                            this.state.activeTabProgress + 1
                                          )
                                        }
                                      >
                                        {this.props.t("Next")}
                                      </Button>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                        </TabPane>
                        {this.state.displayFollowUp ? (
                          this.state.selectedAnalystReportingTypeOptionValue !==
                            "myself" && (
                            <TabPane tabId={3}>
                              <AvForm
                                hidden={!this.state.displayFollowUp}
                                className="needs-validation"
                                onValidSubmit={this.handleNewFollowUpForm}
                              >
                                {
                                  <>
                                    <Row>
                                      <Col sm="12" md="6" lg="4">
                                        <h5>{this.props.t("Follow-Up")}</h5>
                                        <p>
                                          {this.props.t(
                                            "If you would like a follow-up about your report in addition to being notified by your email, input your available times for a callback. Please make sure that the correct phone number is saved in your account settings."
                                          )}
                                        </p>
                                      </Col>
                                      <Col sm="12" md="6" lg="8">
                                        <Row>
                                          {this.renderRegisterForm()}
                                          <Col sm="12" className="mb-3">
                                            <div className="mb-3">
                                              <Row>
                                                {this.state
                                                  .selectedAnalystReportingTypeOption ===
                                                  "other_one" && (
                                                  <>
                                                    <Col
                                                      sm="6"
                                                      className="mb-3"
                                                    >
                                                      <Label
                                                        className="form-label"
                                                        htmlFor="email_other_one"
                                                      >
                                                        {this.props.t(
                                                          "Email address"
                                                        )}{" "}
                                                        {this.props.t(
                                                          "(Optional)"
                                                        )}
                                                      </Label>
                                                      <ExtendableAvField
                                                        name="email_other_one"
                                                        placeholder=""
                                                        type="email"
                                                        className="form-control"
                                                        validate={{
                                                          required: {
                                                            value: false,
                                                          },
                                                          email: true,
                                                        }}
                                                        errorMessage={this.props.t(
                                                          "This field is invalid"
                                                        )}
                                                        value={this.state?.submitData?.email_other_one}
                                                        onChange={(e) => {
                                                          return this.setState({
                                                            ...this.state,
                                                            submitData: {
                                                              ...this.state
                                                                .submitData,
                                                              email_other_one:
                                                                e.target.value,
                                                            },
                                                          });
                                                        }}
                                                        id="email_other_one"
                                                      />
                                                    </Col>
                                                    <Col
                                                      sm="6"
                                                      className="mb-3"
                                                    >
                                                      <Label
                                                        className="form-label"
                                                        htmlFor="phone"
                                                      >
                                                        {this.props.t(
                                                          "Phone number"
                                                        )}{" "}
                                                        {this.props.t(
                                                          "(Optional)"
                                                        )}
                                                      </Label>
                                                      <InputPhoneNumber
                                                        id="phone"
                                                        name="phone"
                                                        required={false}
                                                        className="form-control"
                                                        placeholder=""
                                                        errorMessage=""
                                                        onChange={(phone) =>
                                                          this.setState({
                                                            inputPhoneNumber:
                                                              phone,
                                                          })
                                                        }
                                                        value={
                                                          this.props.user &&
                                                          !this.userUtils.isAnalystOrAnalystAdmin(
                                                            this.props.user
                                                          )
                                                            ? this.props.user
                                                                .phone_number
                                                            : ""
                                                        }
                                                        validate={{
                                                          required: {
                                                            value: false,
                                                          },
                                                        }}
                                                      />
                                                     </Col>
                                                  </>
                                                )}

                                                {this.state
                                                  .isThirdPartyUserLogin ===
                                                  true && (
                                                  <>
                                                    {!this.props.user.email && (
                                                      <Col
                                                        sm="6"
                                                        className="mb-3"
                                                      >
                                                        <Label
                                                          className="form-label"
                                                          htmlFor="email_other_one"
                                                        >
                                                          {this.props.t(
                                                            "Email address"
                                                          )}{" "}
                                                          {this.props.t(
                                                            "(Optional)"
                                                          )}
                                                        </Label>
                                                        <ExtendableAvField
                                                          name="email_other_one"
                                                          placeholder=""
                                                          type="email"
                                                          className="form-control"
                                                          validate={{
                                                            required: {
                                                              value: false,
                                                            },
                                                            email: true,
                                                          }}
                                                          errorMessage={this.props.t(
                                                            "This field is invalid"
                                                          )}
                                                          id="email_other_one"
                                                          value={this.state?.submitData?.email}
                                                          onChange={(e) => {
                                                            this.setState({
                                                              ...this.state,
                                                              submitData: {
                                                                ...this.state
                                                                  .submitData,
                                                                email:
                                                                  e.target
                                                                    .value,
                                                              },
                                                            });
                                                          }}
                                                        />
                                                      </Col>
                                                    )}
                                                    {!this.props.user
                                                      .phone_number && (
                                                      <Col
                                                        sm="6"
                                                        className="mb-3"
                                                      >
                                                        <Label
                                                          className="form-label"
                                                          htmlFor="phone"
                                                        >
                                                          {this.props.t(
                                                            "Phone number"
                                                          )}{" "}
                                                          {this.props.t(
                                                            "(Optional)"
                                                          )}
                                                        </Label>
                                                        <InputPhoneNumber // This is the case
                                                          name="phone"
                                                          placeholder=""
                                                          errorMessage={this.props.t(
                                                            "This field cannot be blank"
                                                          )}
                                                          className="form-control"
                                                          validate={{
                                                            required: {
                                                              value: false,
                                                            },
                                                          }}
                                                          id="phone"
                                                        />
                                                      </Col>
                                                    )}
                                                  </>
                                                )}

                                                {this.state
                                                  .isEmployeeUserLogin ===
                                                  true && (
                                                  <>
                                                    {!this.props.user.email && (
                                                      <Col
                                                        sm="6"
                                                        className="mb-3"
                                                      >
                                                        <Label
                                                          className="form-label"
                                                          htmlFor="email_other_one"
                                                        >
                                                          {this.props.t(
                                                            "Email address"
                                                          )}{" "}
                                                          {this.props.t(
                                                            "(Optional)"
                                                          )}
                                                        </Label>
                                                        <ExtendableAvField
                                                          name="email_other_one"
                                                          placeholder=""
                                                          type="email"
                                                          className="form-control"
                                                          validate={{
                                                            required: {
                                                              value: false,
                                                            },
                                                            email: true,
                                                          }}
                                                          errorMessage={this.props.t(
                                                            "This field is invalid"
                                                          )}
                                                          id="email_other_one"
                                                        />
                                                      </Col>
                                                    )}
                                                    {!this.props.user
                                                      .phone_number && (
                                                      <Col
                                                        sm="6"
                                                        className="mb-3"
                                                      >
                                                        <Label
                                                          className="form-label"
                                                          htmlFor="phone"
                                                        >
                                                          {this.props.t(
                                                            "Phone number"
                                                          )}{" "}
                                                          {this.props.t(
                                                            "(Optional)"
                                                          )}
                                                        </Label>
                                                        <PhoneNumber
                                                          name="phone"
                                                          placeholder=""
                                                          errorMessage={this.props.t(
                                                            "This field cannot be blank"
                                                          )}
                                                          className="form-control"
                                                          validate={{
                                                            required: {
                                                              value: false,
                                                            },
                                                          }}
                                                          id="phone"
                                                        />
                                                      </Col>
                                                    )}
                                                  </>
                                                )}
                                              </Row>
                                              <Label className="form-label">
                                                {this.props.t(
                                                  "I am available during the following times for a callback"
                                                )}{" "}
                                                {this.props.t("(Optional)")}
                                              </Label>
                                              <AvCheckboxGroup name="phone_available_time">
                                                <Row>
                                                  {this.state.phoneAvailableTimes.map(
                                                    (phoneAvTime) => {
                                                      return (
                                                        <Col
                                                          key={
                                                            phoneAvTime.optionId
                                                          }
                                                          sm="12"
                                                          md="6"
                                                        >
                                                          <AvCheckbox
                                                            label={
                                                              phoneAvTime.value
                                                            }
                                                            value={
                                                              phoneAvTime.optionId
                                                            }
                                                            onChange={(e) =>
                                                              this.handleAvaTimeChange(
                                                                e,
                                                                phoneAvTime
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvCheckboxGroup>
                                              <Row>
                                                <Col sm="12" className="mt-3">
                                                  {this.state
                                                    .showCustomAvaTime ? (
                                                    <ExtendableAvField
                                                      type="text"
                                                      validate={{
                                                        required: {
                                                          value:
                                                            this.state
                                                              .showCustomAvaTime,
                                                        },
                                                      }}
                                                      name="custom"
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          userCustomAvaTime:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  ) : null}
                                                </Col>
                                              </Row>

                                              <Row>
                                                <hr></hr>
                                              {this.state.selectedAuthType === "register" && !this.props.user ? (   
                                                <>           
                                                  <Col sm="12">
                                                      <Input 
                                                        type="checkbox" 
                                                        checked={this.state.regConsent} 
                                                        onChange={() => this.setState({regConsent: !this.state.regConsent})} 
                                                        required
                                                        className="me-2" />
                                                        { localStorage.getItem("i18nextLng") === "de" ? 
                                                          <Label>
                                                            {"Ich stimme den "}
                                                            <a href={`user_policy`} target="_blank">{"Nutzungsbedingungen"}</a> 
                                                            {" " + "und der" + " "}
                                                            <a href={`data_privacy`} target="_blank">{"Datenschutzerklärung"}</a>
                                                            {" zu."}
                                                            </Label>
                                                        : 
                                                        <Label>
                                                          {this.props.t("I agree to")} {" "}
                                                          <a href={`user_policy`} target="_blank">{this.props.t("User policy")}</a> 
                                                          {" " + this.props.t("and") + " "}
                                                          <a href={`data_privacy`} target="_blank">{this.props.t("Data privacy")}</a>
                                                          </Label>
                                                        
                                                        }
                                                    </Col> 
                                                    {/* Captcha */}
                                                      <Col sm="12">
                                                        {this.state.captchaFetched && (
                                                          <>
                                                          <div className="mt-3 mb-3">
                                                            <img className="me-4" src={this.state.captchaSrc} alt="Captcha" />
                                                            <i 
                                                            className="ri-restart-line ri-2x"
                                                            style={{ cursor: "pointer" }} 
                                                            onClick={this.fetchCaptcha}
                                                            ></i>
                                                          </div>
                                                            <AvField
                                                              name="captcha"
                                                              placeholder={this.props.t("Enter captcha")}
                                                              type="text"
                                                              className="form-control"
                                                              errorMessage={this.props.t("This field cannot be blank")}
                                                              validate={{
                                                                required: { value: true },
                                                              }}
                                                              onChange={(e) => this.setState({...this.state, captcha: e.target.value})}
                                                              id="captcha"
                                                            />
                                                          </>
                                                          
                                                        )}
                                                      </Col>
                                                </>
                                                    ) : null }
                                              </Row>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                <Progress
                                  bar
                                  animated
                                  color="info"
                                  value="100"
                                  hidden={!this.state.showProg}
                                >
                                  {this.props.t(
                                    "Please wait. We are processing your request."
                                  )}
                                </Progress>

                                <Row>
                                  <Col sm="12 m-t-15">
                                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                                      <li className="previous">
                                        <Button
                                          color="light"
                                          outline
                                          onClick={() =>
                                            this.toggleTabProgress(
                                              this.state.activeTabProgress - 1
                                            )
                                          }
                                        >
                                          {this.props.t("Back")}
                                        </Button>
                                      </li>
                                      <li className="next">
                                        <Button
                                          color="btn btn-primary"
                                          disabled={
                                            !this.state.displayButton
                                            ||
                                            (!this.props.user && this.state.selectedAuthType === "register" && !this.state.regConsent)
                                          }
                                          type="submit"
                                        >
                                          {this.props.t("Submit")}
                                        </Button>
                                      </li>
                                    </ul>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane>
                          )
                        ) : (
                          <p hidden={this.state.showAlertSuccess}>
                            {this.props.t(
                              "Thank you for your patience while your request is being processed."
                            )}
                          </p>
                        )}
                        <TabPane
                          tabId={
                            this.state
                              .selectedAnalystReportingTypeOptionValue !==
                            "myself"
                              ? 4
                              : 3
                          }
                        >
                          {this.state.tabs && this.state.tabs[4]
                            ? (this.state.tabs[4].active = true)
                            : null}
                          <Alert
                            color="success"
                            className="alert-dismissible fade show"
                            role="alert"
                            hidden={!this.state.showAlertSuccess}
                          >
                            <i className="mdi mdi-check-all me-2"></i>
                            <b>{this.props.t("Report Submitted")}</b>
                            <p>
                              {this.props.t(
                                "If you have any questions, please contact the technical support by sending an email to support@diss-co.tech."
                              )}
                            </p>
                            <p>
                              {this.props.t(
                                "If you receive error messages or have a technical questions, please make a screenshot of the error message or your view including the URL and send your request by email to support@diss-co.tech"
                              )}
                            </p>
                          </Alert>

                          <Row hidden={!this.state.showAlertSuccess}>
                            <Col sm="12 m-t-15">
                              <Link
                                to={
                                  this.getUserHome()
                                }
                              >
                                <Button
                                  color="secondary"
                                >
                                  {
                                    this.props.t("Back To reports")
                                  }
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <ConsentModal
            modal={this.state.showConsentModal}
            isTP={window.location.href.includes("thrid-party")}
            toggle={this.toggleConsentModal}
            consent={this.state.loginConsent}
            setConsent={() => this.setState({ loginConsent: !this.state.loginConsent })}
            loginData={this.props.token}
            doLogin={this.createReport}
        />
        </div>
      </React.Fragment>
    );
  }

  displayEnteredWhitchLocations() {
    const location = this.state.locations.find(
      (location) =>
        parseInt(this.state.submitData.locations) === parseInt(location.id)
    );
    if (location) {
      return (
        <Row>
          <Col sm="12">
            <Label className="form-label">
                {this.props.t(this.props.modules.active === "wb" ? "Which country is affected?" : "Your location")}
            </Label>
            <p className="text-answers">{this.props.t(location.name)}</p>
          </Col>
        </Row>
      );
    }
    return null;
  }

  fetchQuestions() {
    const url =
      !this.props.user || this.userUtils.isThirdParty(this.props.user)
        ? this.props.modules.active === "wb"
          ? API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION
          : CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION
        : this.props.modules.active === "wb"
        ? API_URL_QUESTION_FORM_ORGANIZATION
        : CM_API_URL_QUESTION_FORM_ORGANIZATION;

    return axios
      .post(
        url,
        {
          organization: this.urlUtils.getOrganizationId(),
          typeReport: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      )
      .then((response) => {
        const {
          legalEntities,
          questions,
          location,
          categoryQuestion,
          report_category: categories,
          organizationCountry: organizationCountry,
        } = response.data.data;
        this.setState({
          categoryQuestion: categoryQuestion,
          questions: questions && Array.isArray(questions) ? questions : [],
          categories: categories && Array.isArray(categories) ? categories : [],
          locations: location && Array.isArray(location) ? location : [],
          organizationCountry: organizationCountry,
          legalEntities: legalEntities && Array.isArray(legalEntities) ? legalEntities : [],
        });
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      });
  }

  // for fixing translation bugs
  phoneAvailableTimes() {
    this.setState({
      phoneAvailableTimes: [
        {
          optionId: "7_9",
          value: this.props.t("7am - 9am"),
        },
        {
          optionId: "9_12",
          value: this.props.t("9am - 12pm"),
        },
        {
          optionId: "12_3",
          value: this.props.t("12pm - 3pm"),
        },
        {
          optionId: "3_6",
          value: this.props.t("3pm - 6pm"),
        },
        {
          optionId: "6_8",
          value: this.props.t("6pm - 8pm"),
        },
        {
          optionId: "custom",
          value: this.props.t("Customize"),
        },
      ],
    });
  }

  sortLocationsByLanguage(language, locations) {
    if (locations && Array.isArray(locations)) {
      const collator = new Intl.Collator(language, {
        sensitivity: "base",
      });
      return locations.sort((a, b) =>
        collator.compare(this.props.t(a.name), this.props.t(b.name))
      );
    } else {
      return [];
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.reload) {
      this.setState({ displayButton: true, showProg: false, reload: false });
    }

    if (!prevProps.App.loaded && this.props.App.loaded) {
      this.statusLogin();
      this.fetchQuestions();
      this.phoneAvailableTimes();
      this.fetchReportCaseAfterLoginTherdParty();
    }

    // for fixing translation bugs
    if (this.props.t !== prevProps.t) {
      this.phoneAvailableTimes();

      this.setState({
        popover: {
          id: null,
          active: false,
        },
      });
    }
    if (
      this.state.selectedAuthType === "register" && 
      !this.state.captchaFetched &&
      !this.fetchingCaptchaInProgress &&
      this.state.activeTabProgress === 3
    ) {
      this.fetchingCaptchaInProgress = true;
      setTimeout(() => {
        this.fetchCaptcha();
        this.fetchingCaptchaInProgress = false; 
      }, 1500);
    }
  }

  componentDidMount() {
    this.phoneAvailableTimes();
    if (this.props.App.loaded) {
      this.statusLogin();
      this.fetchQuestions();
      this.fetchReportCaseAfterLoginTherdParty();

      if (
        this.props.user &&
        this.props.user.roles.length &&
        this.props.user.roles.includes("ROLE_ANALYST_ADMIN")
      ) {
        this.setState({
          selectedAnalystReportingTypeOptionValue: "myself",
        });
      } else {
        this.setState({
          selectedAnalystReportingTypeOptionValue: "other_one",
        });
      }
    } else {
      this.fetchQuestions();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App, Modules } = state;
  return { token, Organization, user, App, modules: Modules };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      logoutUser,
      errorNotification,
      successNotification,
      checkLogin,
      loginUserSuccessful,
      authCurrentUser,
      changeWorkspace,
      appError,
      appLoadStatusChanged,
      setUserAccessToken,
    })(ReportConfidently)
  )
);
