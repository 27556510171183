import { withRouter, useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useState, useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { connect } from "react-redux";
import OverviewHeader from "../../../../components/overview-header";

import { toast } from "react-toastify";
import DOMPurify from 'dompurify';

import SupplierOverviewService from "src/modules/3rd-party-management/apis/SupplierOverviewService";
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import SimpleBar from "simplebar-react";

import MessageBox from "src/modules/3rd-party-management/components/MessageBox";

import DateUtils from "src/services/utils/DateUtils";

import SupplierInternalCommunicationService from "src/modules/3rd-party-management/apis/SupplierInternalCommunicationService";

import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

import { 
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";

import {
    TasksStatuses
} from "src/modules/3rd-party-management/constants";


import {
    HistoryLogDescriptionCreator
} from "src/modules/3rd-party-management/services/HistoryLogDescriptionCreator";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const Overview = ({
    t,
    modules,
    supplierId
}) => {
    const location = useLocation();
    const history = useHistory();

    const [ riskLevels, setRiskLevels ] = useState(null);
    const [ tasks, setTasks ] = useState(null);
    const [ lastActivities, setLastActivities ] = useState(null);
    const [ latestFindings, setLatestFindings ] = useState([]);
    const [ mentionSuggestionList, setMentionSuggestionList ] = useState([]);

    const dateUtils = new DateUtils();

    const logDescriptionCreator = new HistoryLogDescriptionCreator();

    const {
        data: supplierInfos
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		}
	});

    const handleFetchStatisticsQuery = useQuery({
		queryKey: ["3rd-party-management-fetch-supplier-overview-statistics", supplierId],
		queryFn: async () => {
            const service = SupplierOverviewService.getInstance();

            return await service.fetchStatistics(supplierId);
		},
		cacheTime: 0,
        refetchOnWindowFocus: false,
		onError: (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching dashboard data."), {
                type: "error",
            });
		},
	});

    const handleFetchLastActivitiesQuery = useQuery({
		queryKey: ["3rd-party-management-fetch-supplier-overview-last-activities", supplierId],
		queryFn: async () => {
            const service = SupplierOverviewService.getInstance();

            return await service.fetchLastActivities(supplierId);
		},
		cacheTime: 0,
        refetchOnWindowFocus: false,
		onError: (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching dashboard data."), {
            type: "error",
            });
		},
	});

    const handleFetchLatestFindingsQuery = useQuery({
		queryKey: ["3rd-party-management-fetch-supplier-overview-latest-findings", supplierId],
		queryFn: async () => {
            const service = SupplierOverviewService.getInstance();

            return await service.fetchLatestFindings(supplierId);
		},
		cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching dashboard data."), {
                type: "error",
            });
		},
	});

    const handleFetchCommunicationMentionSuggestionListQuery = useQuery({
	    queryKey              : ["3rd-party-management-fetch-internal-communication-mention-suggestion-list-query", supplierId],
		queryFn               : async () => {
            const service = new SupplierInternalCommunicationService();

            return await service.mentionCommentSearch(supplierId, {
                query : ''
            });
		},
		cacheTime             : 0,
        refetchOnWindowFocus  : false,
		onError               : (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching analyst admins list."), {
                type: "error",
            });
		},
	});

    const renderRiskLevelChart = () => {
        if(handleFetchStatisticsQuery.isLoading || handleFetchStatisticsQuery.isFetching || !riskLevels){
            return (
                <>
                    <div className="dt-field dt-skeleton mb-2" style={{width: '30%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '90%', height: '320px'}}></div>
                </>
            );
        }

        const isOwnCompany = !!supplierInfos?.isOwnCompany;

        const chartOptions = {
            labels: [],
            plotOptions: {
                pie: {
                    donut: {
                        size: "75%",
                    }
                }
            },
            dataLabels: {
                enabled: true,
                dropShadow : false,
                formatter : (val, x) => {
                    return x.w.config.series[x.seriesIndex];
                }
            },
            legend: {
                show: true,
                position: 'bottom'
            },
            colors: []
        };

        const series = [];
        const labels = [];
        const colors = [];
        
        for(const riskKey in (riskLevels || {})){
            series.push(riskLevels[riskKey].amount);
            labels.push(t(riskLevels[riskKey].title));
            colors.push(`#${riskLevels[riskKey].color}`);
        }

        return (
            <Card>
                <CardBody>
                    <CardTitle className="overview-section-title mb-4">
                        {t(isOwnCompany ? 'Company Risk Level' : 'Third Party Risk Level')}
                    </CardTitle>

                    <ReactApexChart
                        options={{
                            ...chartOptions,
                            labels : labels,
                            colors : colors
                        }}
                        series={ series }
                        type="donut"
                        height="350px"
                    />
                </CardBody>
            </Card>
        );
	}

    const renderTasksChart = () => {
        if(handleFetchStatisticsQuery.isLoading || handleFetchStatisticsQuery.isFetching || !tasks){
            return (
                <>
                    <div className="dt-field dt-skeleton mb-2" style={{width: '30%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '90%', height: '320px'}}></div>
                </>
            );
        }

        const chartOptions = {
            labels: [],
            plotOptions: {
                pie: {
                    donut: {
                        size: "75%",
                    }
                }
            },
            dataLabels: {
                enabled: true,
                dropShadow : false,
                formatter : (val, x) => {
                    return x.w.config.series[x.seriesIndex];
                }
            },
            legend: {
                show: true,
                position: 'bottom'
            },
            colors: []
        };

        const series = [];
        const labels = [];
        const colors = [];

        for(const taskKey in (tasks || {})){
            if(TasksStatuses[taskKey]){
                series.push(parseInt(tasks[taskKey]));
                labels.push(t(TasksStatuses[taskKey]['title']));
                colors.push(TasksStatuses[taskKey]['color']);
            }
        }

        return (
            <Card>
                <CardBody>
                    <CardTitle className="overview-section-title mb-4">
                        {t('Tasks')}
                    </CardTitle>

                    <ReactApexChart
                        options={{
                            ...chartOptions,
                            labels : labels,
                            colors : colors
                        }}
                        series={ series }
                        type="donut"
                        height="350px"
                    />
                </CardBody>
            </Card>
        );
	}

    const renderRecentActivity = () => {
        if(handleFetchLastActivitiesQuery.isLoading || handleFetchLastActivitiesQuery.isFetching || !lastActivities){
            return (
                <>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '30%'}}></div>

                    <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                        <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                        <div className="w-100">
                            <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                            <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                        <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                        <div className="w-100">
                            <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                            <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                        <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                        <div className="w-100">
                            <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                            <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center flex-row justify-content-start mb-4">
                        <div className="dt-field dt-skeleton me-2" style={{width: '26px', borderRadius: '50%'}}></div>
                        <div className="w-100">
                            <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                            <div className="dt-field dt-skeleton" style={{width: '90%'}}></div>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <Card>
                <CardBody>
                    <CardTitle className="overview-section-title mb-4">
                        { t('Recent Activity') }
                    </CardTitle>
                    
                    <SimpleBar style={{ height: "318px" }}>
                        <ul className="list-unstyled activity-wid">
                            {
                                (lastActivities || []).map((activity) => {
                                    return (
                                        <li className="activity-list">
                                            <div className="activity-icon avatar-xs">
                                                <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                    <i className="ri-edit-2-fill"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <div>
                                                    <h5 className="font-size-13">
                                                        { dateUtils.convertDateToDate(activity.created_at, INTERNATIONAL_DATE_FORMAT) }
                                                        <small className="ml-3 text-muted">
                                                            {' '} { dateUtils.convertDateToDate(activity.created_at, 'HH:mm') }
                                                        </small>
                                                    </h5>
                                                </div>

                                                <div>
                                                    <p className="text-muted mb-0">
                                                        { t(logDescriptionCreator.createFromApiData(activity)) }
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </SimpleBar>
                </CardBody>
            </Card>
        )
    }

    const renderLatestFindings = () => {
        if(handleFetchLatestFindingsQuery.isLoading || handleFetchLatestFindingsQuery.isFetching){
            return (
                <>
                    <div className="dt-field dt-skeleton mb-2" style={{width: '20%'}}></div>
                    <Row>
                        <Col sm="12" md="3">
                            <div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
                        </Col>
                        <Col sm="12" md="3">
                            <div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
                        </Col>
                        <Col sm="12" md="3">
                            <div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
                        </Col>
                        <Col sm="12" md="3">
                            <div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
                        </Col>
                    </Row>
                </>
            );
        }

        if(!latestFindings.length) return null

        return (
            <Card className="latest-finding-section">
                <CardBody>
                    <CardTitle className="overview-section-title mb-4">
                        { t('Latest Findings') }
                    </CardTitle>

                    <Row>
                        {
                            latestFindings.map((finding) => {
                                return (
                                    <Col sm="12" md="3">
                                        <Card className="shadow-none mb-0">
                                            <CardImg top src={`/images/findings/${finding.image}.jpg`} alt={ finding.title } />

                                            <CardBody>
                                                <CardText className="mb-2">
                                                    <small className="category">
                                                        { finding.news_agency }
                                                    </small>
                                                </CardText>

                                                <CardTitle onClick={() => goToFindingDetailPage(finding)} className="d-flex align-items-center">
                                                    <i className="ri-checkbox-blank-circle-fill text-danger me-2"></i>
                                                    <p className="m-0 ellipsis l-3">{ finding.title }</p>
                                                    
                                                </CardTitle>

                                                <CardText 
                                                    className="mb-2 ellipsis l-3" 
                                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(finding.lead_text)}} 
                                                />

                                                <CardText className="mb-1">
                                                    <small className="last-update">{t('Last updated')} { formatDistanceToNow(new Date(finding?.release_date?.timestamp * 1000), { addSuffix: true }) }</small>
                                                </CardText>

                                                <div className="key-words">
                                                    <span className="badge me-1">{ finding?.keyword }</span>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </CardBody>
            </Card>
        );
    }

    const renderCommunicationBoxes = () => {
        const isOwnCompany = !!supplierInfos?.isOwnCompany;

        return (
            <Row>
                <Col sm="12" md={!isOwnCompany ? '6' : '12'}>
                    <MessageBox
                        settings={{
                            messageListUrl          :   '/supplier/comment/list_internal',
                            sendMessageUrl          :   '/supplier/comment/add_internal',
                            attachmentDownloadUrl   :   '/supplier/attachment/',
                            attachmentUploadUrl     :   '/supplier/attachment/upload',
                            attachmentDeleteUrl     :   '/supplier/attachment/',
                            supplierId              :   supplierId,
                            headerOptions           :   {
                                displayFilters  :   false,
                                title           :   isOwnCompany ? 'Communication' : 'Internal Communication'
                            },
                            mention                 :   {
                                enable          :   true,
                                suggestionList  :   mentionSuggestionList
                            }
                        }}
                    />
                </Col>

                {!isOwnCompany && (
                    <Col sm="12" md="6">
                        <MessageBox
                            settings={{
                                messageListUrl          :   '/supplier/comment/list',
                                sendMessageUrl          :   '/supplier/comment/add',
                                attachmentDownloadUrl   :   '/supplier/attachment/',
                                attachmentUploadUrl     :   '/supplier/attachment/upload',
                                attachmentDeleteUrl     :   '/supplier/attachment/',
                                supplierId              :   supplierId,
                                headerOptions           :   {
                                    displayFilters  :   false,
                                    title           :   'External Communication'
                                },
                                mention                 :   {
                                    enable          :   false
                                }
                            }}
                        />
                    </Col>
                )}
            </Row>
        )
    }

    const goToFindingDetailPage = ({
        id
    }) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', 'findings');
        searchParams.set('id', id);
        history.push({
            search : searchParams.toString()
        });
    }

    useEffect(() => {
        if(handleFetchStatisticsQuery.data){
            const score = parseInt(handleFetchStatisticsQuery.data?.score);
            setTasks(handleFetchStatisticsQuery.data?.task?.data);

            const riskRate = handleFetchStatisticsQuery.data?.riskRate || {};
            let riskRateSum = 0;
            const titles = {};

            Object.keys(riskRate).forEach((itemIndex) => {
                riskRateSum += parseInt(riskRate[itemIndex]?.amount);
                titles[riskRate[itemIndex]?.title] = itemIndex;
            });

            if(riskRateSum <= 0){
                const highestRisk = handleFetchStatisticsQuery.data.highestRisk;
                setRiskLevels({
                    ...riskRate,
                    [titles[highestRisk]]: {
                        ...riskRate[titles[highestRisk]],
                        amount : 1
                    }
                });

                // if(score <= 2){
                //     setRiskLevels({
                //         ...riskRate,
                //         [titles['Existence - threatening']] : {
                //             ...riskRate[titles['Existence - threatening']],
                //             amount : 1
                //         }
                //     });
                // }
                // else if(score <= 4){
                //     setRiskLevels({
                //         ...riskRate,
                //         [titles['Critical']] : {
                //             ...riskRate[titles['Critical']],
                //             amount : 1
                //         }
                //     });
                // }
                // else if(score <= 5){
                //     setRiskLevels({
                //         ...riskRate,
                //         [titles['Noticeable']] : {
                //             ...riskRate[titles['Noticeable']],
                //             amount : 1
                //         }
                //     });
                // }
                // else if(score <= 8){
                //     setRiskLevels({
                //         ...riskRate,
                //         [titles['Minor']] : {
                //             ...riskRate[titles['Minor']],
                //             amount : 1
                //         }
                //     });
                // }
                // else{
                //     setRiskLevels({
                //         ...riskRate,
                //         [titles['Insignificant']] : {
                //             ...riskRate[titles['Insignificant']],
                //             amount : 1
                //         }
                //     });
                // }
            }
            else{
                setRiskLevels(riskRate);
            }
            
        }
    }, [ handleFetchStatisticsQuery.data ]);

    useEffect(() => {
        if(handleFetchLastActivitiesQuery.data && Array.isArray(handleFetchLastActivitiesQuery.data)){
            setLastActivities(handleFetchLastActivitiesQuery.data);
        }
    }, [ handleFetchLastActivitiesQuery.data ]);

    useEffect(() => {
        if(handleFetchLatestFindingsQuery.data && Array.isArray(handleFetchLatestFindingsQuery.data)){
            setLatestFindings(handleFetchLatestFindingsQuery.data);
        }
    }, [ handleFetchLatestFindingsQuery.data ]);

    useEffect(() => {
        const {
            data
        } = handleFetchCommunicationMentionSuggestionListQuery;

        if(data && Array.isArray(data)){
            const list = data.map((item) => {
            return {
                text    : item.full_name,
                url     : item.id,
                value   : item.full_name
            }
            });

            setMentionSuggestionList(list);
        }
    }, [ handleFetchCommunicationMentionSuggestionListQuery.data ]);

    useEffect(() => {
        if(modules.featuresLoadingStatus === 'loaded' && isFeatureEnabledForActiveModule('news_monitoring')){
            handleFetchLatestFindingsQuery.refetch();
        }
    }, [ modules.featuresLoadingStatus ]);

    return (
        <div className="p-4 overview-content">
            <Row>
                <Col sm="12" className="mb-4">
                    <OverviewHeader supplierId={ supplierId } settings={{
                        status  :   {
                            editable    :   true
                        },
                        analystAdmin    :   {
                            editable    :   true
                        },
                        flagging        :   {
                            editable    :   true
                        }
                    }} />
                </Col>

                <Col sm="12">
                    <Row>
                        <Col sm="12" md="4">
                            { renderRiskLevelChart() }
                        </Col>

                        <Col sm="12" md="4">
                            { renderTasksChart() }
                        </Col>

                        <Col sm="12" md="4">
                            { renderRecentActivity() } 
                        </Col>
                    </Row>
                </Col>

                {isFeatureEnabledForActiveModule('news_monitoring') && (
                    <Col sm="12">
                        { renderLatestFindings() }
                    </Col>
                )}

                <Col sm="12">
                    { renderCommunicationBoxes() }
                </Col>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { Modules } = state;

    return {
        modules    :   Modules
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(Overview)))
);