import { Button } from "reactstrap";

export const handleRenderReportStatus = ({
  status,
  id,
  updateUserStatusHandler,
  t,
}) => {
  const statusMapping = {
    ACTIVE: { color: "success", nextStatus: "INACTIVE", label: t("Active") },
    INACTIVE: { color: "danger", nextStatus: "ACTIVE", label: t("Inactive") },
  };

  const { color, nextStatus, label } = statusMapping[status] || {};
  return (
    <Button
      color={color}
      size="sm"
      onClick={() => updateUserStatusHandler(id, nextStatus)}
    >
      {label}
    </Button>
  );
};

