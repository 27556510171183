import { Redirect } from 'react-router-dom';
import VerticalLayout from '../components/VerticalLayout/';
import FullPageLayout from '../components/FullPageLayout';
import Profile from '../pages/Authentication/Profile';
import ChangePassword from '../pages/Authentication/ChangePassword';
import Home from '../pages/Admin/home';
import AdminReportingChannel from '../pages/Admin/ReportingChannel/index';
import EditUser from '../pages/Admin/administration/EditUser';
// import AddUser from '../pages/Admin/administration/AddUser';
import ADSettings from '../pages/Admin/administration/ADSettings';
import Customization from '../pages/Admin/administration/customization';
import PrivacyPolicy from '../pages/Admin/administration/PrivacyPolicy';
import QuestionnaireDesigner from '../pages/Admin/administration/QuestionnaireDesigner';
import IndexKanban from '../pages/Admin/KanbanBoard';
import Settings from '../pages/Admin/administration/Settings';
// import ChatPage from '../pages/Admin/chat';
import Dashboard from '../pages/Admin/dashboard';
import Cockpit from '../pages/Admin/dashboard/Cockpit';
import ReportAnonymously from '../pages/Reporting/ReportAnonymously';
import ReportConfidently from '../pages/Reporting/ReportConfidently';
import ReportDetails from '../pages/Reporting/ReportDetails';
import ReportList from '../pages/Reporting/ReportList';
import ElearningHome from '../pages/E-learning';
import ElearningDetail from '../pages/E-learning/ElearningDetail';
import TutorialDetail from '../pages/Tutorial/TutorialDetail';
import Documents from '../pages/Documents';
import TutorialAdmin from '../pages/Tutorial/TutorialAdmin';
import HardwareAssetsList from '../pages/Reporting/GDPRHardwareAssetsList';
import SoftwareAssetsList from '../pages/Reporting/GDPRSoftwareAssetsList';
import HistoryLogs from '../pages/Admin/administration/history-logs';
import HistoryLogDetailPage from '../pages/Admin/administration/history-logs/details';
import moduleSelection from '../pages/Authentication/module-selection';
import UserManual from  '../pages/UserManual.js';
import FAQ from  '../pages/Faq';
import policy from '../pages/Common/policy.js';
import PlatformUpdate from '../pages/PlatformUpdate/index.jsx';
import ClientManagement from '../pages/Admin/administration/ClientManagement.js';
import ClientDetails from '../pages/Admin/administration/ClientDetails.js';

import {
    DataProtectionAdminRoutes
} from 'src/modules/data-protection/exports';

import {
    TPDDAdminRoutes
} from 'src/modules/3rd-party-management/exports';

import {
    adminRoutes as riskManagementRoutes
} from '@smartintegrity/risk-management-module/dist';

import {
    adminRoutes as entityManagementRoutes
} from '@smartintegrity/entity-management-module/dist';
import ListUsers from 'src/pages/Admin/administration/user-management/ListUsers';
import AddUser from 'src/pages/Admin/administration/user-management/new-user/other-roles/AddUser';
import newUser from 'src/pages/Admin/administration/user-management/new-user';

const routes = [
	{
		path: '/admin/data_privacy',
		component: policy,
		layout: VerticalLayout,
		type: 'admin'
	},
	{
		path: '/admin/user_policy',
		component: policy,
		layout: VerticalLayout,
		type: 'admin'
	},
	{
		path: '/admin/administration/clients',
		component: ClientManagement,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/administration/client/:id',
		component: ClientDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report/:id/details',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report/case/:caseId',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/e-learning/:id/detail',
		component: ElearningDetail,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/dashboard',
		component: Dashboard,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/user_manual',
		component: UserManual,
		layout: VerticalLayout,
		type: 'admin'
	},
	{
		path: '/admin/faq',
		component: FAQ,
		layout: VerticalLayout,
		type: 'admin'
	},
	{
		path: '/admin/cockpit',
		component: Cockpit,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report/detail',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report-anonymously',
		component: ReportAnonymously,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report-confidentially',
		component: ReportConfidently,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/administration/history-logs/:id',
		component: HistoryLogDetailPage,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/administration/history-logs',
		component: HistoryLogs,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm', 'gd'],
	},
	{
		path: '/admin/administration/users/:id/edit',
		component: EditUser,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/users/add',
		component: newUser,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/users/ad-settings',
		component: ADSettings,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/users',
		component: ListUsers,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/customization',
		component: Customization,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/privacy-policy',
		component: PrivacyPolicy,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/settings',
		component: Settings,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/administration/questionnaire-designer',
		component: QuestionnaireDesigner,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/documents',
		component: Documents,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/kanban-board',
		component: IndexKanban,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/home',
		component: Home,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm']
	},
	{
		path: '/admin/assetslist/hardwares',
		component: HardwareAssetsList,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/assetslist/softwares',
		component: SoftwareAssetsList,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/reporting-channel',
		component: AdminReportingChannel,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/reports',
		component: ReportList,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	// {
	// 	path: '/admin/livechat',
	// 	component: ChatPage,
	// 	layout: VerticalLayout,
	// 	type: 'admin',
	// },
	{
		path: '/admin/profile/change-password',
		component: ChangePassword,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/profile',
		component: Profile,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/e-learning',
		component: ElearningHome,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/tutorial/:id/detail',
		component: TutorialDetail,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/tutorial',
		component: TutorialAdmin,
		layout: VerticalLayout,
		type: 'admin',
	},
	{
		path: '/admin/report/task/:id',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report/risk/:id',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/report/risk/:id',
		component: ReportDetails,
		layout: VerticalLayout,
		type: 'admin',
		modules: ['wb', 'cm'],
	},
	{
		path: '/admin/start',
		component: moduleSelection,
		layout: FullPageLayout,
		type: 'admin'
	},
	{
		path: '/admin/platform-updates',
		component: PlatformUpdate,
		layout: VerticalLayout,
		type: 'admin'
	},
    ...riskManagementRoutes,
    ...DataProtectionAdminRoutes,
    ...TPDDAdminRoutes,
    ...entityManagementRoutes,
	{
		path: ['/admin', '/admin/*'],
		component: () => <Redirect to='/admin/home' />,
		layout: VerticalLayout,
		type: 'admin',
	},
];

export default routes;
