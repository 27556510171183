import { withNamespaces } from "react-i18next";
import React from "react";
import OverviewHeaderContent from "src/modules/3rd-party-management/components/overview-header";


const OverviewHeader = (props) => {
   const { reportRequest, questionnaireTypes } = props;

    const renderContent = () => {
        const firstRowItems = [
            {
                title   :   'Report Title',
                value   :   reportRequest.label
            },
            {
                title   :   'Financial Year',
                value   :   reportRequest.financialYear
            },
            {
                title   :   'Report Type',
                value   :   questionnaireTypes[reportRequest.type].title
            },
        ];

        return (
            <OverviewHeaderContent items={[
                [...firstRowItems],
            ]} className="single-line-view mb-5" />
        )
    }


    return (
        <React.Fragment>
            {renderContent()}
        </React.Fragment>
    );
};

export default withNamespaces()(OverviewHeader);