import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Select from 'react-select';
import * as CurrencyFormat from 'react-currency-format';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
    Col,
    Row
} from "reactstrap";

import { Form } from "react-bootstrap";
import SupplierLegalEntityService from "src/modules/3rd-party-management/apis/SupplierLegalEntityService";

const NewLegalModal = ({
    t,
    lng,
    supplierId,
    show,
    toggle,
    onSuccess,
    entitiesOptions
}) => {
    const [ totalRevenue, setTotalRevenue ] = useState("");
    const [ financialYear, setFinancialYear ] = useState(null);
    const [ formValidated, setFormValidated ] = useState(false);
    const [ selectedLegalEntity, setSelectedLegalEntity ] = useState(null);
    const [ faildValidations, setFaildValidations ] = useState([]); 

    const {
        mutate: addConnectedLegal,
        isLoading: addLegalInprocess
    } = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierLegalEntityService.getInstance();

            return await service.addConnectedLegal(payload);
        },
        onSuccess: () => {
            toast(t("Legal entity successfully added to the list."), {
                type: "success",
            });

            onSuccess && onSuccess();
            toggle();
        },
        onError: () => {
            toast(t("An error occurred while adding new legal entity."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(!show){
            setSelectedLegalEntity(null);
            setFinancialYear(null);
            setFormValidated(false);
            setFaildValidations([]);
        }
    }, [show])

    const handleCreateRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated(true);

        const faildInputs = [];

        if(!selectedLegalEntity){
            faildInputs.push('entity');
        }

        if(!financialYear?.value){
            faildInputs.push('financialYear')
        }

        if(!totalRevenue){
            faildInputs.push('totalRevenue')
        }

        if(faildInputs.length > 0){
            setFaildValidations(faildInputs);
            return;
        }

        addConnectedLegal({
            supplier            :   supplierId,
            legalId             :   selectedLegalEntity.value,
            financialYear       :   financialYear.value,
            totalRevenue        :   totalRevenue
        });
    }

    const isLegalEntityInvalid = faildValidations.includes('entity') || (formValidated && !selectedLegalEntity);
    const financialYearIsInvalid = faildValidations.includes('financialYear') || (formValidated && !financialYear?.value)
    const totalRevenueIsInvalid = faildValidations.includes('totalRevenue') || (formValidated && !totalRevenue)

    return (
        <Modal size="md" isOpen={ show }>
            <Form noValidate onSubmit={handleCreateRequest} autocomplete="off">
                <ModalHeader className="border-0" toggle={ toggle }>
                    {t("Add A New Legal Entity")}
                </ModalHeader>
                
                <ModalBody>
                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="entity">
                                <Form.Label>
                                    { t('Legal Entity') }
                                </Form.Label>

                                <Select
                                    placeholder={t('Select...')}
                                    name="entity"
                                    classNamePrefix='select2-selection'
                                    options={ entitiesOptions }
                                    value={ selectedLegalEntity }
                                    onChange={(e) => {
                                        setSelectedLegalEntity(e);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'entity'), 1)
                                    }} 
                                />

                                <Form.Control.Feedback type="invalid" style={{ display: `${isLegalEntityInvalid ? 'block' : 'none'}`}}> 
                                    { t('Legal entity not selected') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="financialStartYear">
                                <Form.Label>
                                    { t('Financial Year') }
                                </Form.Label>
                                <Select
                                    placeholder={t('Select...')}
                                    name="financialYear"
                                    classNamePrefix="select2-selection"
                                    options={[
                                        { value: 2022, label: '2022' },
                                        { value: 2023, label: '2023' },
                                        { value: 2024, label: '2024' },
                                        { value: 2025, label: '2025' }
                                    ]}
                                    onChange={(e) => {
                                        setFinancialYear(e);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'financialYear'), 1)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" style={{ display: `${financialYearIsInvalid ? 'block' : 'none'}`}}>
                                    { t('Financial year not selected') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="totalRevenue">
                                <Form.Label>
                                    { t('Total Revenue') }
                                </Form.Label>

                                <CurrencyFormat 
                                    thousandSeparator={true} 
                                    prefix={'€ '} 
                                    decimalScale={2}
                                    customInput={Form.Control}
                                    onValueChange={({value}) => setTotalRevenue(value)}
                                />

                                <Form.Control.Feedback type="invalid" style={{ display: `${totalRevenueIsInvalid ? 'block' : 'none'}`}}>
                                    { t('Total revenue is required') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={ addLegalInprocess }> 
                        { t('Cancel') }
                    </Button>

                    <Button type="submit" color="primary" disabled={ addLegalInprocess }>
                        {
                            addLegalInprocess && (
                                <Spinner animation="border" variant="primary" size="sm"/>
                            )
                        }
                        { t('Add') }
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

export default withNamespaces()(NewLegalModal);