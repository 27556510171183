import React from "react";

import { Document, Page, Text, View, Font } from "@react-pdf/renderer";

import { withNamespaces } from "react-i18next";

import { 
    FONT_OPEN_SANS_BOLD, 
    FONT_OPEN_SANS_REGULAR
} from "src/components/constants";

import DateUtils from "src/services/utils/DateUtils";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { now } from "moment";

import {
    RisksRates
} from "src/modules/3rd-party-management/constants";

import FirstPageHeader from "./components/FirstPageHeader";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HandIcon from "./components/HandIcon";
import HTMLContent from "./components/HTMLContent";
import Sections from "./Sections";
import styles from "./Styles";
import {
    generateColor
} from "./helpers";

Font.register({
    family: "Open Sans",
    fonts: [{
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "italic",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "italic",
        },
    ],
});

const Supplier = (props) => {
	const { 
        t,
        organization,
        supplierData
    } = props;

	if (!supplierData) {
		return (
			<Document>
				<Page size="A4" style={styles.page1}></Page>
			</Document>
		);
	}

    const primaryColor = organization?.primaryColor || "#5664d2";
    
    const logo = {};
    
    if(organization.secondaryLogo){
        logo.src = organization.secondaryLogo;
        logo.size = {
            maxWidth: '200px',
            height: `${organization.secondaryLogoSize || 40}px` 
        }
    }
    else{
        logo.src = organization.primaryLogo;
        logo.size = {
            maxWidth: '240px',
            maxHeight: '120px',
            height: `${organization.primaryLogoSize || 40}px`
        }
    }

    const isOwnCompany = !!supplierData.supplierDetail?.isOwnCompany
	
    const dateUtils = new DateUtils();

    const renderGeneralInfosSection = () => {
        const {
            supplierDetail
        } = supplierData;

        return (
            <>
                <View style={styles.sections}>
                    <View style={styles.generalInfos}>
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Name')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.name}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('SIP ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.secondaryId}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Address')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(supplierDetail?.country)}, {supplierDetail?.city}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Phone')}:</Text>
                                <Text style={styles.generalInfosValue}></Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Website')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.websiteUrl}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Industry')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {(supplierDetail?.industries || []).map((industry) => industry.name).join(', ')}
                                </Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Employee Size')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.employeeSize}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Vat ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.vatNumber}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Overall Risk Rating')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(RisksRates[supplierDetail?.highestRisk?.toLowerCase()]?.title)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t(isOwnCompany ? 'Company Score' : 'Supplier Score')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.score}/10</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Last Update')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {supplierDetail?.lastUpdate && dateUtils.convertDateToDate(supplierDetail?.lastUpdate, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Report Printed')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {dateUtils.convertDateToDate(now(), INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Assigned Analyst')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {supplierDetail?.assignedAnalyst && (
                                        <>
                                            {`${supplierDetail.assignedAnalyst.firsName || ''} ${supplierDetail?.assignedAnalyst?.lastName || ''}`}
                                        </>
                                    )}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            
                <View style={styles.sectionSeparator}>
                    <Text>
                        {t('GENERAL INFORMATION')}
                    </Text>
                </View>
            
                <View style={styles.note}>
                    <HandIcon />
                    <Text style={styles.ml1}>
                        {t('This summary is created by an AI and may be incomplete.')}
                    </Text>
                </View>
            
                <View style={styles.sections}>
                    <View style={styles.aiGeneralDescription}>
                        <HTMLContent content={supplierDetail.description} />
                    </View>
                </View>
            </>
        )
    }

    styles.sectionSeparator.backgroundColor = generateColor(primaryColor, 15);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <FirstPageHeader 
                    startColor={primaryColor} 
                    stopColor={generateColor(primaryColor, 15)} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                    isOwnCompany={isOwnCompany}
                />

                <View>
                    {renderGeneralInfosSection()}
                </View>

                <Footer backgroundColor={primaryColor} />
            </Page>

            <Page size="A4" style={styles.page}>
                <Header 
                    startColor={primaryColor} 
                    stopColor={generateColor(primaryColor, 15)} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                />

                <View>
                    <Sections 
                        supplierData={supplierData}
                        noteBackgroundColor={styles.sectionSeparator.backgroundColor}
                    />
                </View>

                <Footer backgroundColor={primaryColor} />
            </Page>
        </Document>
    );
      
};


export default withNamespaces()(
    Supplier
);
