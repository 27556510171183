import {
    HiChevronDoubleDown,
    HiChevronDoubleUp,
    HiMenuAlt4
} from "react-icons/hi";

export const HistoryLogActions = {
	report      :   {   
        create                      :   'New report created',
		update_analyst              :   'Update Analyst',
		update_status               :   'Update Status',
		mark_as_delete              :   'Marked as delete',
		delete                      :   'Deleted',
		upload_attachment           :   'Uploaded attachment',
		download_user_attachment    :   'Download user attachment',
		download_analyst_attachment :   'Download analyst attachment',
		delete_attachment           :   'Delete Attachment',
		add_comment                 :   'Add Comment',
		add_internal_comment        :   'Add Internal Comment',
		update_analyst_readonly     :   'Read-Only Analysts',
		update_risk                 :   'Risk updated',
		link_case                   :   'Linked case updated',
		change_admin_description    :   'Admin description updated',
		change_admin_category       :   'Admin category updated',
		add_link_case               :   "Linked case added",
		remove_link_case            :   "Linked case removed",
	},
	risk        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New risk created',
		update_description  :   'Description updated',
		delete              :   'Deleted',
		assign_analyst      :   'Assigned to analyst',
		update_damage       :   'Damage updated',
		update_likelihood   :   'Likelihood updated',
		update_category     :   'Category updated',
		change_description  :   'Description updated by admin',
		change_category     :   'Category updated by admin',
		create_ai           :   'New Ai risk created',
	},
	task        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New task created',
		assign_analyst      :   'Assigned to analyst',
		update_description  :   'Description updated',
		update_status       :   'Status updated',
		update_priority     :   'Priority updated',
		update_deadline     :   'Deadline updated',
		update_category     :   'Category updated',
		delete              :   'Deleted',
	},
	admin       :   {
		change_active_directory_settings    :   'Active directory settings',
		edit_risk_matrix                    :   'Update matrix',
		restore_risk_matrix                 :   'Restore matrix',
		edit_user                           :   'Edit user',
		create_user                         :   'Created user',
		delete_user                         :   'Deleted user',
		change_user_status                  :   'Changed user status',
		edit_organization                   :   'Edited organization',
		update_question                     :   'Updated question',
		remove_question                     :   'Removed question',
		remove_category                     :   'Removed category',
		login                               :   'Login',
	},
	document    :   { 
        create  :   'Created', 
        remove  :   'Removed' 
    },
	user        :   { 
        approveConsent  : "Approve Consent",
		edit_tag        :  "Update keyword"  
    },
    supplier    :   {
        'onboard'           :   'New supplier created',
        'first_assessment'  :   'First assessment based on basic information',
        'new_report'        :   'New report created'
    },
    ownCompany     :   {
        'onboard_company'    :   'New company created',
        'first_assessment'  :   'First assessment based on basic information',
        'new_report'        :   'New report created'
    }
};

export const HistoryLogSections = {
	risk        :   'Risk Management',
	task        :   'Task Management',
	admin       :   'Administration',
	report      :   'Report',
	document    :   'Document Management',
	user        :   "User",
    supplier    :   'Supplier',
    ownCompany  :   'Company'
};

export const HistoryLogRoles = {
	ROLE_ANALYST_ADMIN  :   'Analyst admin',
	ROLE_ANALYST        :   'Analyst',
	ROLE_TASK_OWNER     :   'Task Owner',
	ROLE_THIRD_PARTY    :   'Third Party',
	ROLE_EMPLOYEE       :   'Employee'
};

export const TasksStatuses = {
	NEW				:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	IN_PROGRESS		:	{
		title	:	'In Progress',
		color	:	'#8792E8'
	},
	REVIEW			:	{
		title	:	'Review',
		color	:	'#85FF8A'
	},
	CLOSED			:	{
		title	:	'Closed',
		color	:	'#EDECEC'
	},
	DELETED			:	{
		title	:	'Deleted',
		color	:	'#FFDADA'
	}
}

export const TasksPriorities = {
	low		:	{
		title	:	"Low",
		color	:	"#1CBB8C",
		icon	:	<HiChevronDoubleDown />
	},
	medium	:	{
		title	:	"Medium",
		color	:	"#FCC34B",
		icon	:	<HiMenuAlt4 />
	},
	high	:	{
		title	:	"High",
		color	:	"#DC3545",
		icon	:	<HiChevronDoubleUp />
	}
};

export const ReportsStatuses = {
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Open',
		color	:	'#85FF8A'
	},
	2	:	{
		title	:	'Assigned',
		color	:	'#F693FF'
	},
	3	:	{
		title	:	'In Progress',
		color	:	'#8792E8'
	},
	4	:	{
		title	:	'Closed',
		color	:	'#EDECEC'
	},
	5	:	{
		title	:	'Pending Deletion',
		color	:	'#FFDADA'
	},
	6	:	{
		title	:	'Deleted',
		color	:	'#FFDADA'
	},
}

export const SuppliersStatuses = {					
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Under Review',
		color	:	'#74B8FF'
	},
	2	:	{
		title	:	'Approved',
		color	:	'#4CD4AC'
	},
	3	:	{
		title	:	'Rejected',
		color	:	'#BE4F59'
	}
}

export const CompanySizes = {
	"0-10"	:	{
		title	:	"0-10"
	},
	"10-50"	:	{
		title	:	"10-50"
	},
	"50-100"	:	{
		title	:	"50-100"
	},
	"100-500"	:	{
		title	:	"100-500"
	},
	"500-1000"	:	{
		title	:	"500-1000"
	},
	"1000-2000"	:	{
		title	:	"1000-2000"
	},
	"2000-5000"	:	{
		title	:	"2000-5000"
	},
	"+5000"		:	{
		title	:	"+5000"
	}
};

export const RisksRates = {
    "low": {
        title: "Low",
        color: "#1CBB8C"
    },
    "medium": {
        title: "Medium",
        color: "#FCC34B"
    },
    "high": {
        title: "High",
        color: "#DC3545"
    },

    
    "insignificant": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "minor": {
        title: "Minor",
        color: "#59C94F"
    },
    "noticeable": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "critical": {
        title: "Critical",
        color: "#FF4949"
    },
    "existence - threatening": {
        title: "Existence - threatening",
        color: "#822626"
    },
}

export const SupplierStatuses = {
    "new"   :   {
        title   :   "New",
        color   :   "bg-secondary"
    },
    "under review"    :   {
        title   :   "Under Review",
        color   :   "bg-info"
    },
    "approved"  :   {
        title   :   "Approved",
        color   :   "bg-success"
    },
    "rejected"  :   {
        title   :   "Rejected",
        color   :   "bg-danger"
    }
}

export const customFonts = [
    {
      style: "normal",
      family: "Inter",
      weight: 400,
    },
    {
      style: "normal",
      family: "Nunito",
      weight: 400,
    },
    {
      style: "normal",
      family: "Raleway",
      weight: 400,
    },
    {
      style: "normal",
      family: "Roboto Mono",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Serif Toto",
      weight: 400,
    },
    {
      style: "normal",
      family: "Kumbh Sans",
      weight: 400,
    },
    {
      style: "normal",
      family: "Padyakke Expanded One",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Sans Cherokee",
      weight: 400,
    },
  ];

export const DocumentsTypes = [
    {
        typeKey: 'regular',
        title: 'Other'
    },
    {
        typeKey: 'user_policy',
        title: 'User policy'
    },
    {
        typeKey: 'privacy_policy',
        title: 'Privacy policy'
    }
];

export const SuppliersScorings = {
    "highRisk": {
        title: "High Risk",
        color: "#822626"
    },
    "moderate": {
        title: "Moderate Risk",
        color: "#FF4949"
    },
    "average": {
        title: "Average",
        color: "#FFA049"
    },
    "lowRisk": {
        title: "Low Risk",
        color: "#59C94F"
    },
    "excellent": {
        title: "Excellent",
        color: "#4EB8B1"
    }
}

export const SupplierLatestReportsStatuses = {
    "submitted"   :   {
        title       :   "Submitted",
        color       :   "#d4edda",
        fontColor   :   "#155724" 
    },
    "not_requested"    :   {
        title       :   "Not Requested",
        color       :   "#f5f5f5",
        fontColor   :   "#6c757d"
    },
    "waiting"  :   {
        title       :   "Waiting",
        color       :   "#fff9db",
        fontColor   :   "#856404"
    },
    "expired"  :   {
        title       :   "Expired",
        color       :   "#f8d7da",
        fontColor   :   "#721c24"
    }
}

export const BAFAQuestionnaireTypes = {
    'short_tpdd_bafa': {
        title: 'Abbreviated Reporting Obligation',
        prefix: 'BAFA: ',
        shortTitle: 'Abbreviated'
    },
    'tpdd_bafa': {
        title: 'Full Reporting Obligation',
        prefix: 'BAFA: ',
        shortTitle: 'Full'
    },
    'tpdd': {
        title: 'Compliance Report',
        shortTitle: 'Compliance'
    }
};

/**
 * The colors are the keys that api returns
 */
export const ResidualRisksRates = {
    "4EB8B1": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "59C94F": {
        title: "Minor",
        color: "#59C94F"
    },
    "FFA049": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "FF4949": {
        title: "Critical",
        color: "#FF4949"
    },
    "822626": {
        title: "Existence - threatening",
        color: "#822626"
    }
}