export * from './Routes';
export * from './ApiRoutes';
export * from './TranslationKeys';
export * from './Messages';
export * from './Dates';
export * from './Common';

/**
 * SPIK-7479
 * This is an uggly way to handle this task. 
 * This part should be changed in the future after handling it by the backend.
 */
export const NotShowOrgInfosOrganizations = [
    6566
]