import { withNamespaces } from 'react-i18next'
import { useState } from 'react'
import { connect } from 'react-redux'
import { API_BASE_URL } from 'src/modules/3rd-party-management/constants'
import axios from 'axios'
import {
    Button
} from 'reactstrap'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'

const DownloadAttachmentsButton = ({ t, token, reportId }) => {
    const [templateDownloadOptions, setTemplateDownloadOptions] = useState({
        percent: 0,
        status: null,
        cancelToken: null
    })

    const handleDownloadTemplate = async () => {
        const cancelToken = axios.CancelToken.source()

        setTemplateDownloadOptions({
            percent: 0,
            status: 'downloading',
            cancelToken: cancelToken
        })

        let loadedBytes = 0

        try {
            const response = await axios.post(
                `${API_BASE_URL}/report.downloadUserAttachment`,
                {
                    "report_case_id": reportId
                },
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    onDownloadProgress: (progressEvent) => {
                        loadedBytes = progressEvent.loaded

                        const percentCompleted = Math.round(
                            (loadedBytes * 100) / progressEvent.total
                        )

                        setTemplateDownloadOptions((currentState) => {
                            return {...currentState, percent: percentCompleted}
                        })
                    },
                    cancelToken: cancelToken.token,
                }
            )
    
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: '*' })
            )

            const link = document.createElement('a')
    
            link.href = url
            link.setAttribute('download', `${reportId}.zip`)
    
            document.body.appendChild(link)
    
            link.click()
            link.remove()

            setTemplateDownloadOptions({
                percent: 0,
                status: 'finished',
                cancelToken: null
            })
        } 
        catch (error) {
            let status;
            if (axios.isCancel(error)) {
                status = 'canceled'
                toast(t('Download cancelled.'), {
                    type: 'success',
                })
            } 
            else {
                status = 'faild'
                toast(t('An error occurred while downloading the file.'), {
                    type: 'error'
                })
            }

            setTemplateDownloadOptions({
                percent: 0,
                status: status,
                cancelToken: null
            })
        }
    }

    return (
        <Button
            color='primary'
            outline
            className='me-2'
            onClick={handleDownloadTemplate}
        >
            {templateDownloadOptions.status === 'downloading' ? (
                <>
                    <Spinner
                        animation='border'
                        variant='primary'
                        size='sm'
                        className='me-1'
                    />
                    {t('Downloading')}{` ${templateDownloadOptions.percent}%`}
                    
                </>
            ) : (
                <>
                    <i className='ri-download-line align-middle me-2' />
                    {t(
                        'Download Attachments'
                    )}
                </>
            )}
        </Button>
    )
}

const mapToState = (state) => {
    const { token } = state.Login

    return {
        token
    }
}

export default withNamespaces()(
    connect(mapToState, {})(
        DownloadAttachmentsButton
    )
)