import { withRouter, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, memo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import OverviewHeader from "../../components/overview-header";

import {
    AnswerSheet,
    helpers
} from '@smartintegrity/questionnaire';

import {
    Col, Row, Button, Container, Modal, ModalHeader, ModalBody,
    Spinner
} from "reactstrap";

import SupplierReportsService from "src/modules/3rd-party-management/apis/thirdparty/SupplierReportsService";

import ReportDetailsPDF from './export/PDF';

import { pdf } from "@react-pdf/renderer";

import { saveAs } from "file-saver";

const ReportDetails = (props) => {
    const {
        t,
        organization
    } = props;

    const { id : reportId } = useParams();

    const [questions, setQuestions] = useState([]);

    const [answers, setAnswers] = useState([]);

    const [ exportPDFInprocess, setExportPDFInprocess ] = useState(false);

    const handleFetchSupplierReportDetailsQuery = useQuery({
		queryKey: ['3rd-party-management-thirdparty-fetch-supplier-report-details', reportId],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.details(reportId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});
		},
	});

    const {
        refetch: fetchReportPDFData
    } = useQuery({
		queryKey: ['3rd-party-management-thirdparty-create-report-pdf-details-query', reportId],
		queryFn: async () => {
            setExportPDFInprocess(true);
			const service = SupplierReportsService.getInstance();

            return await service.fetchPDFData(reportId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});

            setExportPDFInprocess(false);
		},
        onSuccess: async ({
            answers
        }) => {
            const fileName = `report-${reportId}.pdf`;
            const blob = await pdf( 
                <ReportDetailsPDF 
                    organization={organization} 
                    data={{
                        sections:  groupQuestionsBySection(answers)
                    }}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setExportPDFInprocess(false);
        }
	});

    useEffect(() => {
        if(handleFetchSupplierReportDetailsQuery.data?.qa){
            setAnswers(
                helpers.makeAnswersReadyForAnswerSheet(
                    handleFetchSupplierReportDetailsQuery.data?.qa, 
                    handleFetchSupplierReportDetailsQuery.data?.qa
                )
            );

            setTimeout(() => {
                setQuestions(helpers.makeQuestionsReadyForAnswerSheet(
                    handleFetchSupplierReportDetailsQuery.data?.qa,
                    true
                ))
            }, 1000);
        }
        
    }, [ handleFetchSupplierReportDetailsQuery.data ]);

    const groupQuestionsBySection = (questions) => {
        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    icon : question.icon,
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const renderLoadingSection = () => {
        return (
            <React.Fragment>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const renderExportPDFWaitingModal = () => {
        return (
            <Modal isOpen={ exportPDFInprocess } toggle={ () => setExportPDFInprocess(false) }>
                <ModalHeader toggle={ () => setExportPDFInprocess(false) }>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Third Party Report')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    {t('Exporting as PDF')}...
                </ModalBody>
            </Modal>
        )
    }

    const questionnaireIsLoading = (
        handleFetchSupplierReportDetailsQuery.isFetching || handleFetchSupplierReportDetailsQuery.isLoading   
    );

    return (
        <div className='page-content report-details-page'>
            <Container fluid>
                <Row>
                    <Col sm="12" className="mb-4">
                        <OverviewHeader className='tp-view'/>
                    </Col>
                </Row>
                {
                    questionnaireIsLoading ? (
                        renderLoadingSection()
                    ) : (
                        <Row>
                            <Col sm="12" className="d-flex flex-row-reverse justify-content-start align-items-center mb-3">
                                <Button color="primary" onClick={fetchReportPDFData}>
                                    { t('Download Report PDF') }
                                </Button>
                            </Col>

                            <Col sm="12">
                                <AnswerSheet 
                                    settings={{
                                        buttons	:	{
                                            back	:	{
                                                enabled	:	false
                                            },
                                            confirm	:	{
                                                enabled	:	false
                                            }
                                        }
                                    }}
                                    questions={ questions }
                                    answers={ answers }
                                />
                            </Col>

                            { renderExportPDFWaitingModal() }
                        </Row>
                    )
                }
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user : state.Login.user,
        organization : Organization,
        token
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(ReportDetails)))
);