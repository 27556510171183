import moment from "moment";
import { DATE_FORMAT_MONTH_YEAR } from "../../common/constants/";

export default class DateUtils {
    convertTimestamp(timestamp) {
        var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
        hh = d.getHours(),
        h = hh,
        min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
        ampm = "AM",
        time;

        if (hh > 12) {
        // h = hh - 12;
        // ampm = "PM";
        } else if (hh === 12) {
        h = 12;
        // ampm = "PM";
        } else if (hh === 0) {
        h = 12;
        }

        // ie: 2013-02-18, 8:35 AM
        time = dd + "/" + mm + "/" + yyyy + ", " + h + ":" + min;
        // + " " + ampm;

        return time;
    }

    convertTimestampToOnlyDate(timestamp) {
        var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
        time = dd + "/" + mm + "/" + yyyy;

        return time;
    }

    convertTimeStampToDate(timeStamp, dateFormat) {
        if (timeStamp && !isNaN(timeStamp)) {
        const newDateFormat = dateFormat ? dateFormat : DATE_FORMAT_MONTH_YEAR;
        return moment.unix(timeStamp).format(newDateFormat);
        }

        return timeStamp;
    }

    convertDateToTimeStamp(date) {
        if (date instanceof Date) {
        return Math.floor(date.getTime() / 1000);
        }

        return date;
    }

    convertDateToDate(date, dateFormat) {
        if (moment(date).isValid()) {
        const newDateFormat = dateFormat ? dateFormat : DATE_FORMAT_MONTH_YEAR;
        return moment(date).format(newDateFormat);
        }

        return date;
    }

    getCurrentDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    getCurrentDateInTimestamp = () => {
        return Math.floor(new Date().getTime() / 1000);
    };

    getDiffInDays = (date1, date2) => {
        const startDate = new Date(date1);
        const endDate = new Date(date2);

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        
        const oneDay = 1000 * 60 * 60 * 24;
        const differenceInMs = Math.abs(endDate.getTime() - startDate.getTime());
        const daysDifference = Math.floor(differenceInMs / oneDay);

        return Math.round(daysDifference);
    }

    isLeapYear = (yearDate) => {
        const year = (new Date(yearDate)).getFullYear();

        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    }
}
