import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import * as datePickerLocales from "date-fns/locale";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from 'react-select';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
    Col,
    Row
} from "reactstrap";

import { Form } from "react-bootstrap";

import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";
import { BAFAQuestionnaireTypes } from "src/modules/3rd-party-management/constants";

const NewReportRequestModal = ({
    t,
    lng,
    supplierId,
    show,
    toggle,
    onSuccess,
    supplierType
}) => {
    const twoWeeksLaterDate = new Date();
    twoWeeksLaterDate.setDate(twoWeeksLaterDate.getDate() + 14);

    const [ formValidated, setFormValidated ] = useState(false);
    const [ people, setPeople ] = useState([]);
    const [ deadlineDate, setDeadlineDate ] = useState(twoWeeksLaterDate);
    const [ financialYear, setFinancialYear ] = useState(null);
    const [ reportType, setReportType ] = useState(null);
    const [ person, setPerson ] = useState(null);
    const [ creationFormInProcess, setCreationFormInProcess ] = useState(false);
    const [ faildValidations, setFaildValidations ] = useState([]); 

    const dateUtils = new DateUtils();

    const handleFetchPeopleList = useQuery({
		queryFn: async () => {
			const service = SupplierContactPersonService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching contact list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateNewReportRequestMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            
            setCreationFormInProcess(true);

            return await service.createReportRequest(payload);
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("New report request created successfully."), {
                type: "success",
            });

            toggle();
        },
        onError: ({ response }) => {
            if(response.status === 400 && response.data?.error === 'already_has_open_request'){
                toast(t("An open request for this type of report already exists for the selected financial year. Requesting a new report is not allowed."), {
                    type: "error",
                });
            }
            else{
                toast(t("An error occurred while creating request."), {
                    type: "error",
                });
            }
        },
        onSettled: () => {
            setCreationFormInProcess(false);
        }
    });

    useEffect(() => {
        setPeople((handleFetchPeopleList?.data?.contactPersons || []).map((person) => {
            return {
                value   :   person.id,
                label   :   `${person.name} ${person.lastName}`
            }
        }));
    }, [ handleFetchPeopleList.data ]);

    useEffect(() => {
        if(!show){
            setPerson(null);
            setDeadlineDate(twoWeeksLaterDate);
            setFinancialYear(null);
            setFormValidated(false);
            setFaildValidations([]);
        }
    }, [show])

    const handleCreateRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated(true);

        const faildInputs = [];

        if(supplierType === 'own' && !reportType){
            faildInputs.push('reportType');
        }

        if(!person){
            faildInputs.push('person');
        }

        if(!deadlineDate){
            faildInputs.push('deadlineDate');
        }

        if(!financialYear){
            faildInputs.push('financialYear')
        }

        if(faildInputs.length > 0){ 
            setFaildValidations(faildInputs);
            return;
        }

        handleCreateNewReportRequestMutation.mutate({
            type                :   supplierType === 'own' ? reportType.value : 'tpdd',
            supplier            :   supplierId,
            responsiblePerson   :   person.value,
            deadlineDate        :   dateUtils.convertDateToDate(deadlineDate, 'YYYY-MM-DD'),
            financialYear    :   financialYear?.value
        });
    }

    const peopleListLoading = handleFetchPeopleList.isFetching || handleFetchPeopleList.isLoading;

    const isReportTypeInvalid = faildValidations.includes('reportType') || (formValidated && !reportType);
    const isResponsiblePersonInputInValid = faildValidations.includes('person') || (formValidated && !person);
    const isDeadlineInputInValid = faildValidations.includes('deadline') || (formValidated && !deadlineDate);
    const isFinancialYearInvalid = faildValidations.includes('financialYear') || (formValidated && !financialYear);

    const questionnaireTypesOptions = Object.keys(BAFAQuestionnaireTypes).map((typeKey) => {
        return {
            value: typeKey,
            label: `${BAFAQuestionnaireTypes[typeKey].prefix || ''}${t(BAFAQuestionnaireTypes[typeKey].title)}`
        }
    })

    return (
        <Modal size="md" isOpen={ show }>
            <Form noValidate onSubmit={handleCreateRequest} autocomplete="off">
                <ModalHeader className="border-0" toggle={ toggle }>
                    {t("Request A New Report")}
                </ModalHeader>
                
                <ModalBody>
                    {supplierType === 'own' && (
                        <Row className="mb-3">
                            <Col sm='12'>
                                <Form.Group controlId="reportType">
                                    <Form.Label>
                                        { t('Select Reporting Obligation Type') }
                                    </Form.Label>

                                    <Select
                                        placeholder={t('Select...')}
                                        name="reportType"
                                        classNamePrefix='select2-selection'
                                        options={questionnaireTypesOptions}
                                        value={ reportType }
                                        onChange={(e) => {
                                            setReportType(e);
                                            faildValidations.splice(faildValidations.findIndex((item) => item === 'reportType'), 1)
                                        }} 
                                    />

                                    <Form.Control.Feedback type="invalid" style={{ display: `${isReportTypeInvalid ? 'block' : 'none'}`}}> 
                                        { t('Reporting obligation type not selected') }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="responsiblePerson">
                                <Form.Label>
                                    { t('Responsible Person') }
                                </Form.Label>

                                <Select
                                    placeholder={t('Select...')}
                                    name="responsiblePerson"
                                    isDisabled={ peopleListLoading }
                                    isLoading={ peopleListLoading }
                                    classNamePrefix='select2-selection'
                                    options={ people }
                                    value={ person }
                                    onChange={(e) => {
                                        setPerson(e);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'person'), 1)
                                    }} 
                                />

                                <Form.Control.Feedback type="invalid" style={{ display: `${isResponsiblePersonInputInValid ? 'block' : 'none'}`}}> 
                                    { t('Responsible person not selected') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm='12'>
                            <Form.Group controlId="deadline">
                                <Form.Label>
                                    { t('Deadline') }
                                </Form.Label>

                                <DatePicker 
                                    locale={datePickerLocales[lng]}
                                    name="deadline"
                                    id="deadline"
                                    className="form-control"
                                    selected={ deadlineDate }
                                    showTimeSelect={ false }
                                    minDate={new Date()}
                                    dateFormat={INTERNATIONAL_DATE_FORMAT}
                                    onChange={(date) => {
                                        setDeadlineDate(date);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'deadlineDate'), 1)
                                    }}
                                /> 

                                <Form.Control.Feedback type="invalid" style={{ display: `${isDeadlineInputInValid ? 'block' : 'none'}`}}>
                                    { t('Deadline is required') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm="12">
                            <Form.Group controlId="financialYear">
                                <Form.Label>
                                    {t('Financial Year')}
                                </Form.Label>

                                <Select
                                    placeholder={t('Select a year')}
                                    name="financialYear"
                                    classNamePrefix="select2-selection"
                                    options={[
                                        { value: 2022, label: '2022' },
                                        { value: 2023, label: '2023' },
                                        { value: 2024, label: '2024' },
                                        { value: 2025, label: '2025' }
                                    ]}
                                    onChange={(e) => {
                                        setFinancialYear(e);
                                        faildValidations.splice(faildValidations.findIndex((item) => item === 'financialYear'), 1)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" style={{ display: `${isFinancialYearInvalid ? 'block' : 'none'}`}}> 
                                    { t('Financial year not selected') }
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={ creationFormInProcess }> 
                        { t('Cancel') }
                    </Button>

                    <Button type="submit" color="primary" disabled={ creationFormInProcess }>
                        {
                            creationFormInProcess && (
                                <Spinner animation="border" variant="primary" size="sm"/>
                            )
                        }
                        { t('Submit') }
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

export default withNamespaces()(NewReportRequestModal);