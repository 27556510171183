import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        fontSize: 10,
        position: 'relative',
        fontFamily: 'Open Sans',
        paddingBottom: 30
    },
    ml1: {
        marginLeft: 4
    },
    section: {
        padding: '0px 50px',
        height: 'auto',
        flexGrow: 1
    },
    note: {
        fontSize: 9,
        color: '#4472c4',
        padding: '0px 35px',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start'
    },
    generalInfosRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12
    },
    generalInfosRowCol: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    generalInfosTitle: {
        fontWeight: 'bold'
    },
    generalInfosValue: {
        color: '#4472c4',
        fontWeight: 'normal'
    },
    aiGeneralDescription: {
        lineHeight: 1.5
    },
    sections: {
        padding: "0px 30px",
    },
    sectionSeparator: {
        width: '100%',
        backgroundColor: '#4472c4',
        fontWeight: 600,
        fontSize: 13,
        color: 'white',
        padding: "4px 20px",
        marginBottom: 20,
        marginTop: '10px'
    },
    generalInfos: {
        marginBottom: 20
    },
    table: {
        display: "table",
        width: "auto",
        marginBottom: '10px',
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
    },
    tableRow: {
        flexDirection: "row",
        borderBottom: "1px solid #000"
    },
    tableLastRow : {
        borderBottom: 'none'
    },
    tableColHeader: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff",
        borderRight: '1px solid #000',
    },
    fontBold: {
        fontWeight: "600"
    },
    tableCol: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRight: '1px solid #000',
        paddingTop: 2,
        paddingBottom: 2
    },
    borderRightNone : {
        borderRight: 'none'
    },    
    tableCell: {
        margin: 5,
        fontSize: 8,
        flexDirection: "row",
        alignItems: "center",
        flexGrow: 1,
        flexShrink: 1, 
        flexBasis: 0, 
        maxWidth: "100%", 
        wordWrap: "break-word",
        whiteSpace: "normal",
        overflow: "hidden"
    },
    text: {
        fontSize: 10,
        marginBottom: 10,
    },
    alphListItem: {
        color: "blue",
        marginBottom: 12
    }
});

export default styles;