import { AvField } from "availity-reactstrap-validation";
import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, Col, Label, Progress, Row } from "reactstrap";

class FileUploader extends Component {
    dropzoneAcceptedFiles = {
        accepted:
            "text/plain, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
        description: [
            `${this.props.t(
                "You are allowed to upload limited file types"
                )}. ${this.props.t("For more information")}, ${this.props.t("visit")}`,
            <a href="https://diss-co.tech/faq-diss-co/"
            target="_blank"
            >{` ${this.props.t("FAQ")} `}</a>,
            this.props.t("page"),
        ],
    };

    render() {
        const {
            t,
            uploadedFiles,
            handleAcceptedFiles,
            showProg,
            handleClickDeleteFiles,
        } = this.props;

        return (
            <>
                <Label className="form-label">
                    {t("Attachments")}
                </Label>
                <AvField
                    name={"fileUpload"}
                    id="fileUpload"
                    type="file"
                    className="form-control fileUpload"
                    onChange={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                    multiple={true}
                />

                <br />

                <Progress color="info" striped animated value={100} hidden={!showProg}>
                    {t("Please wait while the file is uploaded")}
                </Progress>

                <div className="dropzone-previews mt-3" id="file-previews">
                    {uploadedFiles.map((f, i) => {
                        return (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}>
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col>
                                            <Link to="#" className="text-muted fw-bold">
                                                {f.name}
                                            </Link>

                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>

                                        <Col xs={2}
                                            style={{
                                                textAlign: "right",
                                            }}>
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => handleClickDeleteFiles(f)}>
                                                X
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}

                    {false && (
                        <p style={{
                            color: "red",
                            fontSize: 14,
                            }}
                            className="mt-3">
                            {t(
                            "The file type is not supported by our system that removes meta data of your file to ensure your anonymity. Please see the FAQ to learn more about the supported file types"
                            )}
                        </p>
                    )}
                </div>
            </>
        );
    }
}

export default withNamespaces()(FileUploader);
