import {
    ROLE_ANALYST_ADMIN
} from "src/components/constants";

import {
    ROUTE_PATH_ADMIN_DASHBOARD,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES,
    ROUTE_PATH_ADMIN_SETTINGS,
    ROUTE_PATH_ADMIN_CUSTOMIZATION,
    ROUTE_PATH_ADMIN_USER_LIST,
    ROUTE_PATH_ADMIN_DOCUMENTS,
    ROUTE_PATH_ADMIN_VIEW_DOCUMENTS,
    ROUTE_PATH_ADMIN_QUESTIONNAIRE,
    ROUTE_PATH_ADMIN_HISTORY_LOGS
} from "src/modules/3rd-party-management/constants"

import {
    ROUTE_PATH_ADMIN_E_LEARNING,
    ROUTE_PATH_ADMIN_TUTORIAL,
    ROUTE_PATH_ADMIN_RISK_MANAGMENT,
    ROUTE_PATH_ADMIN_ENTITY_MANAGMENT,
    ROUTE_PATH_ADMIN_PLATFORM_UPDATE
} from "src/common/constants";

const AdminMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-dashboard-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-artboard-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Third Party Management",
        path        :   ROUTE_PATH_ADMIN_HOME,
        icon        :   "ri-team-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Own Company",
        path        :   ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES,
        icon        :   "ri-building-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [
            'entity_management'
        ],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Risk Management",
        path        :   ROUTE_PATH_ADMIN_RISK_MANAGMENT,
        icon        :   "ri-alert-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Administration",
        path        :   null,
        icon        :   "ri-admin-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Settings",
                path        :   ROUTE_PATH_ADMIN_SETTINGS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Customisation",
                path        :   ROUTE_PATH_ADMIN_CUSTOMIZATION,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Entity Management",
                path        :   ROUTE_PATH_ADMIN_ENTITY_MANAGMENT,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [
                    'entity_management'
                ],
                roles           :   [
                    ROLE_ANALYST_ADMIN
                ],
                customValidator :   null
            },
            {
                titleKey    :   "History Logs",
                path        :   ROUTE_PATH_ADMIN_HISTORY_LOGS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [
                    "history-logs"
                ],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Documents",
                path        :   ROUTE_PATH_ADMIN_DOCUMENTS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "User Management",
                path        :   ROUTE_PATH_ADMIN_USER_LIST,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Questionnaire Designer",
                path        :   ROUTE_PATH_ADMIN_QUESTIONNAIRE,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            }
        ]
    },
    {
        titleKey    :   "Documents",
        path        :   ROUTE_PATH_ADMIN_VIEW_DOCUMENTS,
        icon        :   "ri-book-open-line",
        linkType    :   "react-router",
        moduleFeatures  :   [],
        authProtected   :   true,
        settingKey      :   null,
        roles           :   [
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "What's New?",
        path        :   ROUTE_PATH_ADMIN_PLATFORM_UPDATE,
        icon        :   "ri-flashlight-fill",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    // {
    //     titleKey    :   "E-Learning",
    //     path        :   ROUTE_PATH_ADMIN_E_LEARNING,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     authProtected   :   true,
    //     settingKey      :   "isElearning",
    //     moduleFeatures  :   [],
    //     roles           :   [],
    //     customValidator :   null
    // },
    // {
    //     titleKey    :   "Tutorial",
    //     path        :   ROUTE_PATH_ADMIN_TUTORIAL,
    //     icon        :   "ri-video-line",
    //     linkType    :   "react-router",
    //     authProtected   :   true,
    //     settingKey      :   null,
    //     moduleFeatures  :   [],
    //     roles           :   [],
    //     customValidator :   null
    // }
];

export default AdminMenuItems;