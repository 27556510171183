import React from "react";
import { Document, Page, View, StyleSheet, Font } from "@react-pdf/renderer";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";
import FirstPageContent from "./FirstPage/first-page";
import FirstHeader from "./FirstPage/firstHeader";
import FirstFooter from "./FirstPage/firstFooter";
import PageContent from "./OtherPages/page-content";
import Header from "./OtherPages/header";
import {
    FONT_OPEN_SANS_BOLD,
    FONT_OPEN_SANS_REGULAR,
} from "src/components/constants";
import { format } from 'date-fns';

Font.register({
    family: "Open Sans",
    fonts: [
        {
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "bold",
        }
    ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Open Sans",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingBottom: 30
  }
});

const PDFDocument = (props) => {
    const { supplierType, data, organization } = props;

    const logo = {};
    
    if(organization.secondaryLogo){
        logo.src = organization.secondaryLogo;
        logo.size = {
            maxWidth: '200px',
            height: `${organization.secondaryLogoSize || 40}px` 
        }
    }
    else{
        logo.src = organization.primaryLogo;
        logo.size = {
            maxWidth: '240px',
            maxHeight: '120px',
            height: `${organization.primaryLogoSize || 40}px`
        }
    }

    const primaryColor = organization.primaryColor || "#5664d2";

    const generateColor = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor?.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    const startColor = primaryColor;
    const stopColor = generateColor(primaryColor, 15);

    const {
        financialEndYear,
        responsiblePerson,
        sections,
        companyName,
        assignedAnalyst,
        submissionDate
    } = data;

    return (
        <Document>
            <Page 
                size="A4" 
                style={styles.page}
            >
                <FirstHeader 
                    startColor={startColor} 
                    stopColor={stopColor} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                />
                
                <View>
                    <FirstPageContent
                        name={companyName}
                        supplierType={supplierType}
                        financialEndYear={financialEndYear}
                        submissionDate={submissionDate}
                        responsiblePerson={responsiblePerson}
                        downloadedAt={format(new Date(), INTERNATIONAL_DATE_FORMAT)}
                        assignedAnalyst={assignedAnalyst}
                    />
                </View>
                <FirstFooter 
                    backgroundColor={primaryColor} 
                />
            </Page>

            <Page 
                size="A4" 
                style={styles.page}
            >
                <Header 
                    startColor={startColor} 
                    stopColor={stopColor} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                />
                    <PageContent
                        sections={sections || []}
                    />
                <FirstFooter 
                    backgroundColor={primaryColor} 
                />
            </Page>
        </Document>
    );
};

export default PDFDocument;
