export const faqs = {
  tp: [
    {
      question: "What is the Smart Integrity Platform (SIP)?",
      answer:
        "The SIP is a whistleblower system designed to meet regulatory and legal requirements, offering anonymous reporting and communication while improving governance and internal processing.",
    },
    {
      question: "What are the system requirements for using the SIP?",
      answer:
        "The platform is optimized for desktops, requires a stable internet connection, and works with current versions of popular browsers. However, for data protection reasons, using Google Chrome is not recommended.",
    },
    {
      question: "How do I log in to the SIP?",
      answer:
        "Log in with your email and password. A 6-digit authentication code will be sent to your email. Enter the code to complete the login process.",
    },
    {
      question: "What should I do if I forget my password?",
      answer:
        "Click 'I forgot my password' on the login page. Enter your email, and you’ll receive a reset code and a link to set a new password.",
    },
    {
      question: "How can I personalize the platform?",
      answer:
        "Admins can customize elements like company information, fonts and colors, logos and background images, and the privacy policy and terms of use.",
    },
    {
      question: "Can I update the privacy policy?",
      answer:
        "Yes, upload a PDF version of your policy in all platform languages. Contact support for a template.",
    },
    {
      question: "How do I add users to the platform?",
      answer:
        "You have two options: A) Upload a CSV file with user details or add them manually in the 'User Administration' section. Invitations will be sent automatically.",
    },
    {
      question: "How are entities managed in the SIP?",
      answer:
        "Admins can view, create, or delete entities, assign them by default, and add locations. The system allows detailed customization of entity structures.",
    },
    {
      question: "How does the risk assessment work?",
      answer:
        "The system uses the SIP Risk Database, considering factors like name, country, NACE codes, and products. Assigned Analysts can create risks based on findings and related incidents and reports from the third party and change the risk rating accordingly.",
    },
    {
      question: "How can I manage adverse news?",
      answer:
        "Flag a third party for monitoring, manage keywords for news fetching, and review findings. Use AI tools for risk mitigation based on flagged items.",
    },
    {
      question: "How do I create a questionnaire?",
      answer:
        "Use the Questionnaire Designer to add tabs, questions, and panels. Question types include radio buttons, checkboxes, file uploaders, and more.",
    },
    {
      question: "Can I edit a questionnaire after sending it?",
      answer:
        "No, you’ll need to withdraw the request and send a new one with the updated questionnaire.",
    },
    {
      question: "How do I add a third party?",
      answer:
        "Upload a CSV file or enter details manually. Include information like management, shareholders, and product specifics for better risk assessments.",
    },
    {
      question: "Can I request compliance reports from third parties?",
      answer:
        "Yes, select the 'Request a New Report' option, add a contact person, and set deadlines. Notifications will track the submission status.",
    },
    {
      question: "How can I view and manage notifications?",
      answer:
        "Click the bell icon for a dropdown list of updates. Notifications can be navigated or removed manually.",
    },
    {
      question: "Where can I get additional help?",
      answer:
        "Contact support at support@diss-co.tech for assistance with technical issues or platform usage.",
    },
  ],
};
