import { withNamespaces } from "react-i18next";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Button, Container, Label } from "reactstrap";
import OrganizationHeader from "./components/OrganizationHeader";
import { AvForm } from "availity-reactstrap-validation";
import Password from "../../components/Fields/Password";
import { useMutation } from '@tanstack/react-query';
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import {
    API_URL_SET_PASSWORD,
    API_URL_CHECK_PASSWORD_TOKEN,
} from "../../common/constants";
import axios from "axios";
import { useEffect } from "react";

const SetPasswordPage = ({
    t
}) => {
    const { token } = useParams();
    const history = useHistory();
    const searchQueries = new URLSearchParams(history.location.search);

    const {
        mutate: sendCheckTokenMutation,
        isLoading: checkTokenMutationInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            return await axios.post(API_URL_CHECK_PASSWORD_TOKEN, payload, {});
        },
        onError: () => {
            toast(t("Invalid Token"), {
                type: "error",
            });

            history.push('/404');
        }
    });

    const {
        mutate: resetPasswordMutation,
        isLoading: resetPasswordMutationInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            return await axios.post(API_URL_SET_PASSWORD, payload, {});
        },
        onError: (error) => {
            toast(t("Unknown error"), {
                type: "error",
            });
        },
        onSuccess: () => {
            toast(t("Your password was updated successfully"), {
                type: "success",
            });

            if(searchQueries.has('tpdd_supplier')){
                // currently only tpdd module has set password for third party users
                history.push(`/third-party/signin?disableRegister=true&username=${searchQueries.get('username') || ''}`)
            }
            else{
                history.push("/signin");
            }
        }
    });

    const handleValidSubmit = (e, values) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;

        if (!passwordRegex.test(values.password)) {
            toast(t("Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"), {
                type: "error",
            });
        } 
        else if (values.password !== values.repassword) {
            toast(t("Password and repeat password are not the same"), {
                type: "error",
            });
        } 
        else {
            resetPasswordMutation({
                token       :   token,
                password    :   values.password
            });
        }
    }

    useEffect(() => {
        sendCheckTokenMutation({
            token   :   token
        });
    }, [])

    return (
        <div>
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <OrganizationHeader />

                                            <div className="p-2 mt-5">
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={handleValidSubmit}
                                                >
                                                    <div className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="password">
                                                            {t("Password")}
                                                        </Label>
                                                        <Password
                                                            name="password"
                                                            validate={{ required: true }}
                                                            errorMessage={t(
                                                                "This field cannot be blank"
                                                            )}
                                                            id="password"
                                                            placeholder=""
                                                        />
                                                    </div>
                        
                                                    <div className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="repassword">
                                                            {t("Repeat password")}
                                                        </Label>
                                                        <Password
                                                            name="repassword"
                                                            validate={{ required: true }}
                                                            errorMessage={t(
                                                                "This field cannot be blank"
                                                            )}
                                                            id="repassword"
                                                            placeholder=""
                                                        />
                                                    </div>
                        
                                                    <div className="mt-4 text-center">
                                                        <Button
                                                            disabled={checkTokenMutationInProcess || resetPasswordMutationInProcess}
                                                            color="primary"
                                                            className="w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            {resetPasswordMutationInProcess && (
                                                                <Spinner animation="border" className="me-1" variant="white" size="sm"/>
                                                            )}
                                                            {t("Set password")}
                                                        </Button>
                                                    </div>
                                                </AvForm>
                                            </div>
                    
                                            <div className="mt-5 text-center">
                                                <p>
                                                    {`© 2024 SMART INTEGRITY PLATFORM. ${t('All rights reserved.')}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    return {};
};

const mapDispachToProps = (dispach) => {
    return {};
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, mapDispachToProps)(SetPasswordPage))
);
