import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { memo } from "react";

import OverviewHeader from "../../../../components/overview-header";

import FindingsList from "./components/FindingsList";

const Findings = ({
    t,
    supplierId
}) => {
    return (
        <div className="p-4">
            <OverviewHeader 
                supplierId={ supplierId } 
            />

            <div className="mt-3">
                <FindingsList 
                    t={ t } 
                    supplierId={supplierId} 
                />
            </div>
        </div>
    );
};

export default withNamespaces()(
    withRouter(memo(Findings))
); 