import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { routes } from "../../../../data";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createNewExistingUser,
  fetchOtherUsers,
} from "../../../../api/apiFunctions";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select from "react-select";
import ConfirmSelectionModal from "./components/confirmation-modal";

const AddExistingUser = (props) => {
  const { modules, t, token } = props;
  const activeModule = modules?.active;
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleConfirm = () => {
    toggleModal();
    handleSubmitExistingUserMutation.mutate({ user_id: selectedUser?.value });
  };

  const handleSubmitExistingUserMutation = useMutation({
    mutationFn: async (payload) => {
      await createNewExistingUser({
        token: token,
        payload,
        activeModule,
      });
    },
    onSuccess: () => {
      toast(props.t("User added to this module successfully."), {
        type: "success",
      });
      history.push(routes[activeModule].listOfUsers);
    },
    onError: () => {
      toast(t("Failed to add the user to the module."), {
        type: "error",
      });
    },
  });

  const handleFetchExistingUsersQuery = useQuery({
    queryKey: ["user-management-fetch-other-module-user-list"],
    queryFn: async () => {
      return await fetchOtherUsers({
        token: token,
        activeModule,
        payload: {},
      });
    },
    onError: () =>
      toast(t("An error occurred while fetching user list."), {
        type: "error",
      }),
  });

  return (
    <Card>
      <CardBody>
        <ConfirmSelectionModal
          isOpen={modalOpen}
          toggle={toggleModal}
          onConfirm={handleConfirm}
          selectedUser={selectedUser}
        />

        <Row>
          <Col md="6" sm="12">
            <Select
              isDisabled={
                handleFetchExistingUsersQuery.isLoading ||
                handleSubmitExistingUserMutation?.isLoading
              }
              className="ms-3 mb-2 text-capitalize"
              placeholder={t("Select from existing users")}
              id={`existingUser`}
              onChange={(user) => setSelectedUser(user)}
              options={handleFetchExistingUsersQuery?.data?.map((user) => ({
                value: user.id,
                label: `${user?.first_name} ${user?.last_name}`,
              }))}
            />
          </Col>

          <Col md="4" sm="12">
            <Button
              color="primary"
              disabled={
                !selectedUser ||
                handleFetchExistingUsersQuery.isLoading ||
                handleSubmitExistingUserMutation?.isLoading
              }
              onClick={() => setModalOpen(true)}
            >
              {t("Submit")}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(AddExistingUser)))
);
