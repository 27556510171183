import UserUtils from "src/services/utils/UserUtils";
import AddUser from "./other-roles/AddUser";
import SuperAdminUserManagement from "./super-admin";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const NewUser = (props) => {
  const { user } = props;
  const userUtils = new UserUtils(user);
  return userUtils.isSuperAdmin(user) ? (
    <SuperAdminUserManagement />
  ) : (
    <AddUser />
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Modules } = state;
  return { token, state, user, modules: Modules };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps)(NewUser)));
