import UserUtils from "src/services/utils/UserUtils";

const userUtils = new UserUtils();

const hasUserAccessToEditSupplier = (currentUser, targetUserId) => {
    return userUtils.isAnalystAdmin(currentUser) || (
        userUtils.isAnalyst(currentUser) && targetUserId === currentUser.id
    );
}

export {
    hasUserAccessToEditSupplier
};