import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as datePickerLocales from "date-fns/locale";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";
import DateUtils from "src/services/utils/DateUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import FinancialYearSettingsService from "src/modules/3rd-party-management/apis/FinancialYearSettingsService";

const FinancialSettings = ({ t, lng }) => {
    const dateUtils = new DateUtils();

    const [ daysInYearCount, setDaysInYearCount ] = useState(365);
    const [ formValidated, setFormValidated ] = useState(false);
    const [ faildValidations, setFaildValidations ] = useState([]);
    const [ financialYears, setFinancialYears ] = useState({
        start: null,
        end: null,
    });

    const {
        data: financialSettingsData,
        isFetching: financialSettingsAreFetching
    } = useQuery({
        queryFn: async () => {
            const service = FinancialYearSettingsService.getInstance();

            return await service.fetchSettings();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching settings.'), {
                type: 'error',
            });
        },
    });

    const handleSaveSettings = useMutation({
        mutationFn: async () => {
            const service = FinancialYearSettingsService.getInstance();
            return await service.saveSettings({
                financialStartYear: financialYears.start,
                financialEndYear: financialYears.end
            });
        },
        onSuccess: () => {
            toast(t("Financial year settings saved successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while saving settings."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(financialSettingsData?.data){
            const start = financialSettingsData.data.financialStartYear;
            const end = financialSettingsData.data.financialEndYear;

            setFinancialYears({
                start: start ? new Date(start * 1000) : null,
                end: end ? new Date(end * 1000) : null,
            });
        }
    }, [ financialSettingsData ]);

    const handleSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated(true);

        const faildInputs = [];

        if (!financialYears.start) {
            faildInputs.push('financialStartYear');
        }

        if (!financialYears.end) {
            faildInputs.push('financialEndYear');
        }

        const daysInYear = dateUtils.isLeapYear(financialYears.start) ? 366 : 365;

        const totalDays = financialYears.start && financialYears.end ? dateUtils.getDiffInDays(financialYears.start, financialYears.end) + 1 : 0;

        if (totalDays !== daysInYear) {
            faildInputs.push('dateRange');
        }

        if(faildInputs.length){
            setFaildValidations(faildInputs);
            return;
        }

        handleSaveSettings.mutate();
    }

    const restoreSettigs = (e) => {
        e.preventDefault();

        if(financialSettingsData?.data){
            const start = financialSettingsData.data.financialStartYear;
            const end = financialSettingsData.data.financialEndYear;

            setFinancialYears({
                start: start ? new Date(start * 1000) : null,
                end: end ? new Date(end * 1000) : null,
            });
        }
    }

    const isStartDateInvalid = faildValidations.includes('financialStartYear') || (formValidated && !financialYears.start);
    const isEndDateInvalid = faildValidations.includes('financialEndYear') || (formValidated && !financialYears.end);
    const isDateRangeInvalid = (!isEndDateInvalid && !isStartDateInvalid) && faildValidations.includes('dateRange');

    return (
        <div className="settings">
            <Form noValidate onSubmit={handleSubmitForm} autocomplete="off">
                <Row>
                    <Col sm='12' lg='6' xl='4' className="mb-4">
                        <Form.Group controlId="financialStartDate">
                            <Form.Label>
                                { t('Start of Financial Year') }
                            </Form.Label>

                            <DatePicker
                                disabled={financialSettingsAreFetching}
                                placeholderText={t('Click to select a date')}
                                name="financialStartYear"
                                id="financialStartYear"
                                selected={ financialYears.start }
                                className={`form-control ${
                                    isStartDateInvalid ? "is-invalid" : ""
                                }`}
                                locale={datePickerLocales[lng]}
                                showTimeSelect={ false }
                                dateFormat={INTERNATIONAL_DATE_FORMAT}
                                onChange={(date) => {
                                    if (date) {
                                        const endDate = new Date(date);
                                        const daysInYear = dateUtils.isLeapYear(date) ? 366 : 365;
                            
                                        endDate.setDate(endDate.getDate() + (daysInYear - 1));

                                        if (date.getMonth() === 1 && date.getDate() === 29 && !dateUtils.isLeapYear(endDate)) {
                                            endDate.setDate(28);
                                        }

                                        setFinancialYears((currentState) => ({
                                            ...currentState,
                                            start: date,
                                            end: endDate,
                                        }));

                                        setFaildValidations((currentState) => {
                                            return [...currentState].filter((err) => {
                                                return err !== 'financialStartYear' && err !== 'dateRange'
                                            })
                                        });
                                    }
                                }}
                            />

                            {isStartDateInvalid && (
                                <div className="invalid-feedback d-block">
                                    {t('The start year is required and must be between 2022 and 2025.')}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm='12' lg='6' xl='4' className="mb-4">
                        <Form.Group controlId="financialEndYear">
                            <Form.Label>
                                { t('End of Financial Year') }
                            </Form.Label>

                            <DatePicker
                                disabled={financialSettingsAreFetching || !financialYears.start}
                                placeholderText={t(
                                    !financialYears.start
                                    ? "Select the start year first"
                                    : "Click to select a date"
                                )}
                                autoComplete="off"
                                name="financialEndYear"
                                id="financialEndYear"
                                locale={datePickerLocales[lng]}
                                className={`form-control ${
                                    isEndDateInvalid || isDateRangeInvalid
                                    ? "is-invalid"
                                    : ""
                                }`}
                                selected={financialYears.end}
                                minDate={financialYears.start}
                                showTimeSelect={ false }
                                dateFormat={INTERNATIONAL_DATE_FORMAT}
                                onChange={(date) => {
                                    const daysInYear = dateUtils.isLeapYear(financialYears.start) ? 366 : 365;
                                    const totalDays = financialYears.start && date ? dateUtils.getDiffInDays(financialYears.start, date) + 1 : 0;

                                    if(date){
                                        const keysToDelete = ['financialEndYear'];
                                        const keysToAdd = [];

                                        setDaysInYearCount(daysInYear);

                                        if(totalDays === daysInYear){
                                            keysToDelete.push('dateRange');
                                        }
                                        else{
                                            keysToAdd.push('dateRange');
                                        }
                                        
                                        setFaildValidations((currentState) => {
                                            const newState = [...currentState].filter((err) => {
                                                return !keysToDelete.includes(err)
                                            })

                                            return [
                                                ...newState,
                                                ...keysToAdd
                                            ]
                                        });
                                    }

                                    setFinancialYears((currentState) => ({
                                        ...currentState,
                                        end: date,
                                    }));
                                }}
                            />

                            {isEndDateInvalid && (
                                <div className="invalid-feedback d-block">
                                    {t('The end year is required')}.
                                </div>
                            )}
                            {isDateRangeInvalid && (
                                <div className="invalid-feedback d-block">
                                    {t(`Please select a date range of exactly one financial year (${daysInYearCount} days). The current selection doesn’t meet this requirement.`)}
                                </div>
                            )}
                            {!isDateRangeInvalid && financialYears.end && (
                                <p className="text-info mb-0 mt-1">
                                    {t("The selected financial end year is")}:{" "}
                                    <b>{new Date(financialYears.end).getFullYear()}</b>
                                </p>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm='12' className="d-flex justify-content-end gap-3">
                        <Button color="primary" outline size="sm" disabled={financialSettingsAreFetching || handleSaveSettings.isLoading} onClick={restoreSettigs}> 
                            { t('Restore') }
                        </Button>

                        <Button type="submit" color="primary" size="sm" disabled={financialSettingsAreFetching || handleSaveSettings.isLoading}>
                            {handleSaveSettings.isLoading && (
                                <Spinner animation="border" variant="primary" size="sm" className="me-2"/>
                            )}
                            { t('Submit') }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token, user: currentUser } = state.Login;
    const { Organization } = state;
    return {
        currentUser,
        token,
        Organization
    };
};

export default withNamespaces()(
    connect(mapStatetoProps)(
        FinancialSettings
    )
);
