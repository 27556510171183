import {
  API_URL_ADMIN_CREATE_EXISTING_USER,
  API_URL_ADMIN_CREATE_USER_MANAGEMENT,
  API_URL_ADMIN_DELETE_WB_USER_MANAGEMENT,
  API_URL_ADMIN_GET_USER_COUNTS_BY_ROLE,
  API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_GET_WB_OTHER_MODULE_USER_LIST,
  AUTH_API_DOMAIN_URL,
} from "src/common/constants";
import {
  API_TPDD_USER_MANAGAMEN_BASE_URL,
  API_URL_ADMIN_CREATE_TPDD_EXISTING_USER,
  API_URL_ADMIN_CREATE_TPDD_USER_MANAGEMENT,
  API_URL_ADMIN_DELETE_TPDD_USER_MANAGEMENT,
  API_URL_ADMIN_GET_TPDD_OTHER_MODULE_USER_LIST,
  API_URL_ADMIN_GET_TPDD_USER_COUNTS_BY_ROLE,
  API_URL_ADMIN_GET_TPDD_USER_MANAGEMENT_LIST,
  API_BASE_URL as TPDD_BASE_URL,
} from "src/modules/3rd-party-management/constants";
import {
  API_BASE_URL,
  API_URL_ADMIN_DELETE_GDPR_USER_MANAGEMENT,
  API_URL_ADMIN_GET_GDPR_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_GET_GDPR_OTHER_MODULE_USER_LIST,
  API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT,
} from "src/modules/data-protection/constants";

export const getUrls = () => ({
  gd: {
    fetchUsers: API_URL_ADMIN_GET_GDPR_USER_MANAGEMENT_LIST,
    fetchOtherUsers: API_URL_ADMIN_GET_GDPR_OTHER_MODULE_USER_LIST,
    deleteUser: API_URL_ADMIN_DELETE_GDPR_USER_MANAGEMENT,
    baseUrl: API_BASE_URL,
    createNewUser: API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT,
  },
  wb: {
    fetchUsers: API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
    fetchOtherUsers: API_URL_ADMIN_GET_WB_OTHER_MODULE_USER_LIST,
    deleteUser: API_URL_ADMIN_DELETE_WB_USER_MANAGEMENT,
    baseUrl: AUTH_API_DOMAIN_URL,
    createNewUser: API_URL_ADMIN_CREATE_USER_MANAGEMENT,
    createNewExistingUser: API_URL_ADMIN_CREATE_EXISTING_USER,
    fetchUserCounts: API_URL_ADMIN_GET_USER_COUNTS_BY_ROLE,
  },
  tp: {
    fetchUsers: API_URL_ADMIN_GET_TPDD_USER_MANAGEMENT_LIST,
    fetchOtherUsers: API_URL_ADMIN_GET_TPDD_OTHER_MODULE_USER_LIST,
    deleteUser: API_URL_ADMIN_DELETE_TPDD_USER_MANAGEMENT,
    baseUrl: API_TPDD_USER_MANAGAMEN_BASE_URL,
    createNewUser: API_URL_ADMIN_CREATE_TPDD_USER_MANAGEMENT,
    createNewExistingUser: API_URL_ADMIN_CREATE_TPDD_EXISTING_USER,
    fetchUserCounts: API_URL_ADMIN_GET_TPDD_USER_COUNTS_BY_ROLE,
  },
});

export const routes = {
  gd: {
    newUser: "/admin/data-protection/administration/users/add",
    listOfUsers: "/admin/data-protection/administration/users",
    editUser: (id) => `/admin/data-protection/administration/users/${id}/edit`,
  },
  tp: {
    newUser: "/admin/3rd-party/administration/users/add",
    listOfUsers: "/admin/3rd-party/administration/users",
    editUser: (id) => `/admin/3rd-party/administration/users/${id}/edit`,
  },
  wb: {
    newUser: "/admin/administration/users/add",
    listOfUsers: "/admin/administration/users",
    editUser: (id) => `/admin/administration/users/${id}/edit`,
  },
};

export const roles = {
  ROLE_ANALYST_ADMIN: {
    label: "Analyst Admin",
    modules: ["wb", "cm", "gd", "tp"]
  },
  ROLE_ANALYST: {
    label: "Analyst",
    modules: ["wb", "cm", "gd", "tp"]
  },
  ROLE_EMPLOYEE: {
    label: "Employee",
    modules: ["wb", "cm", "gd"]
  },
  ROLE_TASK_OWNER: {
    label: "Task Owner",
    modules: ["wb", "cm", "gd"]
  },
};

export const validRolesForAnalystAdmin = {
  ROLE_EMPLOYEE: "Employee",
  ROLE_TASK_OWNER: "Task Owner",
};

export default { routes };
