import React from "react";
import axios from "axios";
import ReactDragListView from "react-drag-listview";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Container, Row, Col, Card, Button, CardBody, Label, Spinner, Tooltip } from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	API_URL_QUESTION_GENERATOR_GET_LIST,
	CM_API_URL_QUESTION_GENERATOR_GET_LIST,
	API_URL_QUESTION_GENERATOR_UPDATE_LIST,
	CM_API_URL_QUESTION_GENERATOR_UPDATE_LIST,
	API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS,
	CM_API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS,
	API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS,
	CM_API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS,
    NotShowOrgInfosOrganizations
} from '../../../common/constants';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { successNotification, errorNotification } from "../../../store/actions";
import { getCurrentLanguage } from "../../../i18n";

const vars = {
	spacing: 64,
	borderRadius: 9,
	borderColor: "#BAC1FF",
};

const styles = {
	box: {
		marginBottom: 20,
	},

	box__head: {
		height: vars.spacing,
		display: "flex",
		alignItems: "Center",
		justifyContent: "space-between",
		backgroundColor: "#F7F8FF",
		borderRadius: vars.borderRadius,
		cursor: 'auto'
	},
	box__head__button: {
		width: vars.spacing,
		height: vars.spacing,
		minWidth: vars.spacing,
		color: "#545B9A",
		display: "flex",
		cursor: "pointer",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		border: 0,
		outline: 0,
		backgroundColor: "transparent",
	},

	box__body: {
		padding: vars.spacing / 1.5,
		marginLeft: vars.spacing - 1,
		marginRight: vars.spacing - 1,
		marginBottom: vars.spacing - 1,
		border: "1px solid #E5E8FF",
		borderTop: 0,
		borderBottomLeftRadius: 9,
		borderBottomRightRadius: 9,
	},

	multichoiceOption: {
		width: "100%",
		height: 42,
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
		border: `1px solid ${vars.borderColor}`,
		borderRadius: vars.borderRadius,
	},
	multichoiceOption__label: {
		width: 111,
		minWidth: 111,
		height: "100%",
		display: "flex",
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		borderRight: `1px solid ${vars.borderColor}`,
	},
	multichoiceOption__button: {
		width: 45,
		minWidth: 45,
		height: "100%",
		display: "flex",
		textAlign: "center",
		cursor: "pointer",
		justifyContent: "center",
		alignItems: "center",
		borderLeft: `1px solid ${vars.borderColor}`,
	},
	multichoiceOption__option: {
		width: "100%",
		height: "100%",
		position: "relative",
	},
	multichoiceOption__option__input: {
		control: (base) => ({
			...base,
			backgroundColor: "inherit",
		}),
	},
	multichoiceOption__type__select: {
		borderLeft: `1px solid ${vars.borderColor} !important`,
	},
	multichoiceOption__type: {
		width: "100%",
		height: "100%",
		position: "relative",
	},
	multichoiceOption__type__input: {
		control: (base) => ({
			...base,
			height: 42,
			border: "0 !important",
			boxShadow: "0 !important",
			borderRadius: 0,
			borderLeft: `1px solid ${vars.borderColor} !important`,
			backgroundColor: "transparent !important",
		}),
	},
};

class QuestionnaireDesigner extends React.Component {
  	constructor(props) {
		super(props);

		this.state = {
			header: {
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			},
			tooltipOpen: false,
			breadcrumbItems: [
				{ title: "SIP", link: "/" },
				{ title:  this.props.t("Questionnaire Designer"), link: "#" },
			],

			spinner: {
				id: "",
				active: false,
			},

			accordion: {
				id: null,
				active: false,
			},

			questionTypes: [
				{
					title: this.props.t("Date"),
					value: "date",
				},
				{
					title: this.props.t("Switch"),
					value: "switch",
				},
				{
					title: this.props.t("Text Input"),
					value: "text",
				},
				{
					title: this.props.t("Text Area"),
					value: "textarea",
				},
				{
					title: this.props.t("Radiobox"),
					value: "radiobox",
				},
				{
					title: this.props.t("Checkbox"),
					value: "checkbox",
				},
				{
					title: this.props.t("Select Box"),
					value: "select",
				},
				{
					title: this.props.t("File Upload"),
					value: "file",
				},
                {
					title: this.props.t("Legal Entity"),
					value: "legal",
                    feature: 'entity_management'
				},
				// {
				// 	title: this.props.t("Default Country List"),
				// 	value: "country_list",
				// },
			],

			customQuestionTypes: [
				{
					title: this.props.t("Date"),
					value: "date",
				},
				{
					title: this.props.t("Switch"),
					value: "switch",
				},
				{
					title: this.props.t("Text Input"),
					value: "text",
				},
				{
					title: this.props.t("Text Area"),
					value: "textarea",
				},
				{
					title: this.props.t("File Upload"),
					value: "file",
				},
				{
					title: this.props.t("Radiobox"),
					value: "radiobox",
				},
				{
					title: this.props.t("Checkbox"),
					value: "checkbox",
				},
                {
					title: this.props.t("Legal Entity"),
					value: "legal",
                    feature: 'entity_management'
				},
				// {
				// 	title: this.props.t("Default Country List"),
				// 	value: "country_list",
				// },
			],

			questions: [],
			initialQuestions: [
				{
					id: 0,
					title: this.props.t("Salutation"),
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				},
				{
					id: 0,
					title: this.props.t("First name"),
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				},
				{
					id: 0,
					title: this.props.t("Last name"),
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				},
				{
					id: 0,
					title: this.props.t("Position"),
                    keyName: "position",
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				},
				{
					id: 0,
					title: this.props.t("Organization"),
                    keyName: "organization",
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				},
				{
					id: 0,
					title: this.props.t("Brief Description of Incident"),
					type: "initial",
					isAnonymous: false,
					isConfidential: false,
					isMeeting: false,
					isMandatory: false,
					options: [],
					isUpdated: false,
					sort: 0,
				}
			],
			defaultQuestions: [],
		};

		this.multipleChoiceInputFields = ["select", "radiobox", "checkbox"];
	}

	toggleTooltip = () => this.setState({
		tooltipOpen: !this.state.tooltipOpen
	});

	handleClickAddQuestion = () => {
		this.setState((prevState) => ({
			questions: [...prevState.questions, { id: "new", sort: 0, type: "text", title: this.props.t("Title"), isMandatory: false, isAnonymous: false, isConfidential: false, isMeeting: false, options: [], isCategory: false }],
		}));
	};

	handleClickRemoveQuestion = (id, number) => {
		const data = {
			question_id: id,
		};

		axios
			.post(this.props.modules.active === 'wb' ? API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS : CM_API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS, data, this.state.header)
			.then((response) => {
				if (response.data.ok) {
					this.props.successNotification({
						code: 'success',
						message: this.props.t('Data removed successfully'),
					});

					const questions = [...this.state.questions].filter((a) => a.id !== id);

					this.setState(
						{
							questions,
							spinner: {
								id: '',
								active: false,
							},
						},
						() => {
							this.fetchQuestions();
						},
					);
				}
			})
			.catch((error) => {
				if (error.response) {
					console.log(error);

					const data = error.response.data;

					if ('unknown_error' !== data.error) {
						this.props.errorNotification({
							code: data.error || 'unknown_error',
							message: this.props.t(data.message || data.title),
						});
					}
				}

				const questions = [...this.state.questions].filter((a, i) => i !== number);

				this.setState({
					questions,
					spinner: {
						id: '',
						active: false,
					},
				});
			});
	};

	handleClickToggleQuestion = (id) => {
		this.setState({
			accordion: { id, active: true },
		});
	};

	handleChangeQuestionField = (e, id, key) => {
		const nonEn = getCurrentLanguage() !== "en";

		const questions = [...this.state.questions].map((c, i) => {
			// console.log("c ---------- ", c)
			// console.log("i ---------- ", i)
			// console.log("id ---------- ", id)

			if (i === id) {
				return { ...c, [key]: e.target.value, isUpdated: nonEn };
			} else {
				let cxxc = c;
				cxxc.isUpdated = false;

				return cxxc;
			}
		});

		this.setState({
			questions,
		});
	};

	handleClickAddOption = (id) => {
		const questions = [...this.state.questions].map((c, i) => {
			if (i === id) {
				let a = c;

				a.options.push({ id: "new", title: "", type: "" });

				return a;
			} else {
				return c;
			}
		});

		this.setState({
			questions,
		});
	};

	handleChangeMultipleChoiceOption = (e, optionId, parentId, key) => {
		// const nonEn = localStorage.getItem("i18nextLng") === "en"; // check this one
		const nonEn = true; // check this one

		const questions = [...this.state.questions].map((c, i) => {
			// console.log("i ", i)
			// console.log("parentId ", parentId)

			if (i === parentId) {
				let nc = c;

				nc.options = c.options.map((cc, ii) => {
					console.log("nc ", nc);
					// ----------------------------------------
					console.log("ii ", ii);
					console.log("optionId ", optionId);

					if (ii === optionId) {
						let value;
						let newcc = cc;

						value = e.target.value;
						newcc[key] = value;
						newcc.isUpdated = nonEn;

						// console.log("nonEn ", nonEn);
						// console.log("---------------------");
						// console.log("cc ", cc);
						// console.log("---------------------");
						// console.log("newcc ", newcc);
						return newcc;
					} else {
						let cxxc = cc;

						// needs to test again.
						// cxxc.isUpdated = false;

						return cxxc;
					}
				});

				return nc;
			} else {
				return c;
			}
		});

		this.setState({
			questions,
		});
	};

	handleClickRemoveMultipleChoiceOption = (optionId, parentId, optionIndex, parentIndex) => {
		if (getCurrentLanguage() !== "en") {
			// TODO: add message why user cannot remove multiple choice option
			// show message: you can't do hat
			return;
		}

		let questions = this.state.questions.map((x, i) => {
			if ((x.id && x.id === parentId) || i === parentIndex) {
				let nx = x;

				nx.options = x.options.filter((xx, xxi) => {
					if (xx.id) {
						return xx.id !== optionId;
					} else {
						return xxi !== optionIndex;
					}
				});

				return nx;
			} else {
				return x;
			}
		});

		const data = {
			subQuestionId: optionId,
		};

		axios
			.post(this.props.modules.active === 'wb' ? API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS : CM_API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS, data, this.state.header)
			.then((response) => {
				const res = response.data.data;

				if (response.data.ok) {
					this.props.successNotification({
						code: "success",
						message: this.props.t("Data removed successfully"),
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					const data = error.response.data;

					if ("unknown_error" !== data.error) {
						this.props.errorNotification({
							code: data.error || "unknown_error",
							message: this.props.t(data.message || data.title),
						});
					}
				}
			});

		this.setState({
			questions,
			spinner: {
				id: "",
				active: false,
			},
		});
	};

	handleChangeCheckBox = (e, id, key) => {
		// console.log("e.target.value: " + e.target.value, id, key);

		// TODO: show message: you can't do hat
		// In Non-English languages, user should not drag & drop questions
		// We can add different accents of English according to the selected country in this validation.
		// But in my opinion, the English language with the title "en" should be used as a reference. Other dialects of English should be treated as foreign languages.
		if (getCurrentLanguage() !== "en") {
			return;
		}

		let questions = this.state.questions.map((c, i) => {
			if (i === id) {
				return { ...c, [e.target.value]: e.target.checked };
			} else {
				return c;
			}
		});
		this.setState(
			{
				questions,
			},
			// () => console.log("State", this.state.questions)
		);
	};

	isQuestionDraggable = (question) => {
		return !(question.id.toString() === 'country_list' || question?.isCategory);
	}

	isQuestionDeletable = (question) => {
		return !(question.id.toString() === 'country_list');
	}

	isQuestionEditable = (question) => {
		return !(question.id.toString() === 'country_list');
	}

	handleSubmit = (e, values) => {
		// console.log("values ", values);

		this.setState({
			spinner: {
				id: "submit-button",
				active: true,
			},
		});

		let newQuestions = this.state.questions;

		let arr1 = this.state.questions;
		let arr2 = this.state.defaultQuestions;

		let difference = arr1.filter((x, i) => !arr2.includes(x));

		newQuestions.map((x) => {
			if (difference.includes(x)) {
				return { ...x, isUpdated: true };
			} else {
				return x;
			}
		});

		newQuestions.map((x, i) => {
			let xx = x;

			xx.id = x.id || "category";
			xx.sort = i;

			return xx;
		});

		difference.map((x) => {
			let xx = x;

			xx.isUpdated = true;

			return xx;
		});

		// console.log("Final questions: ", newQuestions);

		axios
			.post(this.props.modules.active === 'wb' ? API_URL_QUESTION_GENERATOR_UPDATE_LIST : CM_API_URL_QUESTION_GENERATOR_UPDATE_LIST, { Questions: newQuestions, codeLang: getCurrentLanguage() }, this.state.header)
			.then((response) => {
				const res = response.data.data;
				// console.log(res);
				if (response.data.ok) {
					this.setState({
						spinner: {
							id: "",
							active: false,
						},
					});
					this.props.successNotification({
						code: "success",
						message: this.props.t("Data was saved successfully"),
					});

					// TODO: no need to do anything
					if (getCurrentLanguage() === "en") {
						this.fetchQuestions();
					}

					
				}
			})
			.catch((error) => {
				if (error.response) {
					const data = error.response.data;

					this.props.errorNotification({
						code: data.error || "unknown_error",
						message: this.props.t(data.message || data.title),
					});
				}

				this.setState({
					spinner: {
						id: "",
						active: false,
					},
				});
			});
	};

	render() {
		if (!isFeatureEnabledForActiveModule("question-generator")) {
			return <div className="page-content">{this.props.t("Please upgrade your plan to access this feature")}.</div>;
		}

        /**
         * SPIK-7479
         * This is an uggly way to handle this task. 
         * This part should be changed in the future after handling it by the backend.
         */
        const hideOrgInfo = this.props.modules.active === 'cm' && NotShowOrgInfosOrganizations.includes(this.props.Organization.id);

		const that = this;
		const dragProps = {
			nodeSelector: "li",
			handleSelector: "button.btn--drag-and-drop",

			onDragEnd(fromIndex, toIndex) {
				if (getCurrentLanguage() !== "en") {
					return;
				}

				const data = that.state.questions;

				if(!that.isQuestionDraggable(data[fromIndex]) || !that.isQuestionDraggable(data[toIndex])){
					return;
				}

				const item = data.splice(fromIndex, 1)[0];

				data.splice(toIndex, 0, item);
				that.setState({ data, accordion: { id: toIndex, active: true } });
			},
		};

		return (
			<React.Fragment>
				<div className="page-content dissco--question-generator">
					<Container fluid>
						<Breadcrumbs title={this.props.t("Questionnaire Designer")} breadcrumbItems={this.state.breadcrumbItems} />
						
						<Row>
							<Col xl="12">
								<Card>
									<CardBody>
										<div className="mb-4">
											<p>{this.props.t("Questions for anonymous and confidential report") + " "}
											{/* Tooltip and icon rendered separately */}
												<i 
												className="fa fa-info-circle ml-2"
												aria-hidden="true"
												id="tooltipTarget"
												style={{ cursor: 'pointer' }}
												/>
											{/* Tooltip for English-only message */}
											<Tooltip
											placement="right"
											isOpen={this.state?.tooltipOpen}
											target="tooltipTarget"
											toggle={this.toggleTooltip}
											>
											{this.props.t("The questionnaire designer is only editable in English.")}
											</Tooltip>
											</p>
										</div>

										<AvForm onValidSubmit={this.handleSubmit}>
											<div className="mb-3">
												{/* Initial Questions */}
												<ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
													{this.state.initialQuestions.map((question, index) => (
														<li key={`question-${index}`} className={hideOrgInfo && ['organization', 'position'].includes(question.keyName) ? 'd-none' : ''}>
															<div style={{ ...styles.box }} className={question.type === "initial" && "disable-question-box"}>
																<div style={styles.box__head}>
																	<button type="button" style={{ ...styles.box__head__button, borderRight: "1px solid #E5E8FF" }} title={this.props.t("Drag and Drop")}>
																		<i className="ri-arrow-up-down-fill"></i>
																	</button>

																	<div style={{ width: "100%" }}>
																		<span style={{ padding: "0 20px 0 25px", color: "#1A1D30", fontWeight: "bolder" }}>{`Q${index + 1}:`}</span>
																		<span style={{ color: "#5D5E6E", fontWeight: "bolder" }}>{this.props.t(question.title) || this.props.t("Title")}</span>
																	</div>

																	<div style={{ display: "flex" }}>
																		<button type="button" title={this.props.t("Edit")} style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF" }}>
																			<i className="ri-edit-line"></i>
																		</button>
																		<button type="button" title={this.props.t("Delete")} style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF", color: "#7f0000" }}>
																			<i className="ri-delete-bin-line"></i>
																		</button>
																	</div>
																</div>
															</div>
														</li>
													))}
												</ul>

												{/* Default Questions */}
												<ReactDragListView {...dragProps}>
													<ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
														{this.state.questions.length
															? this.state.questions.map((question, index) => (
																	<li key={`question-${index}`}>
																		<div style={styles.box} className={question.type === "initial" || !this.isQuestionDeletable(question) && "disable-question-box"}>
																			<div style={{...styles.box__head, 
																			 cursor: (!this.isQuestionDraggable(question)) ? 'not-allowed' : 'move' 
																			}}>
																				<button disabled={!this.isQuestionDraggable(question)} className="btn--drag-and-drop" type="button" style={{ ...styles.box__head__button, borderRight: "1px solid #E5E8FF", cursor: !this.isQuestionDraggable(question) ? 'not-allowed' : 'move' }} title={this.props.t("Drag and Drop")}>
																					<i className="ri-arrow-up-down-fill"></i>
																				</button>

																				<div style={{ width: "100%" }}>
																					<span style={{ padding: "0 20px 0 25px", color: "#1A1D30", fontWeight: "bolder" }}>{`Q${this.state.initialQuestions.length + index + 1}:`}</span>
																					<span style={{ color: "#5D5E6E", fontWeight: "bolder" }}>{question.title || this.props.t("Title")}</span>
																				</div>

																				<div style={{ display: "flex" }}>
																					<button
																						type="button"
																						title={this.props.t("Edit")}
																						style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF" }}
																						disabled={!this.isQuestionEditable(question)}
																						onClick={() => {
																							this.handleClickToggleQuestion(index);
																						}}
																					>
																						<i className="ri-edit-line"></i>
																					</button>
																					<button
																						type="button"
																						disabled={!this.isQuestionDeletable(question) || getCurrentLanguage() !== "en" || question.isCategory}
																						title={this.props.t("Delete")}
																						style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF", color: "#7f0000", cursor: !this.isQuestionDeletable(question) || getCurrentLanguage() !== "en" || question.isCategory ? 'not-allowed' : 'pointer' }}
																						onClick={() => {
																							question.isCategory  || question.id === 'country_list' ? console.log("you cant") : this.handleClickRemoveQuestion(question.id, index);
																						}}
																					>
																						<i className='ri-delete-bin-line'></i>
																					</button>
																				</div>
																			</div>
																			{this.state.accordion.id === index && this.state.accordion.active ? (
																				<div style={styles.box__body}>
																					<Row>
																						<Col xs={8}>
																							<div>
																								<Label style={{ color: "#1A1D30" }} className="form-label">
																									{this.props.t("Title")}
																								</Label>
																								<AvField
																									// disabled={question.isCategory}
																									type="text"
																									name={`${"question-title"}-${index}`}
																									value={question.title || ""}
																									validate={{
																										required: { value: true },
																									}}
																									className="dissco--question-genrator--input"
																									onChange={(e) => this.handleChangeQuestionField(e, index, "title")}
																								/>
																							</div>
																						</Col>
																						<Col xs={4}>
																							<div style={{ position: "relative" }}>
																								<Label style={{ color: "#1A1D30" }} className="form-label">
																									{this.props.t("Type")}
																								</Label>
																								<AvField
																									type="select"
																									name={`${"question-type"}-${index}`}
																									style={{ border: "#BAC1FF !important" }}
																									value={question.type || ""}
																									validate={{
																										required: { value: true },
																									}}
																									disabled={getCurrentLanguage() !== "en" || question.isCategory}
																									className="dissco--question-genrator--input"
																									onChange={(e) => this.handleChangeQuestionField(e, index, "type")}
																								>
																									{this.state.questionTypes.filter((questionType) => !questionType.feature ? true : isFeatureEnabledForActiveModule(questionType.feature)).map((questionType, index) => (
																										<option key={`question-type-${index}`} value={questionType.value}>
																											{this.props.t(questionType.title)}
																										</option>
																									))}
																								</AvField>
																								<i className="ri-arrow-down-s-line" style={{ position: "absolute", bottom: 6, right: 15, fontSize: 20 }}></i>
																							</div>
																						</Col>
																						{this.multipleChoiceInputFields.includes(question.type) && (
																							<Col xs={12}>
																								{question.options.length ? (
																									<div>
																										<Label style={{ color: "#1A1D30" }} className="form-label mt-4">
																											{this.props.t("Options")}
																										</Label>
																										{question.options.map((option, optionIndex) => (
																											<div id={`option-${optionIndex}`} style={styles.multichoiceOption}>
																												<div style={{ ...styles.multichoiceOption__label }}>
																													<span style={{ whiteSpace: "nowrap" }}>{`${this.props.t("Option")} ${optionIndex + 1}`}</span>
																												</div>
																												<div style={{ ...styles.multichoiceOption__option }}>
																													<AvField
																														type="text"
																														name={`${"question-option-"}-${index}-${optionIndex}`}
																														style={styles.multichoiceOption__option__input}
																														value={this.props.t(option.title) || ""}
																														placeholder={this.props.t("Enter title")}
																														validate={{
																															required: { value: true },
																														}}
																														className="dissco--question-genrator--form-control dissco--question-genrator--input"
																														onChange={(e) => this.handleChangeMultipleChoiceOption(e, optionIndex, index, "title")}
																													/>
																												</div>
																												<div style={{ ...styles.multichoiceOption__type, borderLeft: `1px solid ${vars.borderColor}` }}>
																													<AvField
																														type="select"
																														name={`${"question-option-type-"}-${index}-${optionIndex}`}
																														style={styles.multichoiceOption__type__select}
																														value={option.type || ""}
																														className="dissco--question-genrator--form-control dissco--question-genrator--input"
																														placeholder={this.props.t("Select Type")}
																														disabled={getCurrentLanguage() !== "en"}
																														validate={{
																															required: { value: true },
																														}}
																														onChange={(e) => this.handleChangeMultipleChoiceOption(e, optionIndex, index, "type")}
																													>
																														<option value={null}>{this.props.t("Select Type")}</option>
																														{this.state.customQuestionTypes.filter((questionType) => !questionType.feature ? true : isFeatureEnabledForActiveModule(questionType.feature)).map((f, j) => (
																															<option key={j} value={f.value}>
																																{this.props.t(f.title)}
																															</option>
																														))}
																													</AvField>
																													<i className="ri-arrow-down-s-line" style={{ position: "absolute", top: 6, right: 15, fontSize: 20 }}></i>
																												</div>
																												<div style={{ ...styles.multichoiceOption__button }} onClick={() => this.handleClickRemoveMultipleChoiceOption(option.id, question.id, optionIndex, index)}>
																													<button type="button" title={this.props.t("Delete")} style={{ backgroundColor: "transparent", border: 0, outline: 0, color: "#505d69" }}>
																														<i className="ri-delete-bin-line" style={{ top: 3, position: "relative", fontSize: "96%" }}></i>
																													</button>
																												</div>
																											</div>
																										))}
																									</div>
																								) : null}
																								{getCurrentLanguage() === "en" ? (
																									<div>
																										<Button color="secondary" size="md" outline block style={{ width: "100%" }} onClick={() => this.handleClickAddOption(index)} className="dissco--question-genrator--button dissco--question-genrator--button--md mt-3">{`+ ${this.props.t("Add Options")}`}</Button>
																									</div>
																								) : (
																									""
																								)}
																							</Col>
																						)}
																						<Col xs={12} className="mt-4">
																							<AvCheckboxGroup name={`${"question-is-mandatory"}-${index}`} className="dissco--question-genrator--checkbox" onChange={(e) => this.handleChangeCheckBox(e, index, "isMandatory")}>
																								<Row>
																									<Col>
																										<AvCheckbox label={this.props.t("Mandatory Answer")} value="isMandatory" checked={question.isMandatory || false} />
																									</Col>
																								</Row>
																							</AvCheckboxGroup>
																						</Col>
																					</Row>
																				</div>
																			) : null}
																		</div>
																	</li>
															  ))
															: null}
													</ul>
												</ReactDragListView>
											</div>

											{/* Add Questions */}
											{getCurrentLanguage() === "en" ? (
												<div>
													<Button block style={{ width: "100%" }} size="xl" onClick={this.handleClickAddQuestion} className="dissco--question-genrator--button dissco--question-genrator--button--lg">
														<span style={{ color: "rgba(26, 29, 48, 0.7" }}>{`+ ${this.props.t("Add Question")}`}</span>
													</Button>
												</div>
											) : (
												""
											)}

											{/* Submit */}
											<div className="pt-3">
												<Button type="submit" color="primary" disabled={this.state.spinner.id === "submit-button" && this.state.spinner.active}>
													<span>{this.state.spinner.id === "submit-button" && this.state.spinner.active ? this.props.t("Saving") : this.props.t("Save")}</span>
													{this.state.spinner.id === "submit-button" && this.state.spinner.active && <Spinner size="sm" color="light" style={{ top: 1, position: "relative", marginLeft: 20 }} />}
												</Button>
											</div>
										</AvForm>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	fetchQuestions = () => {
		axios
			.get(this.props.modules.active === 'wb' ? API_URL_QUESTION_GENERATOR_GET_LIST : CM_API_URL_QUESTION_GENERATOR_GET_LIST, this.state.header)
			.then((response) => {
				const res = response.data.data;

				if (response.data.ok) {
					let allQuestions = res.questions;

					allQuestions.map((x) => {
						let xx = x;

						xx.isUpdated = false;
						xx.title = this.props.t(xx.title);

						return xx;
					});

					allQuestions.sort(function (a, b) {
						return (a.isCategory || a.id === 'country_list') ? -1 : (a.sort - b.sort);
					});


					this.setState({
						questions: allQuestions,
						defaultQuestions: allQuestions,
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					const data = error.response.data;

					this.props.errorNotification({
						code: data.error || "unknown_error",
						message: this.props.t(data.message || data.title),
					});
				}
			});
	};

	componentDidMount() {
		this.fetchQuestions();
	}

	componentDidUpdate(prevProps) {
		if (this.props.t !== prevProps.t) {
			this.setState({
				initialQuestions: [
					{
						id: 0,
						title: this.props.t("Salutation"),
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
					{
						id: 0,
						title: this.props.t("First name"),
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
					{
						id: 0,
						title: this.props.t("Last name"),
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
					{
						id: 0,
						title: this.props.t("Position"),
                        keyName: "position",
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
					{
						id: 0,
						title: this.props.t("Organization"),
                        keyName: "organization",
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
					{
						id: 0,
						title: this.props.t("Brief Description of Incident"),
						type: "initial",
						isAnonymous: false,
						isConfidential: false,
						isMeeting: false,
						isMandatory: false,
						options: [],
						isUpdated: false,
						sort: 0,
					},
				],
				questionTypes: [
					{
						title: this.props.t("Date"),
						value: "date",
					},
					{
						title: this.props.t("Switch"),
						value: "switch",
					},
					{
						title: this.props.t("Text Input"),
						value: "text",
					},
					{
						title: this.props.t("Text Area"),
						value: "textarea",
					},
					{
						title: this.props.t("Radiobox"),
						value: "radiobox",
					},
					{
						title: this.props.t("Checkbox"),
						value: "checkbox",
					},
					{
						title: this.props.t("Select Box"),
						value: "select",
					},
					{
						title: this.props.t("File Upload"),
						value: "file",
					},
                    {
                        title: this.props.t("Legal Entity"),
                        value: 'legal',
                        feature: 'entity_management'
                    }
					// {
					// 	title: this.props.t("Default Country List"),
					// 	value: "country_list",
					// },
				],
				customQuestionTypes: [
					{
						title: this.props.t("Date"),
						value: "date",
					},
					{
						title: this.props.t("Switch"),
						value: "switch",
					},
					{
						title: this.props.t("Text Input"),
						value: "text",
					},
					{
						title: this.props.t("Text Area"),
						value: "textarea",
					},
					{
						title: this.props.t("File Upload"),
						value: "file",
					},
					{
						title: this.props.t("Radiobox"),
						value: "radiobox",
					},
					{
						title: this.props.t("Checkbox"),
						value: "checkbox",
					},
                    {
                        title: this.props.t("Legal Entity"),
                        value: 'legal',
                        feature: 'entity_management'
                    }
					// {
					// 	title: this.props.t("Default Country List"),
					// 	value: "country_list",
					// },
				],
			});

			this.fetchQuestions();
		}
	}
}

const mapStatetoProps = (state) => {
	const App = state.App;
	const { token, user } = state.Login;
    const { 
		Modules: modules,
        Organization
	} = state;
	return {
		App,
		token,
		user,
        modules,
        Organization
	};
};

const mapDispachToProps = (dispach) => {
	return {
		errorNotification: (message) => dispach(errorNotification(message)),
		successNotification: (message) => dispach(successNotification(message)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(QuestionnaireDesigner)));
