import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { logoutUser } from "../../../store/auth/login/actions";
import defAvatar from "../../../assets/images/users/def.svg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      user: [],
      render: false,
      // roles: this.props.user ? this.props.user.roles : []
    };
    this.toggle = this.toggle.bind(this);
    this.logoutCurrentUser = this.logoutCurrentUser.bind(this);
  }

  logoutCurrentUser() {
    this.props.logoutUser(this.props.history);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  renderUserProfileItems() {
    const user = this.state.user === [] || this.props.user;
    let displayName =
      user.first_name || user.last_name
        ? `${user?.first_name} ${user?.last_name}`
        : "";

    let accountSettingsLink = "";
    let changePasswordLink = "";
    let roles = user.roles || [];

    if (
      roles.includes("ROLE_ANALYST_ADMIN") ||
      roles.includes("ROLE_ANALYST")
    ) {
      accountSettingsLink = "/admin/profile";
    } else if (roles.includes("ROLE_EMPLOYEE")) {
      accountSettingsLink = "/employee/profile";
    } else if (roles.includes("ROLE_THIRD_PARTY")) {
      accountSettingsLink = "/third-party/profile";
    } else {
      accountSettingsLink = "profile";
    }

    if (
      roles.includes("ROLE_ANALYST_ADMIN") ||
      roles.includes("ROLE_ANALYST")
    ) {
      changePasswordLink = "/admin/profile/change-password";
    } else if (roles.includes("ROLE_EMPLOYEE")) {
      changePasswordLink = "/employee/profile/change-password";
    } else if (roles.includes("ROLE_THIRD_PARTY")) {
      changePasswordLink = "/third-party/profile/change-password";
    } else {
      changePasswordLink = "profile/change-password";
    }

    // console.log(link);

    return (
      <Dropdown
        isOpen={this.state.menu}
        toggle={this.toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
            {this.props.modules.isLoading || this.props.modules.featuresLoadingStatus === 'loading' ? (
                <>
                    <div className="rounded-circle header-profile-user me-1 loading-placeholder profile-image" />
                    <span className="d-none d-xl-inline-block ms-1 text-transform user-dispaly-name">
                        <div className="loading-placeholder profile-name" />
                    </span>
                </>
            ) : (
                <>
                    <img
                        className="rounded-circle header-profile-user me-1"
                        src={
                        this.props.currentUser?.avatar
                            ? this.props.currentUser?.avatar
                            : defAvatar
                        }
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-1 text-transform user-dispaly-name">
                        {displayName}
                    </span>
                </>
            )}
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link className="text-dark" to={accountSettingsLink}>
              <i className="ri-user-line align-middle me-1 text-dark"></i>{" "}
              {this.props.t("Account Settings")}
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem hidden={this.props.currentUser.is_ad_user}>
            <Link className="text-dark" to={changePasswordLink}>
              <i className="ri-key-line align-middle me-1 text-dark"></i>{" "}
              {this.props.t("Change Password")}
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="text-danger"
            onClick={this.logoutCurrentUser}
          >
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {this.props.t("Log out")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user ? (
          this.renderUserProfileItems()
        ) : (
          <Dropdown
            isOpen={this.state.menu}
            toggle={this.toggle}
            className="d-inline-block user-dropdown"
          >
            <DropdownToggle
              tag="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
            >
              <Link to="/third-party/signin">
                <span className="inline-block ms-1 text-transform">
                  {this.props.t("Log in")}
                </span>
              </Link>
            </DropdownToggle>
          </Dropdown>
        )}
      </React.Fragment>
    );
  }

  componentDidMount() {
    if (this.props.App.loaded) {
      if (this.props.user) {
        this.setState({
          user: this.props.user,
        });
      }
    }

    // if (localStorage.getItem("azure")) {
    //   // console.log("user profile changed. ");
    //   // localStorage.setItem("reload", true);
    //   // this.setState({
    //   // 	render: !this.state.render,
    //   // });
    // }
  }

  // componentDidUpdate(prevProps) {
  //   // console.log(this.props.currentUser);
  //   if (localStorage.getItem("azure")) {
  //     console.log("user profile changed. ");

  //     // this.setState({
  //     // 	render: !this.state.render,
  //     // });
  //   }
  // }
}

const mapStatetoProps = (state) => {
  const { user: currentUser } = state.Login;
  const user = state.Login.user;
  const { App, Modules: modules } = state;
  return { currentUser, App, user, modules };
};

export default connect(mapStatetoProps, {
  logoutUser,
})(withNamespaces()(withRouter(ProfileMenu)));
