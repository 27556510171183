import React from "react";
import { Row, Col, Label, Input, Button, Tooltip } from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import "./RegisterForm.css";
import {
  API_URL_CREATE_THIRD_PARTY_ACCOUNT,
  API_URL_FETCH_CAPTCHA,
  API_URL_GENERATE_RANDOM_USERNAME,
  API_URL_SIGN_IN,
} from "../../../common/constants";
import axios from "axios";
import {
  errorNotification,
  successNotification,
  checkLogin,
  setUserAccessToken,
  appError,
  appLoadStatusChanged,
} from "../../../store/actions";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../../services/constants/LocalStorage";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { changeWorkspace } from "../../../store/workspace/actions";
import { connect } from "react-redux";
import {
  authCurrentUser,
  logoutUser,
  loginUserSuccessful,
} from "../../../store/auth/login/actions";
import Password from "../../../components/Fields/Password";
import { getCurrentLanguage } from "../../../i18n";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAuthType: "register",
      authUserInfos: {
        auth_type: "",
        username: "",
        password: "",
        repeat_password: "",
        email: "",
        regCOnsent: false,
        captcha: ""
      },
      captchaFetched: false,
      captchaSrc: "",
      isThirdPartyUser: true,
      isMobileView: false, // Track if the mobile view is active
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleViewportChange);
    this.handleViewportChange();
    if (
      this.state.selectedAuthType === "register" && 
      !this.state.captchaFetched &&
      !this.fetchingCaptchaInProgress
    ) {
      this.fetchingCaptchaInProgress = true;
      setTimeout(() => {
        this.fetchCaptcha();
        this.fetchingCaptchaInProgress = false; 
      }, 1500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleViewportChange);
  }

  handleViewportChange = () => {
    const isMobileView = window.innerWidth <= 767;
    this.setState({ isMobileView });
  };

  generateRandomUsername = async () => {
    this.setState({ username: null });
    const api = API_URL_GENERATE_RANDOM_USERNAME + getCurrentLanguage();
    axios.get(api).then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        this.setState({
          randomGeneratedUsername: data,
          randomGenerated: true,
          authUserInfos: {
            ...this.state.authUserInfos,
            username: data,
          },
        });
      }
    });
  };

  getToken = async () => {
    let userToken = null;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;
    const { setGivenState } = this.props;
    if (!this.props.user && !this.state.registerStatus) {
      try {
        userToken = await new Promise(async (nonAuthRes, nonAuthRej) => {
          try {
            if (
              this.state.authUserInfos.auth_type === "register" ||
              !this.state.authUserInfos.auth_type
            ) {
              var currentEmail;
              currentEmail = this.state.authUserInfos.email;
              if (this.state.authUserInfos.email === "") {
                currentEmail = null;
              }
              if (!passwordRegex.test(this.state.authUserInfos.password)) {
                setGivenState("showProg", false);
                return this.props.errorNotification({
                  code: "passwords-not-valid",
                  message: this.props.t(
                    "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
                  ),
                });
              } else if (
                this.state.authUserInfos.password !==
                this.state.authUserInfos.repeat_password
              ) {
                setGivenState("showProg", false);
                setGivenState("displayFollowUp", false);
                return this.props.errorNotification({
                  code: "password_not_equal",
                  message: this.props.t(
                    "Password and repeat password are not the same"
                  ),
                });
              } else {
                const responseCreate = await axios
                  .post(API_URL_CREATE_THIRD_PARTY_ACCOUNT, {
                    organization: this.props.Organization.id,
                    username: this.state.authUserInfos.username,
                    password: this.state.authUserInfos.password,
                    email: currentEmail,
                    isThirdPartyUser: this.state.isThirdPartyUser,
                    privacyPolicyAccepted: this.state.regConsent,
                    userPolicyAccepted: this.state.regConsent,
                    verifyCode: this.state.authUserInfos.captcha,
                  }, {
                    withCredentials: true
                  })
                  .then(async (data) => {
                    this.setState({ registerStatus: true });
                    try {
                      const response = await axios.post(API_URL_SIGN_IN, {
                        organization: this.props.Organization.id,
                        username: this.state.authUserInfos.username,
                        password: this.state.authUserInfos.password,
                        isThirdPartyUser: this.state.isThirdPartyUser,
                        "2fa": false,
                      });
                      nonAuthRes(response.data.data.token);
                    } catch (error) {
                      setGivenState("showProg", false);
                      console.log(error);
                      nonAuthRej(
                        this.props.t(
                          "Your email and password combination is not correct"
                        )
                      );
                    }
                  })
                  .catch((error) => {
                    setGivenState("showProg", false);
                    for (var key in error.response.data.message) {
                      if (error.response.data.message.hasOwnProperty(key)) {
                        return this.props.errorNotification({
                          code: "validation_error",
                          message: this.props.t(
                            error.response.data.message[key]
                          ),
                        });
                      }
                    }
                  });
              }
            } else {
              try {
                const response = await axios.post(API_URL_SIGN_IN, {
                  organization: this.props.Organization.id,
                  username: this.state.authUserInfos.username,
                  password: this.state.authUserInfos.password,
                  isThirdPartyUser: this.state.isThirdPartyUser,
                  "2fa": false,
                });
                nonAuthRes(response.data.data.token);
              } catch (error) {
                setGivenState("showProg", false);
                nonAuthRej(
                  this.props.t(
                    "Your email and password combination is not correct"
                  )
                );
              }
            }
          } catch (error) {
            console.log("Err", error);
            setGivenState("showProg", false);
            if (!this.state.tabs[3].active) {
              nonAuthRej(this.props.t("Username already used"));
            }
          }
        });
      } catch (error) {
        setGivenState("showProg", false);
        return this.props.errorNotification({
          code: "fatal_error",
          message: error,
        });
        return;
      }
    } else {
      userToken = this.props.token;
    }
    this.props.setUserAccessToken(userToken);
    return localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, userToken);
  };

  makeAuth = async () => {
    this.props.setGivenState("showProg", true);
    await this.getToken();
    await this.props.authCurrentUser(this.state.authUserInfos);
    await this.props.loginUserSuccessful(this.state.authUserInfos);
    this.props.setGivenState("showProg", true);
    this.props.handleValidSubmit();
  };

  fetchCaptcha = () => {
    axios
      .get(API_URL_FETCH_CAPTCHA, {
        responseType: "json", 
        withCredentials: true,
      })
      .then((response) => {
        if (response && response.data && response.data.captcha) {
          this.setState({
            captchaFetched: true,
            captchaSrc: response.data.captcha, 
          });
        } else {
          console.error("Invalid response or empty captcha field.");
        }
      })
      .catch((error) => {
        console.error("Error fetching captcha:", error);
      });
  };

  renderRegisterForm() {
    const { t } = this.props;
    const {
      selectedAuthType,
      authUserInfos,
      isMobileView,
      setGivenState,
      regConsent,
    } = this.state;

    if (!this.props.user) {
      return (
        <AvForm onValidSubmit={this.makeAuth}>
          <Row>
            <Col sm="12" md="6" lg="4">
              <h5
                className={`follow-up ${
                  isMobileView ? "mobile-only" : "desktop-tablet-only"
                }`}
                style={isMobileView ? { marginTop: 0 } : { marginTop: "15rem" }}
              >
                {t("Follow-Up")}
              </h5>
              <p>
                {t(
                  "To get notified about your report, we would need your email."
                )}
                {this.props.t(
                  "If you do not provide your email, you will have to log back into the system for any updates about your report with the username and password you provided."
                )}
              </p>
            </Col>
            <Col sm="12" md="6" lg="8">
              <Col sm="12" className="mb-3">
                <AvGroup>
                  <Label className="form-label">
                    {t("Authentication Type")}
                  </Label>
                  <AvRadioGroup
                    name="auth_type"
                    value={selectedAuthType}
                    onChange={(e) =>
                      this.setState({
                        selectedAuthType: e.target.value,
                        authUserInfos: {
                          ...authUserInfos,
                          auth_type: e.target.value,
                        },
                      })
                    }
                    required
                  >
                    <Row>
                      <Col sm="12" md="6">
                        <AvRadio label={t("Log In")} value="login" />
                      </Col>
                      <Col sm="12" md="6">
                        <AvRadio label={t("Create account")} value="register" />
                      </Col>
                    </Row>
                  </AvRadioGroup>
                  <AvFeedback>{t("This field cannot be blank")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col sm="12" className="mb-3">
                <AvGroup>
                  <Label className="form-label" htmlFor="username">
                    {t("Username")}
                    <span className="ms-2">
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        id="infoIconU"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.usernameTooltipOpen}
                        target="infoIconU"
                        toggle={() =>
                          this.setState({
                            usernameTooltipOpen:
                              !this.state.usernameTooltipOpen,
                          })
                        }
                      >
                        {this.props.t(
                          "Please choose a username that doesnt contain your name or any kind of email address"
                        )}
                      </Tooltip>
                    </span>
                    <Button
                      outline
                      hidden={this.state.authUserInfos.auth_type === "login"}
                      className="btn-sm color-primary ms-2"
                      onClick={this.generateRandomUsername}
                    >
                      {this.props.t("Random")}
                    </Button>
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    onChange={(e) =>
                      this.setState({
                        authUserInfos: {
                          ...authUserInfos,
                          username: e.target.value,
                        },
                      })
                    }
                    value={
                      authUserInfos.username ||
                      this.state.randomGeneratedUsername
                    }
                    required
                  />
                  <AvFeedback>{t("This field cannot be blank")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col sm="12" className="mb-3">
                <AvGroup>
                  <Label className="form-label" htmlFor="password">
                    {t("Password")}
                    <span className="ms-2">
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        id="infoIcon"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.passwordTooltipOpen}
                        target="infoIcon"
                        toggle={() =>
                          this.setState({
                            passwordTooltipOpen:
                              !this.state.passwordTooltipOpen,
                          })
                        }
                      >
                        {this.props.t(
                          "Please choose a password at least 10 characters contains 1 capital letter,1 digit, and 1 special character contains"
                        ) +
                          ": ! @ # $ % ^ & * ( ) _ [ ]+={ } ' \" ; : / ? . , < > |  -"}
                      </Tooltip>
                    </span>
                  </Label>
                  <Password
                    name="password"
                    onChange={(e) =>
                      this.setState({
                        authUserInfos: {
                          ...authUserInfos,
                          password: e.target.value,
                        },
                      })
                    }
                    placeholder=""
                    errorMessage={this.props.t("This field cannot be blank")}
                    validate={{ required: { value: true } }}
                    id="password"
                    value={this.state.password}
                  />
                  <AvFeedback>{t("This field cannot be blank")}</AvFeedback>
                </AvGroup>
              </Col>
              {selectedAuthType === "register" && (
                <Col sm="12" className="mb-3">
                  <AvGroup>
                    <Label className="form-label" htmlFor="repeat_password">
                      {t("Repeat password")}
                    </Label>
                    <Password
                      name="repeat_password"
                      onChange={(e) =>
                        this.setState({
                          authUserInfos: {
                            ...authUserInfos,
                            repeat_password: e.target.value,
                          },
                        })
                      }
                      placeholder=""
                      errorMessage={this.props.t("This field cannot be blank")}
                      validate={{ required: { value: true } }}
                      id="password"
                      value={this.state.repeat_password}
                    />
                    <AvFeedback>{t("This field cannot be blank")}</AvFeedback>
                  </AvGroup>
                </Col>
              )}
              <Col sm="12" className="mb-3">
                <AvGroup>
                  <Label className="form-label" htmlFor="email">
                    {t("Email (Optional)")}
                    <span className="ms-2">
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        id="email-infobox-icon"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.emailTooltipOpen}
                        target="email-infobox-icon"
                        toggle={() =>
                          this.setState({
                            emailTooltipOpen: !this.state.emailTooltipOpen,
                          })
                        }
                      >
                        {this.props.t(
                          "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                        )}
                        .
                      </Tooltip>
                    </span>
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => {
                      this.setState({
                        authUserInfos: {
                          ...authUserInfos,
                          email: e.target.value,
                        },
                      });
                      return setGivenState("email", e.target.value);
                    }}
                    validate={{ email: true }}
                  />
                  <small className="text-danger">
                    {"* " +
                      t(
                        "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                      ) +
                      "."}
                  </small>
                  <AvFeedback>{t("This field is invalid")}</AvFeedback>
                </AvGroup>
              </Col>

              {!this.props.user && selectedAuthType === "register" ? (
                <>
                  <Col sm="12" className="mb-3">
                    <hr></hr>
                    <Input
                      type="checkbox"
                      checked={this.state.regConsent}
                      onChange={() =>
                        this.setState({ regConsent: !this.state.regConsent })
                      }
                      required
                      className="me-2"
                    />
                    { localStorage.getItem("i18nextLng") === "de" ? 
                      <Label>
                        {"Ich stimme den "}
                        <a href={`user_policy`} target="_blank">{"Nutzungsbedingungen"}</a> 
                        {" " + "und der" + " "}
                        <a href={`data_privacy`} target="_blank">{"Datenschutzerklärung"}</a>
                        {" zu."}
                        </Label>
                    : 
                    <Label>
                      {this.props.t("I agree to")} {" "}
                      <a href={`user_policy`} target="_blank">{this.props.t("User policy")}</a> 
                      {" " + this.props.t("and") + " "}
                      <a href={`data_privacy`} target="_blank">{this.props.t("Data privacy")}</a>
                      </Label>
                    
                    }
                  </Col>
                  {/* Captcha */}
                  <Col sm="12">
                      {this.state.captchaFetched && (
                        <>
                        <div className="mt-3 mb-3">
                          <img className="me-4" src={this.state.captchaSrc} alt="Captcha" />
                          <i 
                          className="ri-restart-line ri-2x"
                          style={{ cursor: "pointer" }} 
                          onClick={this.fetchCaptcha}
                          ></i>
                        </div>
                          <Input
                            type="text"
                            name="captcha"
                            id="captcha"
                            onChange={(e) =>
                              this.setState({
                                authUserInfos: {
                                  ...authUserInfos,
                                  captcha: e.target.value,
                                },
                              })
                            }
                            value={
                              authUserInfos.captcha
                            }
                            required
                          />
                        </>
                        
                      )}
                    </Col>
                </>
              ) : null}
            </Col>

            <Col sm="12" className="d-flex justify-content-between">
              <Button
                type="button"
                color="secondary"
                onClick={this.props.handleBackButton}
              >
                {t("Back")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  selectedAuthType === "register" &&
                  !this.props.user &&
                  !regConsent
                }
              >
                {t("Next")}
              </Button>
            </Col>
          </Row>
        </AvForm>
      );
    } else if (
      this.state.isThirdPartyUser &&
      typeof this.props.user.email === "undefined"
    ) {
      return (
        <AvForm onValidSubmit={this.makeAuth}>
          <Row>
            <Col sm="12" md="6" className="form-container">
              <Col sm="12" className="mb-3">
                <AvGroup>
                  <Label className="form-label" htmlFor="email">
                    {t("Email (Optional)")}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) =>
                      this.setState({
                        authUserInfos: {
                          ...authUserInfos,
                          email: e.target.value,
                        },
                      })
                    }
                    validate={{ email: true }}
                  />
                  <small className="text-danger">
                    {`* ${t(
                      "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you."
                    )}`}
                  </small>
                  <AvFeedback>{t("This field is invalid")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col sm="12" className="d-flex justify-content-between">
                <Button type="submit" color="primary">
                  {t("Submit")}
                </Button>
              </Col>
            </Col>
          </Row>
        </AvForm>
      );
    }
    return null;
  }

  render() {
    return <div>{this.renderRegisterForm()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, {
      logoutUser,
      errorNotification,
      successNotification,
      checkLogin,
      loginUserSuccessful,
      authCurrentUser,
      changeWorkspace,
      appError,
      appLoadStatusChanged,
      setUserAccessToken,
    })(RegisterForm)
  )
);
