import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Label, ListGroup, ListGroupItem, Badge, Row, Col } from "reactstrap";
import Select from "react-select";
import { API_BASE_URL, EMPTY_LIST } from "src/common/constants";
import UserUtils from "src/services/utils/UserUtils";
import axios from "axios";
import { toast } from "react-toastify";

const TPDDThirdPartiesList = ({
    t,
    assignedAnalyst,
    user,
    reportIsDeleted,
    reportCaseId,
    defaultValues = [],
    token,
    modules
}) => {
    const userUtils = new UserUtils();
    const [hasTPModule, setHasTPModule] = useState(false);
    const [expandedThirdparties, setExpandedThirdparties] = useState(false);
    const [selectedThirdParties, setSelectedThirdParties] = useState({
        isLoading: false,
        items: defaultValues.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
    });
    const [ownCompanies, setOwnCompanies] = useState({
        isLoading: false,   
        itemsCount: 0,
        pageIndex: 1,
        pageSize: 250,
        searchText: '',
        items: []
    });
    const [thirdParties, setThirdParties] = useState({
        isLoading: false,
        itemsCount: 0,
        pageIndex: 1,
        pageSize: 250,
        ownCompany: null,
        searchText: '',
        items: []
    });

    const userType = userUtils.isAnalystAdmin(user) ? 'admin' : (
        userUtils.isAnalyst(user) ? 'analyst' : 'else'
    );

    const handleFetchThirdParties = () => {
        setThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });
        
        axios.get(`${API_BASE_URL}/organization/suppliers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                pageSize: thirdParties.pageSize,
                pageIndex: thirdParties.pageIndex,
                name: thirdParties.searchText || undefined,
                own_company: thirdParties.ownCompany?.value || undefined
            }
        }).then(({ data }) => {
            const gotData = data?.data;

            if(gotData?.suppliers && Array.isArray(gotData?.suppliers)){
                setThirdParties((currentState) => {
                    return {
                        ...currentState,
                        isLoading: false,
                        itemsCount: parseInt(gotData.itemsCount),
                        pageIndex: parseInt(gotData.pageIndex),
                        pageSize: parseInt(gotData.pageSize),
                        items: gotData.suppliers.map((company) => {
                            return {
                                label: company.name,
                                value: company.id
                            }
                        })
                    }
                });
            }
        }).catch(() => {
            setThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("Failed to fetch third parties list."), {
                type: "error",
            });
        });
    }

    const handleFetchOwnCompanies = () => {
        setOwnCompanies((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });
        
        axios.get(`${API_BASE_URL}/organization/own_companies`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                pageSize: ownCompanies.pageSize,
                pageIndex: ownCompanies.pageIndex,
                name: ownCompanies.searchText || undefined
            }
        }).then(({ data }) => {
            const gotData = data?.data;

            if(gotData?.ownCompanies && Array.isArray(gotData?.ownCompanies)){
                setOwnCompanies((currentState) => {
                    return {
                        ...currentState,
                        isLoading: false,
                        itemsCount: parseInt(gotData.itemsCount),
                        pageIndex: parseInt(gotData.pageIndex),
                        pageSize: parseInt(gotData.pageSize),
                        items: gotData.ownCompanies.map((company) => {
                            return {
                                label: company.name,
                                value: company.id
                            }
                        })
                    }
                });
            }
        }).catch(() => {
            setOwnCompanies((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("Failed to fetch own companies list."), {
                type: "error",
            });
        });
    }

    const handleAddThirdParty = (id) => {
        setSelectedThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${API_BASE_URL}/report_case/add_third_party`,
            {
                reportCaseId: reportCaseId,
                thirdPartyId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.third_parties || []).map((thirdParty) => {
                        return {
                            value: thirdParty.id,
                            label: thirdParty.name
                        }
                    })
                }
            });

            toast(t("The selected third party has been successfully added to the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to add the third party to the list."), {
                type: "error",
            });
        })
    }

    const handleRemoveThirdParty = (id) => {
        setSelectedThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${API_BASE_URL}/report_case/remove_third_party`,
            {
                reportCaseId: reportCaseId,
                thirdPartyId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.third_parties || []).map((thirdParty) => {
                        return {
                            value: thirdParty.id,
                            label: thirdParty.name
                        }
                    })
                }
            });

            toast(t("The third party has been successfully removed from the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to remove the third party from the list."), {
                type: "error",
            });
        })
    }

    useEffect(() => {
        if(!hasTPModule) return;

        if(thirdParties.ownCompany?.value){
            handleFetchThirdParties();
        }
    }, [ thirdParties.ownCompany ]);

    useEffect(() => {
        if(!hasTPModule) return;

        const timeOutId = setTimeout(() => {
            handleFetchOwnCompanies();
        }, 750);

        return () => clearTimeout(timeOutId);
    }, [ ownCompanies.searchText ]);

    useEffect(() => {
        if(!hasTPModule) return;
        
        const timeOutId = setTimeout(() => {
            handleFetchThirdParties();
        }, 750);

        return () => clearTimeout(timeOutId);
    }, [ thirdParties.searchText ]);

    useEffect(() => {
        setHasTPModule(
            !modules.isLoading && (modules.list || []).findIndex((moduleItem) => {
                return moduleItem.name === 'tp'
            }) > -1
        )
    }, [ modules ]);

    useEffect(() => {
        if(hasTPModule){
            handleFetchOwnCompanies();
        }
    }, [ hasTPModule ]);

    if(!hasTPModule || userType === 'else' || (
        userType === 'analyst' && user.id !== assignedAnalyst.user?.value
    )) return null;

    return (
        <div className="module__tpdd-thirdparties-selection">
            <Label className="form-label text-dark d-flex justify-content-between">
                {t("Choose related entities")}:
                <span
                    onClick={() => setExpandedThirdparties(!expandedThirdparties)}
                    className="expand-btn btn-primary"
                >
                    {expandedThirdparties
                    ? t("Collapse list")
                    : t("Expand list")}
                </span>
            </Label>

            <div className="items-container">
                <div>   
                    {selectedThirdParties.items.length > 0 ? (
                        <ListGroup
                            type="inline"
                            style={{
                                maxHeight: expandedThirdparties ? "171px" : "51px"
                            }}
                        >
                            {selectedThirdParties.items.map((thirdparty, index) => {
                                return (
                                    <ListGroupItem key={index}>
                                        {thirdparty.label}
                                        {(userType === 'admin' || !assignedAnalyst.isReadOnly) && (
                                            <Badge
                                                className="delete-btn text-dark pt-2"
                                                color="light"
                                                onClick={() => {
                                                    handleRemoveThirdParty(thirdparty.value);
                                                }}
                                            >
                                            X
                                            </Badge>
                                        )}
                                    </ListGroupItem>
                                )
                            })}   
                        </ListGroup>
                    ) : (
                        <p
                            className="empty-list-message"
                            style={{
                                maxHeight: expandedThirdparties ? "163px" : "43px",
                            }}
                        >
                            {t(EMPTY_LIST)}
                        </p>
                    )}

                    {!reportIsDeleted && (
                        <Row className="mt-2">
                            <Col sm='6' md='6'>
                                <Select
                                    className={"select--filter-categories"}
                                    classNamePrefix="select2-selection"
                                    options={ownCompanies.items}
                                    value={thirdParties.ownCompany}
                                    onChange={(e) => {
                                        setThirdParties((currentState) => {
                                            return {
                                                ...currentState,
                                                ownCompany: e
                                            }
                                        })
                                    }}
                                    isClearable
                                    placeholder={t("Own companies")}
                                    onInputChange={(input) => {
                                        setOwnCompanies((currentState) => {
                                            return {
                                                ...currentState,
                                                searchText: input
                                            }
                                        })
                                    }}
                                    filterOption={(e) => e}
                                />
                            </Col>

                            <Col sm='6' md='6'>
                                <Select
                                    isDisabled={!thirdParties.ownCompany}
                                    className={"select--filter-categories"}
                                    classNamePrefix="select2-selection"
                                    options={(thirdParties.items).filter((thirdParty) => {
                                        return selectedThirdParties.items.findIndex((item) => {
                                            return thirdParty.value === item.value
                                        }) < 0
                                    })}
                                    value={[]}
                                    onChange={(e) => {
                                        handleAddThirdParty(e.value)
                                    }}
                                    placeholder={t("Select third parties")}
                                    onInputChange={(input) => {
                                        setThirdParties((currentState) => {
                                            return {
                                                ...currentState,
                                                searchText: input
                                            }
                                        })
                                    }}
                                    filterOption={(e) => e}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;
    const { Modules } = state;
  
    return {
        token,
        user,
        modules: Modules
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps
    )(
        TPDDThirdPartiesList
    )
);